/* eslint-disable max-len */
import {MASTER_INVENTORY} from '../../services';
import {MASTER_INVENTORY_STATE as STATE} from './masterInventory.types';
import {transformObject, isArray} from '../../helpers';

export const getMasterInventory = (params) => {
  return (dispatch) => {
    dispatch(request(STATE.FETCH));
    MASTER_INVENTORY.getMasterInventory(params)
        .then(
            (response) => {
              dispatch(requestSuccess(STATE.FETCH_SUCCCESS, response.data));
            },
            (error) => {
              const response = error.response.data;
              dispatch(requestFailed(STATE.FETCH_FAILED, response.data.message));
            },
        );
  };
};

export const getMasterInventoryDetail = (id) => {
  return (dispatch) => {
    dispatch(request(STATE.FETCH_DETAIL));
    MASTER_INVENTORY.getMasterInventoryDetail(id)
        .then(
            (response) => {
              dispatch(requestSuccess(STATE.FETCH_DETAIL_SUCCCESS, response.data));
            },
            (error) => {
              const response = error.response.data;
              dispatch(requestFailed(STATE.FETCH_DETAIL_FAILED, response.data.message));
            },
        );
  };
};

export const getMasterInventoryCategory = (params) => {
  return (dispatch) => {
    dispatch(request(STATE.GET_CATEGORY));
    MASTER_INVENTORY.getMasterInventoryCategory(params)
        .then(
            (response) => {
              dispatch(requestSuccess(STATE.GET_CATEGORY_SUCCCESS, response.data));
            },
            (error) => {
              const response = error.response.data;
              dispatch(requestFailed(STATE.GET_CATEGORY_FAILED, response.data.message));
            },
        );
  };
};

export const createMasterInventory = (params) => {
  return (dispatch) => {
    dispatch(request(STATE.CREATE));
    MASTER_INVENTORY.createMasterInventory(params)
        .then(
            (response) => {
              dispatch(requestSuccess(STATE.CREATE_SUCCESS, response.data.data.message));
            },
            (error) => {
              const errors = error.response.data.data.errors;
              const message = isArray(errors) ? transformObject(errors) : error.response.data.data.message;
              dispatch(requestFailed(STATE.CREATE_FAILED, message));
            },
        );
  };
};

export const updateMasterInventory = (id, params) => {
  return (dispatch) => {
    dispatch(request(STATE.UPDATE));
    MASTER_INVENTORY.updateMasterInventory(id, params)
        .then(
            (response) => {
              dispatch(requestSuccess(STATE.UPDATE_SUCCESS, response.data.data.message));
            },
            (error) => {
              const errors = error.response.data.data.errors;
              const message = isArray(errors) ? transformObject(errors) : error.response.data.data.message;
              dispatch(requestFailed(STATE.UPDATE_FAILED, message));
            },
        );
  };
};

export const deleteMasterInventory = (id, params) => {
  return (dispatch) => {
    dispatch(request(STATE.DELETE));
    MASTER_INVENTORY.deleteMasterInventory(id, params)
        .then(
            (response) => {
              dispatch(requestSuccess(STATE.DELETE_SUCCESS, response.data.data.message));
            },
            (error) => {
              const errors = error.response.data.data.errors;
              const message = isArray(errors) ? transformObject(errors) : error.response.data.data.message;
              dispatch(requestFailed(STATE.DELETE_FAILED, message));
            },
        );
  };
};

export const exportExcel = (params) => {
  return (dispatch) => {
    dispatch(request(STATE.EXPORT, true));
    MASTER_INVENTORY.exportExcel(params)
        .then(
            (response) => {
              dispatch(requestSuccess(STATE.EXPORT_SUCCESS, response.data.data));
            },
            (error) => {
              const errors = error.response.data.data.errors;
              const message = isArray(errors) ? transformObject(errors) : error.response.data.data.message;
              dispatch(exportFailed(STATE.EXPORT_FAILED, message));
            },
        );
  };
};

export const getConditionHistory = (params) => {
  return (dispatch) => {
    dispatch(request(STATE.FETCH_CONDITION_HISTORY));
    MASTER_INVENTORY.getConditionHistory(params)
        .then(
            (response) => {
              dispatch(requestSuccess(STATE.FETCH_CONDITION_HISTORY_SUCCCESS, response.data));
            },
            (error) => {
              const response = error.response.data;
              dispatch(requestFailed(STATE.FETCH_CONDITION_HISTORY_FAILED, response.data.message));
            },
        );
  };
};


export const getMovementHistory = (params) => {
  return (dispatch) => {
    dispatch(request(STATE.FETCH_MOVEMENT_HISTORY));
    MASTER_INVENTORY.getMovementHistory(params)
        .then(
            (response) => {
              dispatch(requestSuccess(STATE.FETCH_MOVEMENT_HISTORY_SUCCCESS, response.data));
            },
            (error) => {
              const response = error.response.data;
              dispatch(requestFailed(STATE.FETCH_MOVEMENT_HISTORY_FAILED, response.data.message));
            },
        );
  };
};

export const getConditionHistoryDetail = (id, params) => {
  return (dispatch) => {
    dispatch(request(STATE.FETCH_CONDITION_HISTORY_DETAIL));
    MASTER_INVENTORY.getConditionHistoryDetail(id, params)
        .then(
            (response) => {
              dispatch(requestSuccess(STATE.FETCH_CONDITION_HISTORY_DETAIL_SUCCCESS, response.data));
            },
            (error) => {
              const response = error.response.data;
              dispatch(requestFailed(STATE.FETCH_CONDITION_HISTORY_DETAIL_FAILED, response.data.message));
            },
        );
  };
};

export const createConditionHistory = (params) => {
  return (dispatch) => {
    dispatch(request(STATE.CREATE_CONDITION_HISTORY));
    MASTER_INVENTORY.createConditionHistory(params)
        .then(
            (response) => {
              dispatch(requestSuccess(STATE.CREATE_CONDITION_HISTORY_SUCCCESS, response.data.data.message));
            },
            (error) => {
              const errors = error.response.data.data.errors;
              const message = isArray(errors) ? transformObject(errors) : error.response.data.data.message;
              dispatch(requestFailed(STATE.CREATE_CONDITION_HISTORY_FAILED, message));
            },
        );
  };
};

export const updateConditionHistory = (id, params) => {
  return (dispatch) => {
    dispatch(request(STATE.UPDATE_CONDITION_HISTORY));
    MASTER_INVENTORY.updateConditionHistory(id, params)
        .then(
            (response) => {
              dispatch(requestSuccess(STATE.UPDATE_CONDITION_HISTORY_SUCCCESS, response.data.data.message));
            },
            (error) => {
              const errors = error.response.data.data.errors;
              const message = isArray(errors) ? transformObject(errors) : error.response.data.data.message;
              dispatch(requestFailed(STATE.UPDATE_CONDITION_HISTORY_FAILED, message));
            },
        );
  };
};

export const deleteConditionHistory = (id, params) => {
  return (dispatch) => {
    dispatch(request(STATE.DELETE_CONDITION_HISTORY));
    MASTER_INVENTORY.deleteConditionHistory(id, params)
        .then(
            (response) => {
              dispatch(requestSuccess(STATE.DELETE_CONDITION_HISTORY_SUCCCESS, response.data.data.message));
            },
            (error) => {
              const errors = error.response.data.data.errors;
              const message = isArray(errors) ? transformObject(errors) : error.response.data.data.message;
              dispatch(requestFailed(STATE.DELETE_CONDITION_HISTORY_FAILED, message));
            },
        );
  };
};
export const deleteImage = (id, params) => {
  return (dispatch) => {
    dispatch(request(STATE.DELETE_IMAGE));
    MASTER_INVENTORY.deleteImage(id, params)
        .then(
            (response) => {
              dispatch(requestSuccess(STATE.DELETE_IMAGE_SUCCCESS, response.data.data.message));
            },
            (error) => {
              const errors = error.response.data.data.errors;
              const message = isArray(errors) ? transformObject(errors) : error.response.data.data.message;
              dispatch(requestFailed(STATE.DELETE_IMAGE_FAILED, message));
            },
        );
  };
};


export const clearState = () => {
  return (dispatch) => dispatch(resetState());
};

const request = (requestType) => ({
  type: STATE.REQUEST,
  requestType,
});

const requestFailed = (requestType, response) => ({
  type: STATE.REQUEST_FAILED,
  requestType,
  payload: response,
});

const requestSuccess = (requestType, response) => ({
  type: requestType,
  payload: response,
});

const exportFailed = (requestType, response) => ({
  type: requestType,
  payload: response,
});

const resetState = () => ({
  type: STATE.CLEAR_STATE,
});
