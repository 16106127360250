export default (theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: '1.04vw',
    color: theme.palette.text.secondary,
    marginBottom: '0.52vw',
    borderRadius: 5,
    boxShadow: '0px 0px 10px 1px #0000001A',
    height: '50% !important',
    justifyContent: 'center',
    alignItems: 'center',
    verticalAlign: 'middle',
    display: 'flex',
  },
  parentFontAttend: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: '650 !important',
    fontSize: '21px !important',
    color: '##00AFF0',
  },
  parentFont: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: '650 !important',
    fontSize: '1.2vw',
    color: '#656464',
  },
  parentFontLight: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: '450 !important',
    fontSize: '16px !important',
    color: '#656464',
    marginTop: '1.56vw',
  },
  childFont: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 630,
    fontSize: '0.8vw',
    color: '#4E4E4E',
    textAlign: 'right',
  },
  dateFont: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: '400 !important',
    fontSize: '0.625vw !important',
    color: '#4E4E4E',
    marginTop: '0.625vw',
  },
  fontTitle: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: '650 !important',
    fontSize: '24px !important',
    color: '#656464',
    paddingBottom: 10,
  },
  loaderContainer: {
    minHeight: 220,
    display: 'flex',
    width: '100%',
  },
  loader: {
    display: 'flex',
    margin: 'auto',
  },
  loaderText: {
    marginTop: 1,
    marginLeft: 13,
  },
  imageWrapper: {
    width: '40px',
    // height: '50px',
    overflow: 'hidden',
  },
  logo: {
    width: '100%', // Batas lebar logo
    height: '100%', // Menjaga proporsi
    objectFit: 'cover',
    objectPosition: 'center', // Memastikan gambar tidak terdistorsi
  },
  hidden: {
    visibility: 'hidden',
  },
  imgQR: {
    height: '250px !important',
    width: '250px !important',
  },
  title: {
    color: '#535A61 !important',
    fontWeight: 700,
    fontSize: 50,
    lineHeight: 0,
    // marginBottom: '1.56vw',
  },
  titleDate: {
    color: '#535A61 !important',
    fontWeight: 300,
    fontSize: 20,
    lineHeight: 0,
    marginBottom: 30,
    marginLeft: 10,
  },
  qrSection: {
    paddingTop: 30,
  },
  center: {
    textalign: 'center',
  },
  printTitle: {
    color: '#25bbf2',
    margin: '30px 0',
    fontSize: '3.5rem',
    textalign: 'center',
  },
  printSubTitle: {
    marginTop: '30px',
    textalign: 'center',
  },
  printPage: {
    minHeight: '100vh',
    display: 'grid',
    justifyContent: 'center',
    alignItems: 'center',
  },
  printQRImg: {
    width: 750,
    height: 750,
    border: '3px solid black',
    borderRadius: 25,
  },
  printCompanyContainer: {
    justifyContent: 'center',
  },
  printLogo: {
    width: 'auto',
    height: 75,
  },
  printCompanyName: {
    marginLeft: 20,
    fontWeight: 600,
    fontSize: 24,
  },
  printCompanyAddress: {
    marginLeft: 20,
    fontWeight: 300,
    fontSize: 16,
  },
  printFooter: {
    display: 'flex',
    justifyContent: 'center',
  },
  printFooterText: {
    marginTop: 11,
  },
  pagiiLogo: {
    width: '2.6vw',
  },
  smooetsLogo: {
    width: '4.6vw',
    marginLeft: '0.52vw',
  },
  poweredText: {
    fontSize: '0.83vw',
    marginLeft: '0.52vw',
    alignItems: 'center',
    display: 'inline-flex',
  },
  avatarMini: {
    width: '40px',
    height: '40px',
    overflow: 'hidden',
    borderRadius: '50%',
    position: 'relative',
  },
  avatarMiniImg: {
    display: 'inline',
    margin: '0 auto',
    height: '100%',
    width: '100%',
    objectFit: 'cover',
  },
  avatar: {
    width: '4.68vw',
    height: '4.68vw',
    overflow: 'hidden',
    borderRadius: '50%',
    position: 'relative',
    margin: '0 auto',
  },
  avatarGold: {
    width: '5.2vw',
    height: '5.2vw',
    overflow: 'hidden',
    borderRadius: '50%',
    position: 'relative',
    margin: '0 auto',
  },
  avatarFrameSilver: {
    width: '5.2vw',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  avatarFrameBronze: {
    width: '4.9vw',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  avatarFrameGold: {
    width: '8.85vw',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  avatarImg: {
    display: 'inline',
    // marginTop: '-25%',
    margin: '0 auto',
    height: '100%',
    width: '100%',
    objectFit: 'cover',
  },
  avatarImgDefault: {
    display: 'inline',
    margin: '0 auto',
    height: 'auto',
    width: '100%',
  },
});
