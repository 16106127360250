export default (theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
    borderRadius: 18,
  },
  title: {
    lineHeight: 0,
    marginBottom: 30,
  },
  formControl: {
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(2),
    minWidth: 120,
  },
  dialogContent: {
    padding: 20,
  },
  defaultBtn: {
    'marginTop': 32,
    'backgroundColor': '#00AFF0',
    'boxShadow': 'none',
    '&:hover': {
      boxShadow: 'none',
      backgroundColor: '#0198D0',
    },
    'color': '#fff',
  },
  btnContainerBlasting: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
  },
  btnSaveDraft: {
    marginTop: 32,
    backgroundColor: '#E5AF5A',
    marginRight: '10px',
    boxShadow: 'none',
    '&:hover': {
      boxShadow: 'none',
      backgroundColor: '#E5AF5A',
    },
    color: '#FFF',
  },
  btnSubmit: {
    marginTop: 32,
    backgroundColor: '#00AFF0',
    boxShadow: 'none',
    '&:hover': {
      boxShadow: 'none',
      backgroundColor: '#0198D0',
    },
    color: '#fff',
  },
  btnCancelForm: {
    marginTop: 32,
    marginLeft: 'auto',
    'backgroundColor': '#ff5733',
    boxShadow: 'none',
    '&:hover': {
      boxShadow: 'none',
      backgroundColor: '#D09F53',
    },
    color: '#FFF',
  },
  scheduleDateTime: {
    color: '#008000',
    fontSize: 20,
  },
  subTitle: {
    fontSize: '1.3em',
    fontWeight: 600,
    margin: '10px 0px',
  },
  versionTitle: {
    marginTop: 24,
    fontSize: '1.3em',
    fontWeight: 600,
    margin: '10px 0px',
  },
  bannerFlex: {
    display: 'flex',
    overflowX: 'auto',
    gap: 10,
    padding: '10px 0px',
  },
  bannerCreateContainer: {
    width: 200,
    height: 300,
    flex: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: '1px solid lightgray',
    borderRadius: 10,
  },
  bannerItem: {
    width: 200,
    height: 300,
    flex: 'none',
    border: '1px solid lightgray',
    borderRadius: 10,
    overflow: 'hidden',
    position: 'relative',
  },
  bannerItemIcon: {
    position: 'absolute',
    cursor: 'pointer',
    right: 5,
    top: 5,
  },
  bannerImg: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
  inputBanner: {
    display: 'none',
  },
});
