/* eslint-disable no-sequences */
/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
/* eslint-disable max-len */
import React, {Component} from 'react';
import {connect} from 'react-redux';
import {
  withStyles,
  CardMedia,
  CircularProgress,
  Grid,
  Paper,
  Typography,
  Link,
} from '@material-ui/core';
import styles from './styles';
import {GENERAL_SETTING_STATE as STATE} from '../../redux/generalSetting/generalSetting.types';
import {LEADERBOARD_STATE as LB_STATE} from '../../redux/leaderboard/leaderboard.types';
import {
  generateQR,
  checkTokenQR,
} from '../../redux/generalSetting/generalSetting.actions';
import {toast, isObject} from '../../helpers';
import {Datatable} from '../../components';
import moment from 'moment';
import '../../assets/style/style.css';
import '../../assets/style/mobile.css';
import goldEmployee from '../../assets/img/gamification/goldEmployee.png';
import silverEmployee from '../../assets/img/gamification/silverEmployee.png';
import bronzeEmployee from '../../assets/img/gamification/bronzeEmployee.png';
import photoDefault from '../../assets/img/gamification/photoDefault.png';
import PagiiLogo from '../../assets/img/pagii-simple-attendance-solution-logo.png';
import SmooetsLogo from '../../assets/img/main-logo-smooets-horizontal-sm.png';
import {
  getLeaderboardToday,
  getLeaderboardMonth,
} from './../../redux/leaderboard/leaderboard.actions';
import {Today} from '@material-ui/icons';
import featureFlag from '../../config/featureFlag/index';

const QR_INTERVAL = process.env.REACT_APP_REFRESH_QR_INTERVAL ?
  process.env.REACT_APP_REFRESH_QR_INTERVAL :
  0; // in miliseconds

class Leaderboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      qrURL: null,
      initialLoading: true,
      loadingLogo: true,
      token: null,
      pageToday: 1,
      limitToday: 10,
      sortBy: 'check_in',
      order: 'asc',
      pageMonth: 1,
      limitMonth: 10,
      errorMessage: null,
      resetDatatable: null,
      resetPage: null,
    };
    this.intervalId = null;
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const {
      trigger,
      isSuccess,
      qr,
      autoRefresh,
      isErrorLb,
      messageLb,
      isSuccessLb,
      triggerLb,
      todayMeta,
    } = nextProps;

    if (trigger === STATE.CHECK_TOKEN_SUCCESS) {
      this.setState({qrURL: qr});
      this.checkQRTimer(autoRefresh);
    }

    if (isSuccess && trigger === STATE.GENERATE_QR_SUCCESS) {
      this.setState({qrURL: qr});
      this.checkQRTimer(autoRefresh);
    }

    if (isSuccessLb && triggerLb === LB_STATE.FETCH_TODAY_SUCCESS) {
      if (todayMeta.total < 10) {
        this.setState(
          {
            pageToday: 1,
          },
          () => {
            setTimeout(() => {
              this.fetchToday();
            }, 60000);
          },
        );
      }
      // if((todayMeta.total % (this.state.pageToday*10) === todayMeta.total) || todayMeta.total === this.state.pageToday*10){
      //   this.setState({
      //     pageToday: 1,
      //   },
      //   () => {
      //     setTimeout(() => {
      //       this.fetchToday();
      //     }, 60000);
      //   });
      // } else {
      //   this.setState({
      //     pageToday: this.state.pageToday + 1,
      //   },
      //   () => {
      //     setTimeout(() => {
      //       this.fetchToday();
      //     }, 60000);
      //   });
      // }
    }

    if (
      trigger === STATE.CHECK_TOKEN_FAILED ||
      trigger === STATE.GENERATE_QR_FAILED
    ) {
      this.props.history.push('/419');
    }

    if (isErrorLb && messageLb && !isObject(messageLb)) {
      toast('error', messageLb);
    }
  }

  componentDidMount() {
    this.checkToken();
  }

  componentWillUnmount() {
    this.stopQRTimer();
  }

  checkToken = () => {
    const location = this.props.location.pathname;
    const segment = location.substring(location.lastIndexOf('/') + 1);
    const token = segment !== 'leaderboard' ? segment : null;

    if (token) {
      this.setState(
        {
          token,
        },
        () => {
          this.fetchToday();
          this.fetchMonth();
        },
      );
      this.props.checkTokenQR({id: token});
    } else {
      this.props.history.push('/419');
    }
  };

  checkQRTimer = (autoRefreshStatus) => {
    if (autoRefreshStatus) this.startQRTimer();
    if (!autoRefreshStatus) this.stopQRTimer();
  };

  startQRTimer = () => {
    clearInterval(this.intervalId);
    this.intervalId = setInterval(
      this.handleGenerateQR.bind(this),
      QR_INTERVAL,
    );
  };

  handleGenerateQR = () => {
    const {token} = this.state;
    this.props.generateQR({id: token});
  };

  stopQRTimer = () => {
    clearInterval(this.intervalId);
  };

  fetchToday = () => {
    const {pageToday, limitToday, sortBy, order, token} = this.state;
    this.props.getLeaderboardToday({
      ...(pageToday && {page: pageToday}),
      ...(limitToday && {limit: limitToday}),
      ...(sortBy && {sort_by: sortBy}),
      ...(order && {order}),
      ...(token && {id: token}),
    });
  };

  fetchMonth = () => {
    const {pageMonth, limitMonth, sortBy, order, token} = this.state;
    this.props.getLeaderboardMonth({
      ...(pageMonth && {page: pageMonth}),
      ...(limitMonth && {limit: limitMonth}),
      // ...(sortBy && {sort_by: sortBy}),
      // ...(order && {order}),
      ...(token && {id: token}),
    });
  };

  renderHallOfFame = () => {
    const {classes, isLoading, monthData} = this.props;

    const names = monthData ?
      monthData.map((value) => value['employees.name']) :
      [];
    const avatars = monthData ?
      monthData.map((value) => value['employees.image']) :
      [];
    const points = monthData ? monthData.map((value) => value.total_point) : [];
    return (
      <Paper className={classes.paper}>
        <Grid
          container
          wrap="nowrap"
          spacing={0}
          direction="column"
          alignItems="center"
          justify="center"
        >
          <Grid item xs={12}>
            <Typography
              className={classes.parentFont}
              align="center"
              style={{marginTop: '0.52vw', color: '#00AFF0'}}
            >
              Employee of The Month
            </Typography>
          </Grid>
          <Grid
            item
            container
            wrap="nowrap"
            spacing={0}
            alignItems="center"
            justify="center"
            style={{marginTop: '2.6vw'}}
          >
            <Grid item xs={12}>
              <div style={{position: 'relative'}}>
                <div className={classes.avatar}>
                  <img
                    alt="avatar"
                    src={avatars[1] ? avatars[1] : photoDefault}
                    className={`${
                      avatars[1] ? classes.avatarImg : classes.avatarImgDefault
                    }`}
                  />
                </div>
                <img
                  src={silverEmployee}
                  alt="silverEmployee"
                  className={classes.avatarFrameSilver}
                />
              </div>
              <Typography
                className={classes.childFont}
                style={{marginTop: '2.08vw', color: '#535A61', textAlign: 'center'}}
              >
                {names[1] ? names[1] : null}
              </Typography>
              <Typography
                className={classes.childFont}
                align="center"
                style={{
                  marginTop: '0.1vw',
                  color: '#535A61',
                  fontWeight: '500',
                  textAlign: 'center',
                }}
              >
                {points[1] ? points[1] + ' Points' : null}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <div style={{position: 'relative'}}>
                <div className={classes.avatarGold}>
                  <img
                    src={avatars[0] ? avatars[0] : photoDefault}
                    alt="avatar"
                    className={`${
                      avatars[0] ? classes.avatarImg : classes.avatarImgDefault
                    }`}
                  />
                </div>
                <img
                  src={goldEmployee}
                  alt="goldEmployee"
                  className={classes.avatarFrameGold}
                />
              </div>
              <Typography
                className={classes.childFont}
                style={{marginTop: '2.08vw', color: '#535A61', textAlign: 'center'}}
              >
                {names[0] ? names[0] : null}
              </Typography>
              <Typography
                className={classes.childFont}
                align="center"
                style={{
                  marginTop: '0.1vw',
                  color: '#535A61',
                  fontWeight: '500',
                  textAlign: 'center',
                }}
              >
                {points[0] ? points[0] + ' Points' : null}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <div style={{position: 'relative'}}>
                <div className={classes.avatar}>
                  <img
                    src={avatars[2] ? avatars[2] : photoDefault}
                    alt="avatar"
                    className={`${
                      avatars[2] ? classes.avatarImg : classes.avatarImgDefault
                    }`}
                  />
                </div>
                <img
                  alt="bronzeEmployee"
                  src={bronzeEmployee}
                  className={classes.avatarFrameBronze}
                />
              </div>
              <Typography
                className={classes.childFont}
                style={{marginTop: '2.08vw', color: '#535A61', textAlign: 'center'}}
              >
                {names[2] ? names[2] : null}
              </Typography>
              <Typography
                className={classes.childFont}
                align="center"
                style={{
                  marginTop: '0.1vw',
                  color: '#535A61',
                  fontWeight: '500',
                  textAlign: 'center',
                }}
              >
                {points[2] ? points[2] + ' Points' : null}
              </Typography>
            </Grid>
          </Grid>
          {names.length === 0 && avatars.length === 0 ? (
            <Grid item xs={12}>
              <Typography
                className={classes.parentFont}
                align="center"
                style={{marginTop: '0.52vw', color: '#00AFF0'}}
              >
                Will be available at the next month
              </Typography>
            </Grid>
          ) : null}
        </Grid>
      </Paper>
    );
  };

  handleReload = (params) => {
    this.setState(params, () => this.fetchToday());
  };

  renderTodayCheckIn = () => {
    const {classes, isLoadingLb, todayData, todayMeta} = this.props;
    const {resetDatatable, resetPage} = this.state;

    const columns = [
      {name: 'no', label: 'No', display: true},
      {
        name: 'image',
        label: ' ',
        display: true,
        customBodyRender: (value) => {
          if (featureFlag.leaderboard.enable_avatar) {
            return (
              <Link href={value || '#'} target={value ? '_blank' : '_self'} rel="noopener noreferrer">
                {value ? (
                  <div className={classes.avatarMini}>
                    <img
                      alt="avatarMini"
                      src={value}
                      className={classes.avatarMiniImg}
                    />
                  </div>
                ) : (
                  <div className={classes.avatarMini}>
                  <img
                    src={photoDefault}
                    style={{
                      display: 'inline',
                      margin: '0 auto',
                      height: 'auto',
                      width: '100%',
                    }}
                    alt="avatar"
                  />
                </div>
                )}
              </Link>
            );
          } else {
            return (
              <Link href={value} target="_blank" rel="noopener noreferrer">
                <div className={classes.avatarMini}>
                  <img
                    alt="avatarMini"
                    src={value}
                    className={classes.avatarMiniImg}
                  />
                </div>
              </Link>
            );
          }
        },
      },
      {name: 'name', label: 'Name', display: true},
      {name: 'check_in', label: 'Time', display: true},
    ];

    return (
      <Grid
        container
        wrap="nowrap"
        spacing={0}
        direction="column"
        justify="center"
      >
        {/* <Grid item xs={12}>
          <Typography className={classes.parentFont} align="left" style={{fontSize: '18px'}}>
            Today Check-in
          </Typography>
        </Grid> */}
        <Datatable
          hideActions
          creatable={false}
          title={'Today Checkin'}
          loading={isLoadingLb}
          dataSource={todayData}
          total={todayMeta.total}
          page={todayMeta.page}
          columns={columns}
          paging={false}
          handleReload={(params) => this.handleReload(params)}
          toggleResetAll={resetDatatable}
          toggleResetPage={resetPage}
          manualNumbering={true}
          cellStyle={{
            root: {
              '&:nth-child(1)': {
                width: 100,
              },
              '&:nth-child(2)': {
                width: 40,
              },
              '&:nth-child(4)': {
                width: 100,
              },
              'padding': '0.41vw',
            },
            head: {
              backgroundColor: '#53C6F1 !important',
              color: '#fff !important',
            },
          }}
        />
      </Grid>
    );
  };

  renderQr = () => {
    const {classes, isLoading} = this.props;
    const {qrURL, initialLoading, loadingLogo} = this.state;

    return (
      <Paper className={classes.paper}>
        <Grid
          container
          wrap="nowrap"
          spacing={0}
          direction="row"
          alignItems="center"
          justify="center"
        >
          <Grid item xs={12}>
            <Typography
              className={classes.parentFont}
              align="center"
              style={{
                color: '#00AFF0',
                marginBottom: '0.52vw',
                fontSize: '1.3vw',
              }}
            >
              Working Office
            </Typography>
            <Typography
              className={classes.childFont}
              align="center"
              style={{color: '#00AFF0'}}
            >
              Scan Me For Checkin / Checkout
            </Typography>
          </Grid>
          <Grid item xs={12}>
            {isLoading ? (
              <div className={classes.loaderContainer}>
                <div className={classes.loader}>
                  <CircularProgress color="primary" size={24} />
                  <div className={classes.loaderText}>{'Loading QR...'}</div>
                </div>
              </div>
            ) : (
              <img
                alt="qrUrl"
                className={`img-qr ${initialLoading && classes.hidden}`}
                style={{width: '90%'}}
                src={qrURL ? qrURL : null}
                onLoad={() => this.setState({initialLoading: false})}
              />
            )}
          </Grid>
          {/* <Grid item xs={12}>
            <div className={classes.printFooter}>
              <div><img src={PagiiLogo} className={classes.poweredLogo} style={{height: '25px !important'}} alt="img"/></div>
              <h5 className={classes.poweredText}>Powered By:</h5>
              <div><img src={SmooetsLogo} className={classes.poweredLogo} alt="img"/></div>
            </div>
          </Grid> */}
        </Grid>
      </Paper>
    );
  };

  render() {
    const {classes, companyDetail, isLoading} = this.props;
    const {loadingLogo} = this.state;
    const dateNow = moment().format('D MMMM YYYY');
    return (
      <div
        container
        style={{height: '100vh', margin: 0, backgroundColor: 'white'}}
      >
        <div
          style={{
            backgroundColor: '#F3F4F9',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            height: 'max-content',
            padding: '30px 80px',
          }}
        >
          <div>
            <Typography className={classes.title}>Dashboard</Typography>
          </div>
          <div>
            {companyDetail && companyDetail.logo && (
              <img
                style={{float: 'right', width: 'auto', height: 'auto', maxHeight: '30px'}}
                src={companyDetail.logo}
                className={`company-logo ${
                  (loadingLogo && classes.hidden, classes.logo)
                }`}
                alt="company-logo"
                onLoad={() => this.setState({loadingLogo: false})}
              />
            )}
            <Typography
              className={classes.childFont}
              align="right"
              style={{clear: 'right', color: '#00AFF0'}}
            >
              {companyDetail && companyDetail.name && companyDetail.name}
            </Typography>
            <Typography
              className={classes.childFont}
              align="right"
              style={{clear: 'right', color: '#959595'}}
            >
              {companyDetail && companyDetail.address && companyDetail.address}
            </Typography>
          </div>
        </div>
        <div style={{display: 'flex', width: '100vw', gap: 16, padding: '30px 80px'}}>
          <div style={{width: '60%'}}>{this.renderTodayCheckIn()}</div>
          <div style={{width: '40%'}}>
            {this.renderHallOfFame()}
            {this.renderQr()}
          </div>
        </div>
        <div
          container
          direction="row"
          justify="flex-end"
          alignItems="center"
          xs={12}
          style={{marginTop: '1.56vw'}}
        >
          <div item>
            <div className={classes.printFooter}>
              <div>
                <img src={PagiiLogo} className={classes.pagiiLogo} alt="img" />
              </div>
              <span className={classes.poweredText}>Presented by:</span>
              <div>
                <img
                  src={SmooetsLogo}
                  className={classes.smooetsLogo}
                  alt="img"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({generalSetting, leaderboard}) => ({
  isLoading: generalSetting.isLoading,
  isSuccess: generalSetting.isSuccess,
  isError: generalSetting.isError,
  message: generalSetting.message,
  trigger: generalSetting.trigger,
  isLoadingLb: leaderboard.isLoading,
  isSuccessLb: leaderboard.isSuccess,
  isErrorLb: leaderboard.isError,
  messageLb: leaderboard.message,
  triggerLb: leaderboard.trigger,
  qr: generalSetting.qr,
  autoRefresh: generalSetting.autoRefresh,
  companyDetail: generalSetting.company,
  todayData: leaderboard.todayData,
  todayMeta: leaderboard.todayMeta,
  monthData: leaderboard.monthData,
  monthMeta: leaderboard.monthMeta,
});

const mapDispatchToProps = (dispatch) => ({
  generateQR: (params) => dispatch(generateQR(params)),
  checkTokenQR: (params) => dispatch(checkTokenQR(params)),
  getLeaderboardToday: (params) => dispatch(getLeaderboardToday(params)),
  getLeaderboardMonth: (params) => dispatch(getLeaderboardMonth(params)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles)(Leaderboard));
