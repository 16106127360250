/* eslint-disable array-callback-return */
/* eslint-disable max-len */
/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
/* eslint-disable react/react-in-jsx-scope */
import React, {Component, Fragment} from 'react';
import {
  Paper,
  Button,
  Grid,
  Typography,
  Dialog,
  DialogContent,
  DialogActions,
  IconButton,
  CardMedia,
  Icon,
  Radio,
  RadioGroup,
  FormLabel,
  FormControl,
  TextField,
} from '@material-ui/core';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Tooltip from '@material-ui/core/Tooltip';
import Divider from '@mui/material/Divider';
import {Link} from 'react-router-dom';
import {
  Search,
  Refresh,
  CheckCircle,
  Cancel,
  Send,
  SaveAlt,
  Delete,
  Edit,
  Add,
} from '@material-ui/icons';
import {
  MInput,
  MKeyBoardDatepicker,
  MButton,
  ModalTitle,
  MSelect,
  MDatepicker,
  MAutoComplete,
  MSelectSearch,
} from '../../../components/form';
import ImageCropper from '../../../components/cropper';
import {connect} from 'react-redux';
import {withStyles, MuiThemeProvider} from '@material-ui/core/styles';
import styles from './styles';
import {Datatable} from '../../../components';
import {toast, isObject, convDate, convText, showPopup} from '../../../helpers';
import {checkStatus as checkStatusUser} from '../../../redux/userManagement/userManagement.actions';
import {checkStatus} from '../../../redux/organization/organization.actions';
import _ from 'lodash';
import moment from 'moment';
import Swal from 'sweetalert2';
import {createTheme, ThemeProvider} from '@mui/material/styles';
import {Checkbox, FormControlLabel, Snackbar, createTheme as themeTemplate} from '@mui/material';
import {Autocomplete} from '@material-ui/lab';
import {Verified} from '@mui/icons-material';
import Slider from 'react-slick';
import confirmationIcon from '../../../assets/icon/partner/confirm.svg';
import completedIcon from '../../../assets/icon/employeeFacilities/completed.svg';
import {updateExportNotification} from '../../../redux/exportActivity/exportActivity.actions';

import {
  getMasterInventory,
  getMasterInventoryCategory,
  createMasterInventory,
  updateMasterInventory,
  deleteMasterInventory,
  exportExcel,
  clearState,
} from '../../../redux/masterInventory/masterInventory.actions';
import {MASTER_INVENTORY_STATE as STATE} from '../../../redux/masterInventory/masterInventory.types';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandMore';
import Timeline from '@mui/lab/Timeline';
import TimelineItem, {timelineItemClasses} from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineOppositeContent, {
  timelineOppositeContentClasses,
} from '@mui/lab/TimelineOppositeContent';


class MasterInventory extends Component {
  constructor() {
    super();
    this.state = {
      page: 1,
      limit: 10,
      sortBy: null,
      order: 'desc',
      // Search Filter
      query: null,
      inventory_category_id: 'all',
      status: 'all',
      created_date: null,
      //
      resetDatatable: null,
      resetPage: null,
      errorMessage: null,
      timelineAccordion: false,
      showMoreDesc: false,
      modalFormShow: false,
      isEdit: false,
      modalDetailShow: false,
      formData: {
        id: null,
        inventory_no: null,
        inventory_name: null,
        inventory_category_id: null,
        status: null,
        date: null,
        description: null,
        last_updated: null,
      },

      snackbar: false,
      snackbarVertical: 'top',
      snackbarHorizontal: 'right',
    };
  }

  checkboxTheme = () => createTheme({
    palette: {
      primary: {
        main: '#00AFF0',
      },
    },
  })

  timelineTheme = () => createTheme({
    palette: {
      primary: {
        main: '#00AFF0',
      },
      secondary: {
        main: '#FFC226',
      },
      warning: {
        main: '#F00B38',
      },
      success: {
        main: '#008000',
      },
    },
    components: {
      MuiTimelineItem: {
        styleOverrides: {
          root: {
            minHeight: '46px !important',
          },
        },
      },
      MuiTimelineContent: {
        styleOverrides: {
          root: {
            padding: '6px 4px 6px 12px !important',
          },
        },
      },
      MuiTimeline: {
        styleOverrides: {
          root: {
            padding: '6px 16px 0px 16px !important',
          },
        },
      },
    },
  })

  snackbarTheme = () => themeTemplate({
    palette: {
      primary: {
        main: '#00AFF0',
      },
    },
    components: {
      MuiSnackbarContent: {
        styleOverrides: {
          root: {
            color: '#000000 !important',
            backgroundColor: '#FFFFFF !important',
          },
        },
      },
    },
  });

  modalTitleTheme = () => createTheme({
    palette: {
      primary: {
        main: '#00AFF0',
      },
    },
    components: {
      MuiTypography: {
        styleOverrides: {
          h6: {
            fontSize: 20,
            fontWeight: 600,
            color: '#656464',
          },
        },
      },
    },
  });
  accordionTheme = () => createTheme({
    palette: {
      primary: {
        main: '#00AFF0',
      },
    },
    components: {
      MuiAccordionSummary: {
        styleOverrides: {
          content: {
            display: 'flex',
            justifyContent: 'space-between',
            minHeight: '0px !important',
            margin: '13px 0px 8px 0px !important',
          },
          expanded: {
            // display: 'flex',
            justifyContent: 'space-between',
            minHeight: '0px !important',
            // margin: '8px 0px 0px 0px !important',
          },
          root: {
            minHeight: '0px !important',
          },
        },
      },
      MuiAccordionDetails: {
        styleOverrides: {
          root: {
            display: '',
            padding: '0px !important',
          },
        },
      },
      MuiIconButton: {
        styleOverrides: {
          root: {
            padding: '8px 4px 8px 12px !important',
            marginTop: '6px !important',
          },
        },
      },
    },
  })


  UNSAFE_componentWillMount() {
    this.fetchDatatable();
    this.props.checkStatus();
    this.props.checkStatusUser();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const {trigger, isSuccess, isLoading, message, isError, isSuccessPost, exportData, masterInventoryCategory} = nextProps;

    if (trigger === STATE.EXPORT_SUCCESS ) {
      // toast('success', 'File successfully exported & downloaded');
      this.setState({snackbar: true});
      this.props.updateExportNotification({
        fileName: 'Master Inventory History',
        ...exportData,
      });
    }

    if (isSuccess) {
      if (trigger === STATE.CREATE_SUCCESS ||
            trigger === STATE.UPDATE_SUCCESS ||
              trigger === STATE.DELETE_SUCCESS
      ) {
        this.handleReset();
        this.fetchDatatable();
        this.setState({
          modalFormShow: false,
        });
        toast('success', message);
      }
    }

    if (isError && message) {
      if (isObject(message)) this.setState({errorMessage: message});
      if (!isObject(message)) toast('error', message);
    }
  }

  handleResetForm = (closeModal) => {
    this.setState({
      errorMessage: null,
      formData: {
        id: null,
        inventory_no: null,
        inventory_name: null,
        inventory_category_id: null,
        status: null,
        date: null,
        description: null,
        last_updated: null,
      },
    });
    if (closeModal) {
      this.setState({
        modalDetailShow: false,
      });
    }
  };

  handleSearch = (event) => {
    const {value} = event.target;
    this.setState({query: value}, () => {
      this.handleDebounceSearch(value);
    });
  };

  toggleResetPage = () => {
    this.setState({resetPage: true, page: 1, limit: 10}, () =>
      this.setState({resetPage: null}),
    );
  };

  handleButtonFilter = () => {
    const {
      page,
      limit,
      sortBy,
      order,
      query,
      status,
      created_date,
      method,
      inventory_category_id,
    } = this.state;

    // if (start_date === null || end_date === null) {
    //   return this.setState({
    //     errorFilterDate: true,
    //   });
    // }

    this.props.getMasterInventory({
      ...(page && {page}),
      ...(limit && {limit}),
      ...(query && {query}),
      ...(sortBy && {sort_by: sortBy}),
      ...(order && {order}),
      ...(status && status !== 'all' && {status}),
      ...(created_date && {created_date: convDate(created_date, 'YYYY-MM-DD')}),
      ...(inventory_category_id && inventory_category_id !== 'all' && {inventory_category: inventory_category_id}),
    });
  }

  handleReset = () => {
    const state = {
      page: 1,
      limit: 10,
      sortBy: null,
      order: 'desc',
      query: null,
      inventory_category_id: 'all',
      status: 'all',
      resetDatatable: null,
      resetPage: null,
      created_date: null,
      errorMessage: null,
      errorFilterDate: false,
      checkboxFilter: false,
      modalDetailShow: false,
      formData: {
        id: null,
        inventory_no: null,
        inventory_name: null,
        inventory_category_id: null,
        status: null,
        date: null,
        description: null,
        last_updated: null,
      },
    };
    this.setState(state, () => this.fetchDatatable());
    this.toggleResetDatatable(true);
    this.toggleResetPage();
  };

  toggleResetDatatable = (status) => {
    this.setState({resetDatatable: status}, () =>
      this.setState({resetDatatable: null}),
    );
  };

  handleDebounceSearch = _.debounce((query) => {
    this.toggleResetPage();
    this.setState({page: null}, () => this.fetchDatatable());
  }, 500);


  handleFilterChange = (event) => {
    const {value, name} = event.target;
    this.setState({[name]: value, page: null});
  };

  handleFilterDateChange = (params) => {
    const {value, name} = params;
    this.setState(
        {[name]: value, page: null},
    );
  };

  handleFilterCheckbox = (event) => {
    const {checked} = event.target;

    if (checked) {
      this.setState({
        filterCheckbox: checked,
      });

      const state = {
        page: 1,
        limit: 10,
        query: '',
        sortBy: null,
        created_date: null,
        order: 'desc',
        method: 'all',
        inventory_category_id: 'all',
        status: 'all',
        errorFilterDate: false,
      };
      this.setState(state, () => this.fetchDatatable());
      this.toggleResetDatatable(true);
      this.toggleResetPage();
    } else {
      this.setState({
        filterCheckbox: checked,
      });

      const state = {
        page: 1,
        limit: 10,
        query: '',
        sortBy: null,
        created_date: null,
        order: 'desc',
        method: 'all',
        inventory_category_id: 'all',
        status: 'all',
      };
      this.setState(state, () => this.fetchDatatable());
      this.toggleResetDatatable(true);
      this.toggleResetPage();
    }
  }

  handleExport = () => {
    const {
      page,
      limit,
      sortBy,
      order,
      query,
      status,
      inventory_category_id,
      created_date,
    } = this.state;
    this.props.exportExcel({
      ...(page && {page}),
      ...(limit && {limit}),
      ...(sortBy && {sort_by: sortBy}),
      ...(created_date && {created_date: convDate(created_date, 'YYYY-MM-DD')}),
      ...(order && {order}),
      ...(query && {query}),
      ...(status && status !== 'all' && {status}),
      ...(inventory_category_id && inventory_category_id !== 'all' && {inventory_category: inventory_category_id}),
    });
  }

  handleCloseSnackbar = () => {
    this.setState({snackbar: false});
  };

  renderSnackbar = () => {
    const {snackbar, snackbarVertical, snackbarHorizontal} = this.state;
    const action = (
      <Button color="primary" size="small" onClick={() => this.props.history.push('/export-activity')}>
        View Activity
      </Button>
    );
    return (
      <ThemeProvider theme={this.snackbarTheme()}>
        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={snackbar}
          onClose={this.handleCloseSnackbar}
          message="Your file is in the queue, click here to see the progress"
          key={snackbarVertical + snackbarHorizontal}
          action={action}
        />
      </ThemeProvider>
    );
  }

  renderFilter = () => {
    const {classes, message, exportLoading, masterInventoryData, masterInventoryCategory} = this.props;
    const {query, inventory_category_id, status, created_date, errorMessage} = this.state;
    const renderStatusOption = () => {
      const options = [
        {id: 'all', name: 'All'},
        {id: 'available', name: 'Available'},
        {id: 'unavailable', name: 'Unavailable'},
      ];
      return options;
    };

    const categoryOption = [
      {id: 'all', name: 'All'},
    ];
    if (masterInventoryCategory) {
      masterInventoryCategory.map((item) => {
        categoryOption.push({id: item.name, name: item.name});
      });
    }

    return (
      <Paper variant='outlined' className={classes.paper}>
        {this.state.filterCheckbox ? (
          <>
            <MInput
              autoFocus
              className={`${classes.filter} ${classes.searchInput}`}
              fullWidth={false}
              variant="standard"
              name="query"
              label="Search"
              placeholder="Search keyword"
              value={query}
              onChange={this.handleSearch}
              InputProps={{
                endAdornment: (
                  <IconButton position="end">
                    <Search className={classes.searchIcon} />
                  </IconButton>
                ),
              }}
            />
            <ThemeProvider theme={this.checkboxTheme()}>
              <FormControlLabel
                style={{display: 'inline-flex', verticalAlign: 'bottom'}}
                control={
                  <Checkbox
                    color='primary'
                    checked={this.state.filterCheckbox}
                    onChange={(e) => this.handleFilterCheckbox(e)}
                  />
                }
                label="Filter"
              />
            </ThemeProvider>
            <MSelect
              classNameFC={`${classes.formControlStatus} ${classes.filter}`}
              name="inventory_category_id"
              value={inventory_category_id}
              label="Inventory Category"
              onChange={this.handleFilterChange}
              keyPair={['id', 'name']}
              options={categoryOption ? categoryOption : []}
              variant={'standard'}
            />
            <MSelect
              shrink={true}
              fullWidth={false}
              classNameFC={`${classes.formControlStatus} ${classes.filter}`}
              variant="standard"
              name="status"
              label="Status"
              keyPair={['id', 'name']}
              options={renderStatusOption()}
              value={status}
              onChange={this.handleFilterChange}
              // error={
              // this.state.start_date === null && this.state.end_date !== null ? 'Start Date is required' :
              //   start_date === null && this.state.errorFilterDate ? 'Start Date is required' :
              //     undefined
              // }
            />
            <MKeyBoardDatepicker
              className={classes.filterDatePicker}
              name="created_date"
              label="Created Date"
              value={created_date}
              onDateChange={this.handleFilterDateChange}
              placeholder="dd-mm-yyyy"
              maxDate={convDate(moment())}
            />
            {/*
            <MKeyBoardDatepicker
              className={classes.filterDatePicker}
              name="end_date"
              label="End Date"
              value={end_date}
              onDateChange={this.handleFilterDateChange}
              placeholder="dd-mm-yyyy"
              minDate={new Date(start_date)}
              error={
                this.state.start_date !== null && this.state.end_date === null ? 'End Date is required' :
                  end_date === null && this.state.errorFilterDate ? 'End Date is required' :
                    undefined
              }
            /> */}
            <div className={classes.btnFilterContainer}>
              <MButton
                id='btn_apply_filter_master'
                className={classes.filterBtn}
                label="Apply"
                color="primary"
                onClick={this.handleButtonFilter}
              />
              <MButton
                id='btn_reset_filter_master'
                className={classes.filterBtn}
                label="Reset"
                color="primary"
                icon={<Refresh />}
                onClick={this.handleReset}
              />
              <MButton
                id='btn_export_master'
                className={`${classes.btnExport} ${classes.filterBtnEnd}`}
                label="Export"
                icon={<SaveAlt />}
                onClick={this.handleExport}
                loading={exportLoading}
                disabled={masterInventoryData?.length < 1 ? true : false}
              />
            </div>
          </>
        ) : (
          <Grid container justifyContent='space-between'>
            <Grid item>
              <MInput
                autoFocus
                className={`${classes.filter} ${classes.searchInput}`}
                fullWidth={false}
                variant="standard"
                name="query"
                label="Search"
                placeholder="Search keyword"
                value={query}
                onChange={this.handleSearch}
                InputProps={{
                  endAdornment: (
                    <IconButton position="end">
                      <Search className={classes.searchIcon} />
                    </IconButton>
                  ),
                }}
              />
              <ThemeProvider theme={this.checkboxTheme()}>
                <FormControlLabel
                  style={{display: 'inline-flex', verticalAlign: 'bottom'}}
                  control={
                    <Checkbox
                      color='primary'
                      checked={this.state.filterCheckbox}
                      onChange={(e) => this.handleFilterCheckbox(e)}
                    />
                  }
                  label="Filter"
                />
              </ThemeProvider>
            </Grid>
            <Grid item>
              <div className={classes.btnFilterContainer}>
                <MButton
                  id='btn_export_master'
                  className={`${classes.btnExport} ${classes.filterBtnEnd}`}
                  label="Export"
                  icon={<SaveAlt />}
                  onClick={this.handleExport}
                  loading={exportLoading}
                  disabled={masterInventoryData?.length < 1 ? true : false}
                />
                <br />
              </div>
            </Grid>
          </Grid>
        )}
      </Paper>
    );
  }

  fetchDatatable = () => {
    this.props.checkStatus();
    this.props.checkStatusUser();
    this.props.getMasterInventoryCategory();
    const {
      page,
      limit,
      sortBy,
      order,
      query,
      status,
      inventory_category_id,
      created_date,
    } = this.state;
    this.props.getMasterInventory({
      ...(page && {page}),
      ...(limit && {limit}),
      ...(sortBy && {sort_by: sortBy}),
      ...(created_date && {created_date: convDate(created_date, 'YYYY-MM-DD')}),
      ...(order && {order}),
      ...(query && {query}),
      ...(status && status !== 'all' && {status}),
      ...(inventory_category_id && inventory_category_id !== 'all' && {inventory_category_id}),
    });
  };

  handleReload = (params) => {
    this.setState(params, () => this.fetchDatatable());
  };

  handleConfirmDelete = (data) => {
    const inventory_status = data[5] ? data[5] : null;
    const office_status = data[10] ? data[10] : null;

    if (inventory_status === 'available' && office_status === 'request') {
      showPopup(
          'confirm',
          'This item is being requested, are you sure you want to delete? All request data will be deleted',
          'Yes',
          () => this.props.deleteMasterInventory(data[0]),
      );
    } else {
      showPopup(
          'confirm',
          'Are you sure you want to delete this data ?',
          'Yes',
          () => this.props.deleteMasterInventory(data[0]),
      );
    }
  };

  renderActions = (params) => {
    const svgIcon = (
      <Icon>
        <img style={{display: 'flex', padding: 4, width: 19}} alt="completed" src={completedIcon} />
      </Icon>
    );
    return (
      <Grid container wrap='nowrap' direction='row'>
        <Grid item>
          <Link to ={'/master-inventory-detail?id=' + params[0] + '&ref=1'}>
            <Tooltip title="Detail">
              <div style={{display: 'inline', marginRight: '5px'}}>
                <Button
                  id='btn_detail_master'
                  variant="contained"
                  color="primary"
                  size='small'
                  startIcon={<Search />}
                  style={{
                    'backgroundColor': '#00AFF0',
                    'boxShadow': 'none',
                    'color': '#fff',
                    '&:hover': {
                      boxShadow: 'none',
                      backgroundColor: '#00A2DE',
                    },
                    'minWidth': '10px',
                    'paddingRight': '0px',
                  }}
                >
                </Button>
              </div>
            </Tooltip>
          </Link>
        </Grid>
        <Grid item>
          <Tooltip title="Edit">
            <div style={{display: 'inline', marginRight: '5px'}}>
              <Button
                id='btn_edit_master'
                variant="contained"
                color="primary"
                size='small'
                onClick={() => this.handleFormToggle('edit', params)}
                startIcon={<Edit />}
                style={{
                  'backgroundColor': '#00AFF0',
                  'boxShadow': 'none',
                  'color': '#fff',
                  '&:hover': {
                    boxShadow: 'none',
                    backgroundColor: '#00A2DE',
                  },
                  'minWidth': '10px',
                  'paddingRight': '0px',
                }}
              >
              </Button>
            </div>
          </Tooltip>
        </Grid>
        <Grid item>
          <Tooltip title="Delete">
            <div style={{display: 'inline', marginRight: '5px'}}>
              <Button
                id='btn_delete_master'
                variant="contained"
                color="secondary"
                size='small'
                onClick={() => this.handleConfirmDelete(params)}
                startIcon={<Delete />}
                style={{
                  'backgroundColor': '#E5AF5A',
                  'boxShadow': 'none',
                  'color': '#fff',
                  '&:hover': {
                    boxShadow: 'none',
                    backgroundColor: '#b78c48',
                  },
                  'minWidth': '10px',
                  'paddingRight': '0px',
                }}
              >
              </Button>
            </div>
          </Tooltip>
        </Grid>
      </Grid>
    );
  };

  handleDetail = (type, data) => {
  };

  toggleTimelineAccordion = () => {
    this.setState({
      timelineAccordion: !this.state.timelineAccordion,
    });
  }


  handleInputChange = (event) => {
    const {formData, errorMessage} = this.state;
    const {name, value} = event.target;
    this.setState({
      formData: {...formData, [name]: value},
      errorMessage: {...errorMessage, [name]: null},
    });
  };

  handleSelectSearch = (event, data, name, type) => {
    const {formData, errorMessage} = this.state;
    if (type === 'filter') {
      this.setState({
        [name]: data.name,
        errorMessage: {...errorMessage, [name]: null},
      });
    } else {
      this.setState({
        formData: {...formData, [name]: data},
        errorMessage: {...errorMessage, [name]: null},
      });
    }
  };

  handleFormToggle = (type, data) => {
    const {
      creatable,
      creatableMessage,
      isSuperAdmin,
      orgStatusData,
    } = this.props;

    if (type === 'add') {
      this.setState({
        isEdit: false,
        modalFormShow: !this.state.modalFormShow,
        errorMessage: false,
        formData: {
          id: null,
          inventory_no: null,
          inventory_name: null,
          inventory_category_id: null,
          status: null,
          date: null,
          description: null,
          last_updated: null,
        },
      });
    } else if (type === 'edit') {
      const inventory_status = data[5] ? data[5] : null;
      const office_status = data[10] ? data[10] : null;

      if (inventory_status === 'unavailable' && office_status === 'on_going') {
        return showPopup(
            'info',
            'You are not able to update, this item is in use',
        );
      }

      if (inventory_status === 'available' && office_status === 'request') {
        return showPopup(
            'info',
            'This item is being requested, you have to reject it first',
        );
      }
      this.setState({
        isEdit: true,
        modalFormShow: !this.state.modalFormShow,
        errorMessage: false,
        formData: {
          id: data[0],
          inventory_no: data[2],
          inventory_name: data[3],
          status: data[5],
          date: data[6],
          description: data[7],
          last_updated: data[8],
          inventory_category_id: {id: data[9], name: data[4]},
        },
      });
    } else {
      this.setState({
        isEdit: true,
        modalFormShow: !this.state.modalFormShow,
        errorMessage: false,
        formData: {
          id: null,
          inventory_no: null,
          inventory_name: null,
          inventory_category_id: null,
          status: null,
          date: null,
          description: null,
          last_updated: null,
        },
      });
    }
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const {isEdit} = this.state;
    const {
      id,
      inventory_no,
      inventory_name,
      inventory_category_id,
      status,
      date,
      description,
      last_updated,
    } = this.state.formData;

    if (!isEdit) {
      this.props.createMasterInventory({
        ...(inventory_no && {inventory_no}),
        ...(inventory_name && {inventory_name}),
        ...(inventory_category_id && {inventory_category_id: inventory_category_id.id}),
        ...(status && {status}),
        ...(description && {description}),
      });
    } else {
      this.props.updateMasterInventory(id, {
        ...(inventory_no && {inventory_no}),
        ...(inventory_name && {inventory_name}),
        ...(inventory_category_id && {inventory_category_id: inventory_category_id.id}),
        ...(status && {status}),
        ...(description && {description}),
      });
    }
  };

  renderModalForm = () => {
    const {
      classes,
      isLoading,
      masterInventoryData,
      masterInventoryCategory,
    } = this.props;
    const {
      id,
      inventory_no,
      inventory_name,
      status,
      date,
      description,
      last_updated,
      inventory_category_id,
    } = this.state.formData;
    const {
      modalFormShow,
      isEdit,
      errorMessage,
    } = this.state;

    const categoryOption = [];
    if (masterInventoryCategory) {
      masterInventoryCategory.map((item) => {
        categoryOption.push({id: item.id, name: item.name});
      });
    }

    const optionDummy = [
      {id: 'barang1', name: 'Barang1'},
      {id: 'barang2', name: 'Barang2'},
      {id: 'barang3', name: 'Barang3'},
    ];

    const titleDialog = isEdit ? 'Update Inventory' : 'Create Inventory';

    return (
      <Dialog
        open={modalFormShow}
        onClose={() => this.handleFormToggle('close')}
        maxWidth="sm"
        fullWidth={true}
        scroll="paper"
      >
        <MuiThemeProvider theme={this.modalTitleTheme()}>
          <ModalTitle
            className={classes.modalTitle}
            title={titleDialog}
            onClose={() => this.handleFormToggle('close')}
          />
        </MuiThemeProvider>
        <form autoComplete='off'>
          <DialogContent className={classes.dialogContent}>
            <MInput
              className={classes.formControl}
              name="inventory_no"
              label="Inventory Number *"
              placeholder="Enter inventory number"
              defaultValue={inventory_no}
              onBlur={this.handleInputChange}
              error={
                errorMessage && errorMessage.inventory_no ?
                  errorMessage.inventory_no :
                  undefined
              }
            />
            <MInput
              className={classes.formControl}
              name="inventory_name"
              label="Inventory Name *"
              placeholder="Enter inventory name"
              defaultValue={inventory_name}
              onBlur={this.handleInputChange}
              error={
                errorMessage && errorMessage.inventory_name ?
                  errorMessage.inventory_name :
                  undefined
              }
            />
            <Autocomplete
              className={classes.formControl}
              style={{marginBottom: 14, marginTop: 14}}
              ListboxProps={{style: {maxHeight: '12rem'}}}
              value={inventory_category_id}
              onChange={(event, newValue) => {
                this.setState({
                  formData: {
                    ...this.state.formData,
                    inventory_category_id: newValue,
                  },
                });
              }}
              id="controllable-states-demo"
              options={categoryOption}
              getOptionLabel={(option) => option.name}
              renderInput={(params) =>
                <TextField
                  {...params}
                  className={classes.formControl}
                  label="Inventory Category *"
                  variant='outlined'
                  error={
                    errorMessage && errorMessage.inventory_category_id ?
                      true : false
                  }
                  helperText={
                    errorMessage && errorMessage.inventory_category_id ?
                      errorMessage.inventory_category_id :
                      undefined
                  }
                />
              }
              disableClearable
            />
            <Typography className={errorMessage && errorMessage.status ? classes.statusError : classes.statusForm}>Status*</Typography>
            <FormControl>
              <RadioGroup
                name="status"
                value={status}
                onChange={this.handleInputChange}
              >
                <div>
                  <FormControlLabel value="available" control={<Radio color='primary' />} label="Available" />
                  <FormControlLabel value="unavailable" control={<Radio color='primary' />} label="Unavailable" />
                </div>
              </RadioGroup>
            </FormControl>
            {errorMessage && errorMessage.status ? (
              <Typography className={classes.statusFormError}>{
                errorMessage && errorMessage.status ?
                    errorMessage.status : null
              }</Typography>
            ) : null}
            <MInput
              className={classes.formControl}
              name="description"
              label="Description *"
              placeholder="Enter description"
              defaultValue={description}
              onBlur={this.handleInputChange}
              error={
                errorMessage && errorMessage.description ?
                  errorMessage.description :
                  undefined
              }
              multiline
              rows={3}
            />
          </DialogContent>
          <DialogActions className={classes.dialogFooter}>
            <MButton
              id='cancel-btn=detail'
              className={classes.cancelBtn}
              label="Cancel"
              onClick={() => this.handleFormToggle('close')}
            />
            <MButton
              id='submit-btn-detail'
              className={classes.defaultBtn}
              label={titleDialog}
              type="submit"
              icon={<Send />}
              onClick={this.handleSubmit}
              loading={isLoading ? true : false}
            />
          </DialogActions>
        </form>
      </Dialog>
    );
  }

  render() {
    const {classes, masterInventoryData, masterInventoryMeta, isLoading} = this.props;
    const {resetDatatable, resetPage} = this.state;

    const dummyData = [
      {
        id: 1,
        no: '1',
        inventory_no: 'B102',
        inventory_name: 'Asus Vivobook R505Z',
        inventory_category_id: 'tools gadget',
        status: 'available',
        date: '2023-04-04',
        description: 'Laptop Asus VivoBook X505Z 2019-Ryzen 5-Ram 4GB-HDD 500GB-AMD Radeon Graphic Vega Graphic 8-15 Inch HD Display Screen-Nano Edge',
        last_updated: '2023-04-04',
      },
      {
        id: 2,
        no: '2',
        inventory_no: 'B103',
        inventory_name: 'Honda Supra X 125',
        inventory_category_id: 'transportation',
        status: 'unavailable',
        date: '2023-04-04',
        description: 'Motor Honda Supra X 125cc warna hitam, plat nomor Bandung',
        last_updated: '2023-04-04',
      },
      {
        id: 3,
        no: '3',
        inventory_no: 'B104',
        inventory_name: 'Brompton Bike',
        inventory_category_id: 'transportation',
        status: 'unavailable',
        date: '2023-04-04',
        description: 'Sepeda lipat',
        last_updated: '2023-04-04',
      },
    ];
    const columns = [
      {name: 'id', label: 'Id', display: false},
      {name: 'no', label: 'No', display: true},
      {name: 'inventory_no', label: 'No. Inventory', display: true},
      {
        name: 'inventory_name',
        label: 'Inventory Name',
        display: true,
        customBodyRender: (value) => (value ? value : '-'),
      },
      {
        name: 'inventory_category',
        label: 'Inventory Category',
        display: true,
        customBodyRender: (value) => (value ? <Typography style={{fontSize: 14, fontWeight: 400, textTransform: 'capitalize'}}>{value}</Typography> : '-'),
      },
      {
        name: 'status',
        label: 'Status',
        display: true,
        customBodyRender: (value) => {
          if (value === 'available') {
            return <strong style={{color: '#00A2DE'}}>{'Available'}</strong>;
          } else if (value === 'unavailable') {
            return <strong style={{color: '#F00B38'}}>{'Unavailable'}</strong>;
          } else {
            return '-';
          }
        },
      },
      {
        name: 'date',
        label: 'Created Date',
        display: true,
        customBodyRender: (value) => {
          return <div style={{whiteSpace: 'nowrap'}}>{value ? convDate(value, 'DD-MM-YYYY') : '-'}</div>;
        },
      },
      {name: 'description', label: 'Description', display: false},
      {name: 'last_updated', label: 'Last Updated', display: false},
      {
        name: 'inventory_category_id',
        label: 'Inventory Category',
        display: false,
        customBodyRender: (value) => (value ? <Typography style={{fontSize: 14, fontWeight: 400, textTransform: 'capitalize'}}>{value}</Typography> : '-'),
      },
      {name: 'office_status', label: 'Office Status', display: false},
    ];
    return (
      <div className={classes.root}>
        {this.renderFilter()}
        <Datatable
          title={'Inventory List'}
          loading={isLoading}

          // Comment this if using API Data
          // dataSource={dummyData}
          // total={dummyData.length}
          // page={1}
          //
          // Comment this if using Dummy Data
          dataSource={masterInventoryData}
          total={masterInventoryMeta ? masterInventoryMeta.total : 0}
          page={masterInventoryMeta ? masterInventoryMeta.page : 1}
          //
          columns={columns}
          handleReload={(params) => this.handleReload(params)}
          handleCreate={() => this.handleFormToggle('add')}
          // handleDetail={(params) => this.handleDetail('open', params)}
          customActions={(params) => this.renderActions(params)}
          toggleResetAll={resetDatatable}
          toggleResetPage={resetPage}
          manualNumbering={true}
        />
        {this.renderModalForm()}
        {this.renderSnackbar()}
      </div>
    );
  }
}

const mapStateToProps = ({masterInventory}) => {
  return {

    isLoading: masterInventory.isLoading,
    isError: masterInventory.isError,
    isSuccess: masterInventory.isSuccess,
    message: masterInventory.message,
    trigger: masterInventory.trigger,
    masterInventoryData: masterInventory.masterInventoryData,
    masterInventoryCategory: masterInventory.masterInventoryCategory,
    masterInventoryMeta: masterInventory.masterInventoryMeta,

    isLoadingPut: masterInventory.isLoadingPut,
    isSuccessPut: masterInventory.isSuccessPut,
    isFailurePut: masterInventory.isFailurePut,
    isErrorPut: masterInventory.isErrorPut,
    messagePut: masterInventory.messagePut,
    status: masterInventory.status,
    exportLoading: masterInventory.exportLoading,
    exportData: masterInventory.exportData,
  };
};
const mapDispatchToProps = (dispatch) => ({
  checkStatus: () => dispatch(checkStatus()),
  checkStatusUser: () => dispatch(checkStatusUser()),

  getMasterInventory: (params) => dispatch(getMasterInventory(params)),
  getMasterInventoryCategory: (params) => dispatch(getMasterInventoryCategory(params)),
  // getMasterInventoryDetail: (id, params) => dispatch(getMasterInventoryDetail(id, params)),
  createMasterInventory: (params) => dispatch(createMasterInventory(params)),
  updateMasterInventory: (id, params) => dispatch(updateMasterInventory(id, params)),
  deleteMasterInventory: (id, params) => dispatch(deleteMasterInventory(id, params)),
  exportExcel: (params) => dispatch(exportExcel(params)),
  clearState: () => dispatch(clearState()),
  updateExportNotification: (params) => dispatch(updateExportNotification(params)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withStyles(styles)(MasterInventory));
