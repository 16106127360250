/* eslint-disable max-len */
import {OVERTIME} from '../../services';
import {OVERTIME_STATE as STATE} from './overtime.types';
import {transformObject, isArray} from '../../helpers';

export const getOvertimeHistory = (params) => {
  return (dispatch) => {
    dispatch(request(STATE.FETCH));
    OVERTIME.getOvertimeHistory(params)
        .then(
            (response) => {
              dispatch(requestSuccess(STATE.FETCH_SUCCESS, response.data));
            },
            (error) => {
              const errors = error.response.data.data.errors;
              const message = isArray(errors) ? transformObject(errors) : error.response.data.data.message;
              dispatch(requestFailed(STATE.FETCH_FAILED, message));
            },
        );
  };
};

export const putChangeOvertimeStatus = (params) => {
  return (dispatch) => {
    dispatch(request(STATE.CHANGE_STATUS_OVERTIME, true));
    OVERTIME.putChangeStatusOvertime(params)
        .then(
            (response)=> {
              dispatch(requestSuccess(STATE.CHANGE_STATUS_OVERTIME_SUCCESS, response.data, params.status));
            },
            (error) => {
              const errors = error.response.data.data.errors;
              const message = isArray(errors) ? transformObject(errors) : error.response.data.data.message;
              dispatch(requestFailed(STATE.CHANGE_STATUS_OVERTIME_FAILURE, message));
            },
        );
  };
};

export const exportExcel = (params) => {
  return (dispatch) => {
    dispatch(request(STATE.EXPORT, true));
    OVERTIME.exportExcel(params)
        .then(
            (response) => {
              dispatch(requestSuccess(STATE.EXPORT_SUCCESS, response.data.data));
            },
            (error) => {
              const errors = error.response.data.data.errors;
              const message = isArray(errors) ? transformObject(errors) : error.response.data.data.message;
              dispatch(exportFailed(STATE.EXPORT_FAILED, message));
            },
        );
  };
};

const request = (requestType, custom = false) => ({
  type: custom ? requestType : STATE.REQUEST,
  requestType,
});

const requestFailed = (requestType, response) => ({
  type: STATE.REQUEST_FAILED,
  requestType,
  payload: response,
});

const requestSuccess = (requestType, response, status = null) => ({
  type: requestType,
  payload: response,
  status: status,
});

const exportFailed = (requestType, response) => ({
  type: requestType,
  payload: response,
});

