/* eslint-disable no-unreachable */
/* eslint-disable array-callback-return */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
/* eslint-disable max-len */
import React from 'react';
import {connect} from 'react-redux';
import {withStyles, createTheme} from '@material-ui/core/styles';
import {createTheme as makeMuiTheme, ThemeProvider, Tooltip} from '@mui/material';
import {createTheme as createThemeTimeline, ThemeProvider as ThemeProviderTimeline} from '@mui/material/styles';
import styles from './styles';
import PropTypes from 'prop-types';
import {
  MInput,
  MSelect,
  MButton,
  ModalTitle,
  MDatepicker,
  MAutoComplete,
  MSelectSearch,
  MInputMask,
  MKeyBoardDatepicker,
  MSelectRole,
} from '../../components/form';
import {
  Avatar,
  Box,
  CardMedia,
  CircularProgress,
  Collapse,
  CssBaseline,
  Dialog,
  DialogContent,
  Divider,
  Drawer,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
  MuiThemeProvider,
  Paper,
  Switch,
  TextField,
  Typography,
  Button,
  DialogActions,
  Link as ButtonLink,
  InputAdornment,
} from '@material-ui/core';
import {Link} from 'react-router-dom';
import {
  Add,
  ArrowBack,
  AttachFile,
  Crop,
  Delete,
  Edit,
  ExpandLess,
  ExpandMore,
  HelpOutline,
  Info,
  Refresh,
  Search,
  Send,
  Visibility,
  VisibilityOff,
} from '@material-ui/icons';
import {checkPremium} from '../../redux/upgradeAccount/upgradeAccount.actions';
import {
  blobToFile,
  checkFileUploadSize,
  checkSuperadmin, convDate, dateDiff, getTimeDiff, isObject, remainingDays, showPopup, thousandSeparator, toast, isArray,
} from '../../helpers';
import {getJobTitleByOrg} from '../../redux/jobTitle/jobTitle.actions';
import {
  getBankList,
  getEmployeeDetail,
  updatePersonalData,
  updateEmploymentData,
  updateRemoteData,
  updateAccountData,
  getContractUser,
  addContractUser,
  updateContractUser,
  updateContractStatus,
  updateEmployeeStatus,
  deleteContractUser,
  getAllRole,
  getAllManager,
  getOneManager,
  getSingleManager,
  updateDeductedLeave,
  getUpdateHistory,
  getLeaveHistory,
  clearState,
  getUserPersonalLeave,
  createAdjustmentLeave,
  // getListEmployeeType,
} from '../../redux/userManagement/userManagement.actions';
import {checkStatus} from '../../redux/organization/organization.actions';
import _ from 'lodash';
import {checkStatus as checkStatusUser} from '../../redux/userManagement/userManagement.actions';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandMore';
import Timeline from '@mui/lab/Timeline';
import TimelineItem, {timelineItemClasses} from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineOppositeContent, {
  timelineOppositeContentClasses,
} from '@mui/lab/TimelineOppositeContent';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';

import {
  getDivision,
  getDivisionByOrg,
  createDivision,
  clearState as clearStateDivision,
} from '../../redux/divisionManagement/divisionManagement.actions';
import {
  getGeneralSetting,
} from '../../redux/generalSetting/generalSetting.actions';
import {ListItemButton} from '@mui/material';
import {nullFormat} from 'numeral';
import ImageCropper from '../../components/cropper';
import {Autocomplete} from '@material-ui/lab';
import {USER_STATE} from '../../redux/userManagement/userManagement.types';
import {DIVISION_STATE} from '../../redux/divisionManagement/divisionManagement.types';
import {GENERAL_SETTING_STATE} from '../../redux/generalSetting/generalSetting.types';
import moment from 'moment';
import {connectFirestoreEmulator} from 'firebase/firestore';
import Datatable from '../../components/datatable';
import {NumericFormat} from 'react-number-format';
import UserLeaveDatatable from '../../components/datatable/userLeaveDatatable';
import featureFlag from '../../config/featureFlag';


const INVOICE_PERIOD = process.env.REACT_APP_API_INVOICE_PERIOD;

class EmployeeDetail extends React.Component {
  constructor() {
    super();
    this.state = {
      page: 1,
      limit: 10,
      type: '',
      disableApprovalType: false,
      remoteWorking: false,
      isEdit: false,
      resetPage: null,
      resetDatatable: null,
      modalFormShow: false,
      showPassword: false,
      showUpgradeModal: false,
      nameFilter: null,
      nikFilter: null,
      searchFilterManager: null,
      searchManager: true,
      errorImageSize: null,
      showCropResult: false,
      isCropping: false,
      showMethod: null,
      invoiceUrl: null,
      query: '',
      sortBy: null,
      order: 'desc',
      advantech: null,
      modalImportShow: false,
      file: null,
      import_users: false,
      errorMessageAdditionalRemote: null,
      errorMessageDivision: null,
      modalGuideShow: false,
      division_name: null,
      description: null,
      toggleGeneral: true,
      toggleHistory: false,
      toggleSetting: false,
      toggleAccount: false,
      selectedIndex: 0,
      profileName: null,
      profileContract: null,
      profileStartDate: null,
      profileEndDate: null,
      profileStatus: null,
      contractStatus: null,
      roleName: null,
      modalApprovalInformation: false,
      saveDisabled: false,
      modalDetailContract: false,
      modalEditContract: false,
      modalCreateDivision: false,
      createdDivision: false,
      errorMessageEmployeeDivision: null,

      // Sub Menu Employee Detail
      personalMenu: true,
      employmentMenu: false,

      // Toggle Update Sub Menu
      personalUpdate: false,
      employmentUpdate: false,

      selectedMenu: 'personal',

      // Error Message Sub Menu
      errorMessage: null,
      errorMessageRemote: null,
      bankList: [],
      editingContract: false,
      contractEndDate: null,
      leaveId: null,

      personalData: {
        name: null,
        nik: null,
        dob: null,
        gender: null,
        marital_status: null,
        number_of_children: null,
        religion: null,
        phone: null,
        address: null,
        bank_name: null,
        bank_account_number: null,
        bank_account_name: null,
        npwp_id: null,
        emergency_contact_name: null,
        emergency_contact_relation: null,
        emergency_contact_number: null,
        user_img: null,
        imageURL: null,
        cropped_user_img: null,
      },

      employmentData: {
        name: null,
        division_name: null,
        division_id: null,
        prevDivision: null,
        job_title: null,
        job_title_id: null,
        manager_name: null,
        manager: null,
        approval_type: null,
        bpjs_ketenagakerjaan_id: null,
        health_insurance_id: null,
        health_insurance_name: null,
        employment_status: null,
        register_date: null,
        employment_type: null,
        start_date: null,
        end_date: null,
        main_salary: null,
        allowance: null,
        overtime_fee: null,
        attachment: null,
        role_id: null,
        shift: null,
        reminder: null,
      },

      contractData: {
        user_id: null,
        employment_status: false,
        register_date: null,
        employment_type: null,
        start_date: null,
        end_date: null,
        main_salary: null,
        allowance: null,
        overtime_fee: null,
        reminder: null,
        attachment: null,
      },

      remoteData: {
        limit_remote: null,
        additional_limit_remote: null,
      },
      accountData: {
        email: null,
        password: null,
      },
      leaveData: {
        year: null,
        leave_remaining: null,
        annual_leave: null,
        total_leave: null,
        deductive: null,
        additional: null,
      },

      modalAdjustmentLeave: false,
      modalLeaveHistoryDetail: false,

      errorMessageAdjustmentLeave: null,
      adjustmentLeaveForm: {
        type: null,
        days: null,
        description: null,
      },

      updateHistoryTable: {
        page: 1,
        limit: 10,
        order: 'desc',
        sortBy: null,
      },
      leaveHistoryTable: {
        page: 1,
        limit: 10,
        order: 'desc',
        sortBy: null,
      },
      userPersonalLeaveTable: {
        page: 1,
        limit: 10,
        order: 'desc',
        sortBy: null,
        leaveStartDate: null,
        leaveEndDate: null,
      },

      resetPageUpdateHistory: null,
      resetPageLeaveHistory: null,
      resetPageUserPersonalLeave: null,
      userProfileId: null,
      leaveStartDate: null,
      leaveEndDate: null,

      modalLeaveInformationData: {
        approval_history: [],
        start_date: null,
        end_date: null,
        total_days: null,
        details: null,
        file: null,
        status: null,
      },
      timelineAccordion: false,

      adjustmentLeave: {
        totalLeave: null,
        accumulativeLeave: null,
        annualLeave: null,
        leaveRemaining: null,
      },

      shifts: [],
      isMediaScreenMedium: false,

      userStatus: {},
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.selectedMenu !== prevState.selectedMenu && featureFlag.employees.enable_auto_scroll) {
      window.scrollTo(0, 0);
    }
  }

  accordionTheme = () => createThemeTimeline({
    palette: {
      primary: {
        main: '#00AFF0',
      },
    },
    components: {
      MuiAccordionSummary: {
        styleOverrides: {
          content: {
            display: 'flex',
            justifyContent: 'space-between',
            minHeight: '0px !important',
            margin: '13px 0px 8px 0px !important',
          },
          expanded: {
            // display: 'flex',
            justifyContent: 'space-between',
            minHeight: '0px !important',
            // margin: '8px 0px 0px 0px !important',
          },
          root: {
            minHeight: '0px !important',
          },
        },
      },
      MuiAccordionDetails: {
        styleOverrides: {
          root: {
            display: '',
            padding: '0px !important',
          },
        },
      },
      MuiIconButton: {
        styleOverrides: {
          root: {
            padding: '8px 4px 8px 12px !important',
            marginTop: '6px !important',
          },
        },
      },
    },
  })

  timelineTheme = () => createThemeTimeline({
    palette: {
      primary: {
        main: '#00AFF0',
      },
      secondary: {
        main: '#FFC226',
      },
      warning: {
        main: '#F00B38',
      },
      success: {
        main: '#008000',
      },
    },
    components: {
      MuiTimelineItem: {
        styleOverrides: {
          root: {
            minHeight: '46px !important',
          },
        },
      },
      MuiTimelineContent: {
        styleOverrides: {
          root: {
            padding: '6px 4px 6px 12px !important',
          },
        },
      },
      MuiTimeline: {
        styleOverrides: {
          root: {
            padding: '6px 16px 0px 16px !important',
          },
        },
      },
    },
  })

  tooltipTheme = () => createTheme({
    palette: {
      secondary: {
        main: '#00AFF0',
      },
    },
    overrides: {
      MuiSvgIcon: {
        root: {
          fontSize: 16,
          marginTop: 1,
        },
      },
      MuiIconButton: {
        root: {
          padding: 0,
        },
      },
    },
  })

  linkTheme = () => createTheme({
    palette: {
      secondary: {
        main: '#00AFF0',
      },
    },
    overrides: {
      MuiTypography: {
        body1: {
          fontSize: 13,
        },
        colorPrimary: {
          color: '#00AFF0',
        },
      },
    },
  })

  modalTitleTheme = () => createTheme({
    palette: {
      primary: {
        main: '#00AFF0',
      },
    },
    overrides: {
      MuiTypography: {
        h6: {
          fontSize: 20,
          fontWeight: 600,
          color: '#656464',
        },
      },
    },
  });

  formTheme = () => createTheme({
    palette: {
      primary: {
        main: '#00AFF0',
        contrastText: '#FFFFFF',
      },
    },
    overrides: {
      MuiFormHelperText: {
        contained: {
          marginRight: 0,
          marginLeft: 0,
        },
      },
    },
  });

  switchTheme = () => createTheme({
    palette: {
      primary: {
        main: '#00AFF0',
      },
    },
  });

  drawerInformationTheme = () => createTheme({
    palette: {
      primary: {
        main: '#00AFF0',
        contrastText: '#FFFFFF',
      },
    },
    overrides: {
      MuiDrawer: {
        paperAnchorLeft: {
          left: 'unset',
          right: 'unset',
        },
        paper: {
          top: 'unset',
          position: 'relative',
          width: 300,
          zIndex: 0,
        },
        paperAnchorDockedLeft: {
          borderRadius: '18px 0px 0px 18px',
          marginBottom: 24,
        },
      },
      MuiAvatar: {
        root: {
          width: 148,
          height: 148,
        },
      },
      MuiListItemText: {
        root: {
          paddingLeft: 8,
          paddingRight: 8,
        },
      },
      MuiList: {
        padding: {
          paddingBottom: 0,
        },
      },
      MuiTypography: {
        body1: {
          fontSize: 14,
        },
      },
    },
  });

  tooltipTheme = () => createTheme({
    palette: {
      secondary: {
        main: '#00AFF0',
      },
    },
    overrides: {
      MuiSvgIcon: {
        root: {
          fontSize: 16,
          marginTop: 1,
        },
      },
      MuiIconButton: {
        root: {
          padding: 0,
        },
      },
    },
  })

  modalTheme = () => createTheme({
    overrides: {
      MuiDialog: {
        paperWidthSm: {
          width: 575,
        },
      },
      MuiDialogContent: {
        root: {
          '&:first-child': {
            paddingTop: 0,
          },
        },
      },
    },
    palette: {
      primary: {
        main: '#00AFF0',
      },
    },
  })

  UNSAFE_componentWillMount() {
    const url_string = window.location.href;
    const url = new URL(url_string);
    const id = url.searchParams.get('id');

    this.setState({
      userProfileId: id,
    });

    this.props.getEmployeeDetail(id);
    this.fetchUserContract();
    this.fetchListEmployeeType();
    this.props.getBankList();
    this.props.getAllRole();
    // this.props.getDivision({limit: 100});
    this.props.getDivisionByOrg({limit: 30});
    this.props.checkStatus();
    this.props.checkStatusUser();
  }

  componentDidMount() {
    const {isSuperAdmin} = this.props;
    const url_string = window.location.href;
    const url = new URL(url_string);
    const id = url.searchParams.get('id');

    this.setState({
      userProfileId: id,
    });

    if (!isSuperAdmin) this.props.getJobTitleByOrg();
    this.handleWindowResize();
    window.addEventListener('resize', this.handleWindowResize);
  }

  UNSAFE_componentWillReceiveProps = (nextProps) => {
    const {
      trigger,
      isSuccess,
      isError,
      message,
      data,
      isSuperAdmin,
      divisionData,
      isErrorDivision,
      divisionMessage,
      isSuccessDivision,
      triggerDivision,

      isSuccessGet,
      isErrorGet,
      triggerGet,
      messageGet,
      employeeDetailData,
      isSuccessUpdate,
      isErrorUpdate,
      triggerUpdate,
      messageUpdate,

      bankData,
      isSuccessBank,
      triggerBank,
      oneManagerData,
      divisionDataByOrg,
      employeeTypeList,

      isLoadingAddAdjustment,
      isErrorAddAdjustment,
      isSuccessAddAdjustment,
      triggerAddAdjustment,
      messageAddAdjustment,

      isSuccessEmployeeType,
      isErrorEmployeeType,
      isLoadingEmployeeType,
      triggerEmployeeType,

      generalSettingData,
      generalSettingIsError,
      generalSettingIsSuccess,
      generalSettingMessage,
      generalSettingTrigger,
    } = nextProps;
    const {personalData, employmentData, remoteData, accountData, leaveData, searchManager} = this.state;
    const {isLoadingManager} = this.props;
    console.log(this.props, 'PROPS')
    if (isError && message) {
      if (isObject(message)) {
        const dataMessage = Object.keys(message);
        if (dataMessage[0] === 'emergency_contact_number') {
          this.setState(
            {
              errorMessage: {
                emergency_contact_number: {
                  validate: message.emergency_contact_number,
                },
              },
            });
        } else if (dataMessage[0] === 'manager') {
          this.setState(
            {
              errorMessage: {
                manager: 'manager is required',
              },
            });
        } else {
          this.setState({errorMessage: message});
        }
      }

      if (!isObject(message)) {
        if (message === 'You can only activate one document only') {
          showPopup(
            'info',
            'Please deactivate another contract and make sure the new contract has correct End Date',
          );
        } else {
          toast('error', message);
        }
      }
    }

    if (isSuccessBank) {
      if (triggerBank === USER_STATE.GET_BANK_SUCCESS) {
        this.setState({
          bankList: bankData,
        });
      }
    }

    if (isErrorDivision && divisionMessage) {
      if (isObject(divisionMessage)) this.setState({errorMessageEmployeeDivision: divisionMessage});
      if (!isObject(divisionMessage)) toast('error', divisionMessage);
    }

    if (isSuccessDivision) {
      if (triggerDivision === DIVISION_STATE.CREATE_SUCCESS) {
        this.setState({
          errorMessageDivision: null,
          division_name: null,
          description: null,
          createdDivision: true,
          modalCreateDivision: false,
        });
        toast('success', divisionMessage);
        this.props.getDivisionByOrg({limit: 100});
      }
      if (triggerDivision === DIVISION_STATE.FETCH_BY_ORG_SUCCCESS && !isLoadingManager && searchManager) {
        let createdID = 0;
        for (let i = 0; i < divisionDataByOrg.length; i++) {
          if (divisionDataByOrg[i].id > createdID) {
            createdID = {id: divisionDataByOrg[i].id, name: divisionDataByOrg[i].division_name};
          }
        }
        this.setState({
          employmentData: {
            ...this.state.employmentData,
            division_id: createdID,
          },
          createdDivision: false,
          modalCreateDivision: false,
        });
      }
    }

    if (isSuccessAddAdjustment) {
      if (triggerAddAdjustment === 'USER_CREATE_ADJUSTMENT_LEAVE_SUCCESS') {
        this.setState({
          adjustmentLeaveForm: {
            type: null,
            days: null,
            description: null,
          },
        });
        this.handleResetAdjustmentLeave();
        toast('success', messageAddAdjustment);
        this.setState({
          modalAdjustmentLeave: false,
        });
        this.props.clearState();
      }
    }

    if (isErrorAddAdjustment && messageAddAdjustment) {
      if (isObject(messageAddAdjustment)) this.setState({errorMessageAdjustmentLeave: messageAddAdjustment});
      if (!isObject(messageAddAdjustment)) toast('error', messageAddAdjustment);
    }

    if (isSuccess) {
      if (trigger === USER_STATE.UPDATE_CONTRACT_STATUS_SUCCESS || USER_STATE.ADD_CONTRACT_SUCCESS || USER_STATE.UPDATE_CONTRACT_SUCCESS) {
        this.handleResetFormContract();
        toast('success', message);
        this.fetchUserContract();
        this.fetchListEmployeeType();
        this.props.clearState();
      }

      if (trigger === USER_STATE.DELETE_CONTRACT_SUCCESS) {
        toast('success', message);
        this.fetchUserContract();
        this.props.clearState();
      }

      if (trigger === USER_STATE.UPDATE_DEDUCTED_SUCCESS) {
        toast('success', message);
        this.fetchUserLeave();
        this.props.clearState();
      }
    }


    if (isSuccess) {
      if (trigger === USER_STATE.UPDATE_EMPLOYEE_STATUS_SUCCESS) {
        toast('success', message);
        const url_string = window.location.href;
        const url = new URL(url_string);
        const id = url.searchParams.get('id');

        this.props.getEmployeeDetail(id);
        this.props.getBankList();
        this.props.getAllRole();
        this.props.clearState();
      }
      if (trigger === USER_STATE.UPDATE_PERSONAL_SUCCESS) {
        toast('success', message);
        this.setState({
          selectedIndex: 0,
          selectedMenu: 'personal',
          errorMessage: null,
        });
        const url_string = window.location.href;
        const url = new URL(url_string);
        const id = url.searchParams.get('id');

        this.props.getEmployeeDetail(id);
        this.props.getBankList();
        this.props.getAllRole();
        this.props.clearState();
      }
      if (trigger === USER_STATE.UPDATE_EMPLOYMENT_SUCCESS) {
        toast('success', message);
        this.setState({
          selectedIndex: 1,
          selectedMenu: 'employment',
          errorMessage: null,
        });
        const url_string = window.location.href;
        const url = new URL(url_string);
        const id = url.searchParams.get('id');

        this.props.getEmployeeDetail(id);
        this.props.getAllRole();
        this.props.getBankList();
        this.props.clearState();
      }
      if (trigger === USER_STATE.UPDATE_REMOTE_SUCCESS) {
        toast('success', message);
        this.setState({
          selectedIndex: 2,
          selectedMenu: 'remote',
          errorMessage: null,
        });
        const url_string = window.location.href;
        const url = new URL(url_string);
        const id = url.searchParams.get('id');

        this.props.getEmployeeDetail(id);
        this.props.getBankList();
        this.props.getAllRole();
        this.props.clearState();
      }
      if (trigger === USER_STATE.UPDATE_EMPLOYMENT_FAILED) {
        toast('error', 'Please Repeat Again');
        this.setState({
          selectedIndex: 1,
          selectedMenu: 'employment',
          errorMessage: null,
        });
      }
      if (trigger === USER_STATE.UPDATE_ACCOUNT_SUCCESS) {
        toast('success', message);
        this.setState({
          selectedIndex: 1,
          selectedMenu: 'employment',
          errorMessage: null,
        });
        const url_string = window.location.href;
        const url = new URL(url_string);
        const id = url.searchParams.get('id');

        this.props.getEmployeeDetail(id);
        this.props.getAllRole();
        this.props.getBankList();
        this.props.clearState();
      }
    }

    if (!isSuccessGet) {
      if (triggerGet === USER_STATE.GET_DETAIL_FAILED) {
        toast('error', 'User is not found');
        this.props.history.push('/user-management');
      }
    }

    if (generalSettingIsSuccess) {
      if (generalSettingTrigger === GENERAL_SETTING_STATE.FETCH_ALL_SUCCCESS) {
        if (isArray(generalSettingData) && generalSettingData.length > 0) {
          generalSettingData.map((data) => {
            if (data.key === 'check_in') {
              return this.setState({
                shifts: data.value,
              });
            }
          });
        } else {
          return this.setState({
            shifts: generalSettingData.value,
          });
        }
      }
    }

    if (isSuccessGet) {
      if (triggerGet === USER_STATE.GET_DETAIL_SUCCESS && !isLoadingManager && searchManager) {
        const url_string = window.location.href;
        const url = new URL(url_string);
        const idUser = url.searchParams.get('id');
        let job = this.handleGetJobInfo(employeeDetailData.job_title);
        const role = this.handleGetRoleInfo(employeeDetailData.role);
        const shift = this.handleGetShiftInfo(employeeDetailData.shift);
        const resShift = shift.map((item) => item.label + '(' + item.start_time + '-' + item.end_time + ')');
        if (!isSuperAdmin) job = job ? job[0].id : null;

        this.setState({
          profileName: employeeDetailData.name,
          profileContract: employeeDetailData.employee_type,
          profileStartDate: employeeDetailData.start_date,
          profileEndDate: employeeDetailData.end_date,
          roleName: employeeDetailData.role,
          profileStatus: employeeDetailData.status,
          contractStatus: employeeDetailData.employment_status,
          remoteWorking: employeeDetailData.limit_remote !== null || Number(employeeDetailData.additional_limit_remote) !== 0 ? true : false,
          contractEndDate: convDate(employeeDetailData.end_date, 'YYYY-MM-DD'),
          leaveId: employeeDetailData.leave_id,
          personalData: {
            ...personalData,
            name: employeeDetailData.name,
            nik: employeeDetailData.nik,
            dob: convDate(employeeDetailData.dob, 'DD MMMM YYYY'),
            gender: employeeDetailData.gender,
            marital_status: employeeDetailData.marital_status,
            number_of_children: employeeDetailData.number_of_children,
            religion: employeeDetailData.religion,
            phone: employeeDetailData.phone,
            address: employeeDetailData.address,
            bank_name: employeeDetailData.bank_name,
            bank_account_number: employeeDetailData.bank_account_number,
            bank_account_name: employeeDetailData.bank_account_name,
            emergency_contact_name: employeeDetailData.emergency_contact_name,
            emergency_contact_relation: employeeDetailData.emergency_contact_relation,
            emergency_contact_number: employeeDetailData.emergency_contact_number,
            imageURL: employeeDetailData.image,
            npwp_id: employeeDetailData.npwp_id,
            // user_img: employeeDetailData.,
          },

          employmentData: {
            ...employmentData,
            division_name: employeeDetailData.division_name,
            division_id: {id: employeeDetailData.division_id, name: employeeDetailData.division_name},
            job_title: employeeDetailData.job_title,
            job_title_id: job,
            manager_name: employeeDetailData.manager_name,
            // manager: manager,
            approval_type: employeeDetailData.approval_type,
            bpjs_ketenagakerjaan_id: employeeDetailData.bpjs_ketenagakerjaan_id,
            health_insurance_id: employeeDetailData.health_insurance_id,
            health_insurance_name: employeeDetailData.health_insurance_name,
            employment_status: employeeDetailData.employment_status,
            register_date: convDate(employeeDetailData.join_date, 'YYYY-MM-DD'),
            employment_type: employeeDetailData.employee_type,
            start_date: convDate(employeeDetailData.start_date, 'YYYY-MM-DD'),
            end_date: convDate(employeeDetailData.end_date, 'YYYY-MM-DD'),
            main_salary: employeeDetailData.main_salary,
            allowance: employeeDetailData.allowance,
            overtime_fee: employeeDetailData.overtime_fee,
            attachment: employeeDetailData.attachment,
            role_id: role.length ? role[0].id : null,
            shift: resShift[0],
            reminder: employeeDetailData.reminder,
          },

          remoteData: {
            ...remoteData,
            limit_remote: employeeDetailData.limit_remote,
            additional_limit_remote: employeeDetailData.additional_limit_remote,
          },
          accountData: {
            ...accountData,
            email: employeeDetailData.email,
            password: employeeDetailData.password,
          },
          leaveData: {
            ...leaveData,
            year: employeeDetailData.year,
            leave_remaining: employeeDetailData.leave_remaining,
            annual_leave: employeeDetailData.annual_leave,
            total_leave: employeeDetailData.total_leave,
            deductive: employeeDetailData.deductive,
            additional: employeeDetailData.additional,
          },
        });
        return;
      }
    }
  }

  fetchListEmployeeType = () => {
    const url_string = window.location.href;
    const url = new URL(url_string);
    const id = url.searchParams.get('id');
    // this.props.getListEmployeeType();
    this.props.getEmployeeDetail(id);
  }

  fetchUserContract = () => {
    const {page, limit, sortBy, order} = this.state;

    const url_string = window.location.href;
    const url = new URL(url_string);
    const id = url.searchParams.get('id');

    this.props.getContractUser({
      ...(id && {id}),
      ...(page && {page}),
      ...(limit && {limit}),
      ...(sortBy && {sort_by: sortBy}),
      ...(order && {order}),
    });
    this.props.getEmployeeDetail(id);
  };

  fetchUpdateHistory = (id) => {
    const {page, limit, query, order, sortBy} = this.state.updateHistoryTable;
    this.props.getUpdateHistory({page, limit, query, order, sort_by: sortBy}, id);
    // this.props.getUpdateHistory({}, id);
  }

  fetchLeaveHistory = () => {
    const url_string = window.location.href;
    const url = new URL(url_string);
    const id = url.searchParams.get('id');
    const {page, limit, query, order, sortBy} = this.state.leaveHistoryTable;
    this.props.getLeaveHistory({page, limit, query, order, sort_by: sortBy}, id);
    // this.props.getLeaveHistory({}, this.state.userProfileId);
  }

  fetchUserPersonalLeave = () => {
    const url_string = window.location.href;
    const url = new URL(url_string);
    const id = url.searchParams.get('id');
    const {page, limit, query, order, sortBy} = this.state.userPersonalLeaveTable;
    this.props.getUserPersonalLeave({page, limit, query, order, sort_by: sortBy}, id);
    // this.props.getUserPersonalLeave({}, this.state.userProfileId);
  }

  handleReloadUpdateHistory = (params) => {
    const url_string = window.location.href;
    const url = new URL(url_string);
    const id = url.searchParams.get('id');
    this.setState({updateHistoryTable: params}, () => this.fetchUpdateHistory(id));
  };

  handleReloadLeaveHistory = (params) => {
    const url_string = window.location.href;
    const url = new URL(url_string);
    const id = url.searchParams.get('id');
    this.setState({leaveHistoryTable: params}, () => this.fetchLeaveHistory(id));
  };

  handleReloadUserPersonalLeave = (params) => {
    const url_string = window.location.href;
    const url = new URL(url_string);
    const id = url.searchParams.get('id');
    this.setState({userPersonalLeaveTable: params}, () => this.fetchUserPersonalLeave(id));
  };

  fetchUserLeave = () => {
    const {leaveId, page, limit, sortBy, order} = this.state;

    const url_string = window.location.href;
    const url = new URL(url_string);
    const id = url.searchParams.get('id');

    this.props.getEmployeeDetail(id);
    this.fetchUpdateHistory(id);
    this.fetchLeaveHistory(id);
    this.fetchUserPersonalLeave(id);
  };

  handleReloadContract = (params) => {
    this.setState(params, () => this.fetchUserContract());
  };

  componentWillUnmount() {
    this.props.clearState();
    window.removeEventListener('resize', this.handleWindowResize);
  }

  handleWindowResize = () => {
    const width = window.innerWidth < 1024;
    this.setState({isMediaScreenMedium: width ? true : false});
  };


  renderApproalTypeGuide = () => {
    const {classes} = this.props;
    const {modalApprovalInformation, file} = this.state;

    const approvalList = [
      {
        title: 'Master',
        description: 'adalah pengguna yang memiliki hak untuk melakukan approval dari berbagai divisi. Type ini dapat terdiri dari beberapa pengguna. Master berlaku sebagai level approval terakhir. HR akan otomatis berlaku sebagai Master, dan Master dapat terdiri dari beberapa pengguna.',
      },
      {
        title: 'Approval',
        description: 'Adalah pengguna yang hanya memiliki hak untuk mendapatkan notifikasi dan melakukan approval dari satu divisi saja. Type ini dapat terdiri dari beberapa pengguna. Anda dapat mengatur urutan approve pada menu ‘Approval Order Setting’',
      },
    ];

    return (
      <Dialog
        open={modalApprovalInformation}
        onClose={() => this.setState({modalApprovalInformation: false})}
        maxWidth='sm'
      >
        <ModalTitle
          title={'Approval Type Information'}
          onClose={() => this.setState({modalApprovalInformation: false})}
          style={{color: '#656464'}}
        />
        <form onSubmit={this.handleSubmitImport} autoComplete="off">
          <DialogContent className={classes.dialogContentGuide}>
            {approvalList.map((value, index) => {
              return (
                <div key={index} style={{marginBottom: 24}}>
                  <div style={{display: 'flex'}}>
                    <Typography className={classes.titleGuide} >{index + 1}.&nbsp;</Typography>
                    <Typography className={classes.titleGuide} >{value.title}</Typography>
                  </div>
                  <div style={{display: 'flex', marginTop: 8}}>
                    <Typography className={classes.subtitleGuide} style={{opacity: 0}}>{index + 1}.&nbsp;</Typography>
                    <Typography className={classes.subtitleGuide}>{value.description}</Typography>
                  </div>
                </div>
              );
            })}
          </DialogContent>
        </form>
      </Dialog>
    );
  }

  handleGetShiftInfo = (shiftId) => {
    const {shifts} = this.state;
    const shift = shifts.filter((val) => val.id === shiftId);
    return shift;
  }

  handleGetJobInfo = (jobType) => {
    const job = this.props.jobData.filter((item) => item.name === jobType);
    return job.length ? job : null;
  };

  handleGetRoleInfo = (roleName) => {
    return this.props.roleData.filter((item) => item.name === roleName);
  };

  handlePersonalDate = (params) => {
    const {personalData, errorMessage} = this.state;
    const {name, value} = params;
    this.setState({
      personalData: {...personalData, [name]: value},
      errorMessage: null,
    });
  };

  handleContractDate = (params) => {
    const {contractData, errorMessage} = this.state;
    const {name, value} = params;

    if (name === 'start_date') {
      const start = new Date(value);
      const end = new Date(this.state.contractData.end_date);
      if (start > end) {
        this.setState({
          contractData: {...contractData, [name]: value},
          errorMessage: {...errorMessage, end_date: 'end date is not valid'},
        });
      } else {
        this.setState({
          contractData: {...contractData, [name]: value},
          errorMessage: null,
        });
      }
    } else {
      this.setState({
        contractData: {...contractData, [name]: value},
        errorMessage: null,
      });
    }
  };

  handleSelectSearchPersonal = (event, data, name, type) => {
    const {personalData, errorMessage} = this.state;
    this.setState({
      personalData: {...personalData, [name]: data},
      errorMessage: null,
    });
  };

  handleInputPersonal = (event) => {
    const {name, value} = event.target;
    const {errorMessage} = this.state;

    if (name === 'user_img') {
      const regExp = /\.(jpg|jpeg)$/i;
      const image = event.target.files[0];
      const imageSizeIsAllowed = checkFileUploadSize(image);
      if (imageSizeIsAllowed) {
        if (!value.match(regExp)) {
          this.setState({
            user_img: null,
            errorImageSize: null,
            errorMessage: {
              [name]: 'Must be .jpg/.jpeg',
            },
          });
        } else {
          this.setState({
            isCropping: true,
            errorImageSize: null,
            errorMessage: null,
            personalData: {
              ...this.state.personalData,
              imageURL: URL.createObjectURL(image),
              user_img: image,
            },
          });
        }
      } else {
        let message;
        if (imageSizeIsAllowed === false) {
          message =
            'Maximum file size is ' +
            process.env.REACT_APP_MAX_UPLOAD_SIZE_MB +
            ' MB';
        }
        if (imageSizeIsAllowed === null) message = 'Please select image';
        this.setState({
          errorImageSize: message,
          imageURL: null,
          user_img: null,
        });
      }
    } else if (name === 'npwp_id') {
      const regexp = new RegExp(/^(?=.*?[0-9])[0-9-.]+$/g);
      const validateInt = value.match(regexp);
      if (value.length > 0) {
        if (!validateInt) {
          this.setState({
            [name]: value,
            errorMessage: {
              ...errorMessage,
              [name]: 'NPWP must number',
            },
          });
        } else if ((value.length < 20) || (value.length > 20)) {
          this.setState({
            [name]: value,
            errorMessage: {
              ...errorMessage,
              npwp_id: 'NPWP must 20 number long',
            },
          });
        } else {
          this.setState({
            personalData: {...this.state.personalData, [name]: value},
            errorMessage: null,
          });
        }
      }
    } else if (name === 'bank_account_name') {
      const regexp = /\d/g;
      const validateFullName = value.match(regexp);
      if ((validateFullName) && (value.length > 0)) {
        this.setState({
          errorMessage: {
            ...errorMessage,
            [name]: 'bank account name must not be used number',
          },
        });
      } else {
        this.setState({
          personalData: {...this.state.personalData, [name]: value},
          errorMessage: null,
        });
      }
    } else if (name === 'emergency_contact_number') {
      if (value.length === 0) {
        this.setState({
          personalData: {...this.state.personalData, [name]: value},
          errorMessage: null,
        });
      } else if ((value.length < 9) || (value.length > 14)) {
        this.setState({
          errorMessage: {
            emergency_contact_number: {
              validate: 'emergency contact number must be at least 9-14 character',
            },
          },
        });
      } else {
        this.setState({
          personalData: {...this.state.personalData, [name]: value},
          errorMessage: null,
        });
      }
    } else if (name === 'bank_account_number') {
      const regexp = new RegExp(/^(?=.*?[0-9])[0-9-.]+$/g);
      const validateInt = value.match(regexp);
      if (value.length > 0) {
        if (!validateInt) {
          this.setState({
            [name]: value,
            errorMessage: {
              ...errorMessage,
              [name]: 'bank account number must not be used character',
            },
          });
        } else {
          this.setState({
            personalData: {...this.state.personalData, [name]: value},
            errorMessage: null,
          });
        }
      }
    } else {
      this.setState({
        personalData: {
          ...this.state.personalData,
          [name]: value,
        },
        errorMessage: null,
        // errorMessage: {...errorMessage, [name]: null},
      });
    }
  }
  handleInputEmployment = (event) => {
    const {name, value} = event.target;
    const {errorMessage} = this.state;

    if (name === 'health_insurance_id') {
      const regexp = new RegExp(/^(?=.*?[0-9])[0-9-.]+$/g);
      const validateInt = value.match(regexp);
      if (value.length > 0) {
        if (!validateInt) {
          this.setState({
            errorMessage: {
              ...errorMessage,
              [name]: 'health insurance id must not be used character',
            },
          });
        } else {
          this.setState({
            employmentData: {...this.state.employmentData, [name]: value},
            errorMessage: null,
          });
        }
      }
    } else if (name === 'bpjs_ketenagakerjaan_id') {
      const regexp = new RegExp(/^(?=.*?[0-9])[0-9-.]+$/g);
      const validateInt = value.match(regexp);
      if (value.length > 0) {
        if (!validateInt) {
          this.setState({
            errorMessage: {
              ...errorMessage,
              [name]: 'bpjs ketenagakerjaan id must not be used character',
            },
          });
        } else {
          this.setState({
            employmentData: {...this.state.employmentData, [name]: value},
            errorMessage: null,
          });
        }
      }
    } else {
      this.setState({
        employmentData: {
          ...this.state.employmentData,
          [name]: value,
        },
        errorMessage: null,
      });
    }
  }

  handleInputRole = (event) => {
    const {name, value} = event.target;
    const {errorMessage} = this.state;
    this.setState({
      employmentData: {
        ...this.state.employmentData,
        [name]: value,
      },
      errorMessage: {...errorMessage, [name]: null},
    });
  }

  handleInputContract = (event) => {
    const {name, value} = event.target;
    const {
      start_date,
      end_date,
    } = this.state.contractData;
    const {errorMessage} = this.state;

    const start = new Date(start_date);
    const end = new Date(end_date);
    let res = Math.round((end - start) / (24 * 60 * 60 * 1000));
    if (res < 0) res = 0;
    if (name === 'reminder' && value > res) {
      this.setState({
        errorMessage: {
          ...errorMessage,
          reminder: `reminder can't be more than between start date and end date`,
        },
      });
    } else {
      this.setState({
        contractData: {
          ...this.state.contractData,
          [name]: value ? value : null,
        },
        errorMessage: null,
      });
    }
  }

  handleInputRemote = (event) => {
    const {name, value} = event.target;
    const {errorMessage} = this.state;

    this.setState({
      remoteData: {
        ...this.state.remoteData,
        [name]: value,
      },
      errorMessage: {...errorMessage, [name]: null},
    });
  }

  handleTogglePassword = () => {
    this.setState({showPassword: !this.state.showPassword});
  };

  handleInputAccount = (event) => {
    const {name, value} = event.target;
    const {errorMessage} = this.state;

    this.setState({
      accountData: {
        ...this.state.accountData,
        [name]: value,
      },
      errorMessage: {...errorMessage, [name]: null},
    });
  }

  handleInputLeave = (event) => {
    const {name, value} = event.target;
    const {errorMessage} = this.state;

    this.setState({
      leaveData: {
        ...this.state.leaveData,
        [name]: value,
      },
      errorMessage: {...errorMessage, [name]: null},
    });
  }

  handleTogglePassword = () => {
    this.setState({showPassword: !this.state.showPassword});
  };

  selectHandler = (event, value) => {
    if (value) {
      if (!value.nik) {
        return this.setState({
          errorMessage: {
            manager: 'cant use this option',
          },
        });
      } else {
        value.manager_id = value.nik;
        value.manager_name = value.name;
        this.setState({
          errorMessage: null,
        });
      }
    }
    this.setState({
      employmentData: {
        ...this.state.employmentData,
        manager: value ? value : null,
      },
    });
  }

  searchCombo = (event = null, value) => {
    this.handleDebounceSearch(event.target.value);
  }

  fetchManager = () => {
    this.setState({
      searchManager: false,
    });
    const {page, limit, all, searchFilterManager} = this.state;
    const queryParams = {
      query: searchFilterManager,
      // all: true
    };
    this.props.getAllManager(queryParams);
  }

  resetManager = () => {
    this.setState({searchFilterManager: null});
  }

  handleDebounceSearch = _.debounce((query) => {
    this.setState({searchFilterManager: query},
      () => {
        this.fetchManager();
      });
  }, 500);

  toggleCropperModal = (status) => {
    this.setState({isCropping: status});
  };

  handleCrop = (result) => {
    this.setState({
      personalData: {
        ...this.state.personalData,
        cropped_user_img: result.blob,
        imageURL: result.imageURL,
      },
    });
  };

  handleSubmitPersonal = (e) => {
    e.preventDefault();
    const url_string = window.location.href;
    const url = new URL(url_string);
    const id = url.searchParams.get('id');

    const {
      name,
      nik,
      dob,
      gender,
      marital_status,
      number_of_children,
      religion,
      phone,
      address,
      bank_name,
      bank_account_number,
      bank_account_name,
      npwp_id,
      emergency_contact_name,
      emergency_contact_relation,
      emergency_contact_number,
      cropped_user_img,
    } = this.state.personalData;

    if ((!emergency_contact_name || !emergency_contact_relation || !emergency_contact_number) && (emergency_contact_name || emergency_contact_relation || emergency_contact_number)) {
      this.setState({
        errorMessage: {
          emergency_contact_name: 'Emergency contact name is required',
          emergency_contact_relation: 'Emergency contact relation is required',
          emergency_contact_number: {
            required: 'Emergency contact number is required',
          },
        },
      });
    } else {
      const dataForm = new FormData();
      if (name) {
        dataForm.append('name', name);
      }
      if (nik) dataForm.append('nik', nik);
      if (dob) dataForm.append('dob', convDate(dob, 'YYYY-MM-DD'));
      if (gender) dataForm.append('gender', gender);
      if (marital_status) dataForm.append('marital_status', marital_status);

      if (marital_status === 'married' || marital_status === 'divorced') {
        if (number_of_children) dataForm.append('number_of_children', number_of_children);
      } else {
        dataForm.append('number_of_children', 0);
      }

      if (cropped_user_img) {
        dataForm.append('user_img', blobToFile(cropped_user_img));
      }

      if (religion) dataForm.append('religion', religion);
      if (phone) dataForm.append('phone', phone);
      if (address) dataForm.append('address', address);
      if (bank_name) dataForm.append('bank_name', bank_name);

      if (bank_account_number) dataForm.append('bank_account_number', bank_account_number);
      if (bank_account_name === null || bank_account_name === 'null' || bank_account_name === '-' || !bank_account_name) {
        dataForm.append('bank_account_name', '-');
      } else {
        dataForm.append('bank_account_name', bank_account_name);
      }
      if (npwp_id) dataForm.append('npwp_id', npwp_id);
      if (emergency_contact_name) dataForm.append('emergency_contact_name', emergency_contact_name);
      if (emergency_contact_relation) dataForm.append('emergency_contact_relation', emergency_contact_relation);
      if (emergency_contact_number) dataForm.append('emergency_contact_number', emergency_contact_number);

      this.setState({
        errorMessage: null,
      });

      return showPopup(
        'confirm',
        'Are you sure you want to update this data ?',
        'Yes',
        () => this.props.updatePersonalData(id, dataForm),
      );
    }
  }

  handleSubmitEmployment = () => {
    const url_string = window.location.href;
    const url = new URL(url_string);
    const id = url.searchParams.get('id');

    const {
      name,
      division_name,
      division_id,
      job_title,
      job_title_id,
      manager_name,
      manager,
      approval_type,
      email,
      password,
      bpjs_ketenagakerjaan_id,
      health_insurance_id,
      health_insurance_name,
      employment_status,
      register_date,
      employment_type,
      start_date,
      end_date,
      main_salary,
      allowance,
      overtime_fee,
      attachment,
      role_id,
    } = this.state.employmentData;

    const {isSuperAdmin} = this.props;

    const params = {
      ...(division_id && {division_id: division_id.id}),
      ...(job_title_id && {job_title_id: job_title_id}),
      ...(manager && {manager: manager.manager_id}),
      ...(approval_type && {approval_type: approval_type}),
      ...(email && {email: email}),
      ...(password && {password: password}),
      ...(password && {password: password}),
      ...(bpjs_ketenagakerjaan_id && {bpjs_ketenagakerjaan_id: bpjs_ketenagakerjaan_id}),
      ...(health_insurance_id && {health_insurance_id: health_insurance_id}),
      ...(health_insurance_name && {health_insurance_name: health_insurance_name}),
      ...(role_id && {role_id: role_id}),
    };

    if (manager === null) {
      this.setState(
        {
          errorMessage: {
            manager: 'manager is required',
          },
        });
    } else {
      this.setState(
        {
          errorMessage: null,
          searchManager: true,
        });
      return showPopup(
        'confirm',
        'Are you sure you want to update this data ?',
        'Yes',
        () => this.props.updateEmploymentData(id, params),
      );
    }
  }

  handleSubmitContract = (e) => {
    e.preventDefault();
    const url_string = window.location.href;
    const url = new URL(url_string);
    const id = url.searchParams.get('id');
    const {editingContract} = this.state;
    const {
      user_id,
      employment_type,
      main_salary,
      start_date,
      end_date,
      allowance,
      overtime_fee,
      reminder,
      attachment,
    } = this.state.contractData;

    let permanentContract = false;
    let params = null;

    if (employment_type === 'permanent') {
      permanentContract = true;
    }

    if (permanentContract) {
      params = {
        ...(id && {user_id: id}),
        ...(employment_type && {employment_type}),
        ...(main_salary && {main_salary: main_salary.toString()}),
        ...(start_date && {start_date: convDate(start_date, 'YYYY-MM-DD')}),
        ...(allowance && {allowance: '0'}),
        ...(overtime_fee && {overtime_fee: '0'}),
        ...(attachment && {attachment}),
        end_date: null,
        reminder: 0,
      };
    } else {
      if (reminder !== 0) {
        params = {
          ...(id && {user_id: id}),
          ...(employment_type && {employment_type}),
          ...(main_salary && {main_salary: main_salary.toString()}),
          ...(start_date && {start_date: convDate(start_date, 'YYYY-MM-DD')}),
          ...(end_date && {end_date: convDate(end_date, 'YYYY-MM-DD')}),
          ...(allowance && {allowance: '0'}),
          ...(overtime_fee && {overtime_fee: '0'}),
          ...(reminder && {reminder}),
          ...(attachment && {attachment}),
        };
      } else {
        params = {
          ...(id && {user_id: id}),
          ...(employment_type && {employment_type}),
          ...(main_salary && {main_salary: main_salary.toString()}),
          ...(start_date && {start_date: convDate(start_date, 'YYYY-MM-DD')}),
          ...(end_date && {end_date: convDate(end_date, 'YYYY-MM-DD')}),
          ...(allowance && {allowance: '0'}),
          ...(overtime_fee && {overtime_fee: '0'}),
          ...(attachment && {attachment}),
          reminder: 0,
        };
      }
    }

    const start = new Date(start_date);
    const end = new Date(end_date);
    let res = Math.round((end - start) / (24 * 60 * 60 * 1000));
    if (res < 0) res = 0;

    const errors = {};
    if ((start > end) || (reminder > res) || !attachment || ((attachment !== null) && (attachment.search('http') === -1)) || !start_date || !end_date || !employment_type || !reminder) {
      if (start > end) errors.end_date = 'end date is not valid';
      if ((attachment !== null) && (attachment.search('http') === -1)) errors.attachment = 'link is not valid';
      if (reminder > res) errors.reminder = `reminder can't be more than between start date and end date`;
      if (!attachment) errors.attachment = 'External document is required';
      if (!start_date) errors.start_date = 'Start date is required';
      if (!end_date) errors.end_date = 'End date is required';
      if (!employment_type) errors.employment_type = 'Employment type is required';
      if (!reminder) errors.reminder = 'Reminder type is required';
      this.setState({errorMessage: errors});
    } else {
      this.setState({
        errorMessage: null,
      });
      return showPopup(
        'confirm',
        'Are you sure you want to save this data ?',
        'Yes',
        () => (!editingContract) ? this.props.addContractUser(params) : this.props.updateContractUser(user_id, params),
      );
    }
  }

  handleSubmitRemote = (e) => {
    e.preventDefault();
    const {remoteWorking} = this.state;
    const {additional_limit_remote, limit_remote} = this.state.remoteData;
    const url_string = window.location.href;
    const url = new URL(url_string);
    const id = url.searchParams.get('id');

    if (remoteWorking) {
      const month = Number(limit_remote) + Number(additional_limit_remote);

      if (month > 30) {
        toast('error', 'Remote Working Quota and Additional Remote Limit cant more than 30 day');
      } else {
        if (additional_limit_remote.length === 0) {
          const params = {
            ...(limit_remote && {limit_remote: limit_remote * 1}),
            additional_limit_remote: 0,
          };

          return showPopup(
            'confirm',
            'Are you sure you want to update this data ?',
            'Yes',
            () => this.props.updateRemoteData(id, params),
          );
        } else {
          const params = {
            ...(limit_remote && {limit_remote: limit_remote * 1}),
            ...(additional_limit_remote && {additional_limit_remote: additional_limit_remote * 1}),
          };

          return showPopup(
            'confirm',
            'Are you sure you want to update this data ?',
            'Yes',
            () => this.props.updateRemoteData(id, params),
          );
        }
      }
    } else {
      const params = {
        additional_limit_remote: 0,
      };
      return showPopup(
        'confirm',
        'Are you sure you want to update this data ?',
        'Yes',
        () => this.props.updateRemoteData(id, params),
      );
    }
  }

  handleSubmitAccount = (e) => {
    e.preventDefault();
    const {email, password} = this.state.accountData;
    const url_string = window.location.href;
    const url = new URL(url_string);
    const id = url.searchParams.get('id');

    const params = {
      ...(email && {email: email}),
      ...(password && {password: password}),
    };
    return showPopup(
      'confirm',
      'Are you sure you want to update this data ?',
      'Yes',
      () => this.props.updateAccountData(id, params),
    );
  }

  handleSubmitLeave = () => {
    const url_string = window.location.href;
    const url = new URL(url_string);
    const id = url.searchParams.get('id');
    const {deductive, additional} = this.state.leaveData;

    const params = {
      deductive: deductive ? deductive * 1 : 0,
      // additional: additional ? additional * 1 : 0,
    };
    this.props.updateDeductedLeave(id, params);
  }


  renderNavigationPaper = () => {
    const {classes, attendanceTypes, exportLoading, isSuperAdmin, orgStatusData, userData} = this.props;
    const {query} = this.state;

    return (
      <Paper variant='outlined' className={classes.paperNavigation}>
        <div style={{display: 'flex'}}>
          <Link to='/user-management' className={classes.btnBack}>
            <ArrowBack />
          </Link>
          <Typography className={classes.tableTitle}>Employee Detail</Typography>
        </div>
      </Paper>
    );
  }

  renderNoActiveContract = () => {
    const {classes, attendanceTypes, exportLoading, isSuperAdmin, orgStatusData, userData, contractData, isLoading, isLoadingContract} = this.props;
    const {resetDatatable, resetPage, advantech, profileStatus, contractStatus, contractEndDate, query, profileContract} = this.state;
    const remaining = dateDiff(moment().format('YYYY-MM-DD'), convDate(contractEndDate));

    let hasActiveContract = null;
    for (let i = 0; i < contractData?.length; i++) {
      if (contractData[i].employment_status === true) {
        return hasActiveContract = true;
      } else {
        hasActiveContract = false;
      }
    }

    return (
      <>
        {
          ((isLoading === false || isLoadingContract === false) && contractData.length < 1) || hasActiveContract === false ?
            (
              <Paper variant='outlined' className={classes.paperNavigation}>
                <div style={{display: 'flex'}}>
                  <Info className={classes.btnInfo} />
                  <Typography className={classes.tableTitle}>This employee’s contract has not been set yet. Please set a new contract on  ‘Add contract’.</Typography>
                </div>
              </Paper>
            ) :
            (isLoading === false || isLoadingContract === false) ?
              (profileContract !== 'permanent') ?
                (profileStatus === 'active' || profileStatus === 'expired soon') ?
                  (contractStatus === '' || contractStatus === null || contractStatus === 'active') ?
                    (remaining < 0) ? (
                      <Paper variant='outlined' className={classes.paperNavigation}>
                        <div style={{display: 'flex'}}>
                          <Info className={classes.btnInfo} />
                          <Typography className={classes.tableTitle}>This employee’s contract is expired. Please update new contract on Contract History</Typography>
                        </div>
                      </Paper>
                    ) : <></> : <></> : <></> : <></> : <></>
        }
      </>
    );
  }

  renderPersonalMenu = () => {
    const {classes, bankData} = this.props;
    const {
      name,
      nik,
      dob,
      gender,
      marital_status,
      number_of_children,
      religion,
      phone,
      address,
      bank_name,
      bank_account_number,
      bank_account_name,
      npwp_id,
      emergency_contact_name,
      emergency_contact_relation,
      emergency_contact_number,
      imageURL,
      user_img,
    } = this.state.personalData;

    const {
      personalUpdate,
      errorMessage,
      errorImageSize,
      showCropResult,
      isCropping,
      selectedMenu,
      bankList,
      saveDisabled,
      isMediaScreenMedium,
    } = this.state;

    const bankOption = () => {
      const option = [];
      bankList.map((item) => {
        const isDuplicate = option.find((obj) => obj.name === item.name);
        if (!isDuplicate) {
          option.push(item.name);
        }
      });
      return option;
    };

    const genderOption = () => {
      const options = [
        {id: 'male', name: 'Male'},
        {id: 'female', name: 'Female'},
      ];
      return options;
    };

    const maritalStatusOption = () => {
      const options = [
        {id: 'single', name: 'Single'},
        {id: 'married', name: 'Married'},
        {id: 'divorced', name: 'Divorced'},
      ];
      return options;
    };

    const religionOption = () => {
      const options = [
        {id: 'muslim', name: 'Muslim'},
        {id: 'protestant', name: 'Protestant'},
        {id: 'catholic', name: 'Catholic'},
        {id: 'hindu', name: 'Hindu'},
        {id: 'buddhist', name: 'Buddhist'},
        {id: 'konghucu', name: 'Kong Hu Cu'},
      ];
      return options;
    };

    const relationOption = () => {
      const options = [
        {id: 'mother', name: 'Mother'},
        {id: 'father', name: 'Father'},
        {id: 'relatives', name: 'Relatives'},
        {id: 'friends', name: 'Friends'},
        {id: 'others', name: 'Others'},
      ];
      return options;
    };
    // Render Personal Data Info

    if (bank_account_name === 'null' || bank_account_name === '-') {
      this.setState({
        personalData: {
          ...this.state.personalData,
          bank_account_name: null,
        },
      });
    }

    return (
      <MuiThemeProvider theme={this.formTheme()}>
        <div>
          {selectedMenu === 'personalUpdate' ? (
            <form onSubmit={this.handleSubmitPersonal}>
              <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', flexWrap: isMediaScreenMedium ? 'wrap' : 'nowrap'}}>
                <Typography className={classes.personalTitle} style={{marginBottom: isMediaScreenMedium ? 8 : 0}}>Update Personal Data</Typography>
                <div style={{display: 'flex', flexWrap: isMediaScreenMedium ? 'wrap-reverse' : 'nowrap'}}>
                  <MButton
                    className={classes.cancelBtn}
                    label={'CANCEL'}
                    style={{marginRight: 8, marginBottom: isMediaScreenMedium ? 4 : 0}}
                    onClick={
                      () => {
                        const url_string = window.location.href;
                        const url = new URL(url_string);
                        const id = url.searchParams.get('id');
                        this.props.getEmployeeDetail(id);
                        setTimeout(() => {
                          this.setState({
                            // Set Sub Menu
                            selectedIndex: 0,
                            selectedMenu: 'personal',
                            errorMessage: null,
                          });
                        }, 500);
                      }
                    }
                  />
                  <MButton
                    className={classes.defaultBtn}
                    style={{marginBottom: isMediaScreenMedium ? 4 : 0}}
                    label={'SAVE UPDATE'}
                    type="submit"
                    loading={this.props.isLoading}
                    icon={<Send />}
                    disabled={errorMessage ? true : false}
                  // disabled={
                  //   (emergency_contact_name === null || emergency_contact_name === '') &&
                  //   (emergency_contact_number === null || emergency_contact_number === '') &&
                  //   (emergency_contact_relation === null || emergency_contact_relation === '') ? false :
                  //   (emergency_contact_name !== null || emergency_contact_name !== '') &&
                  //   (emergency_contact_number !== null || emergency_contact_number !== '') &&
                  //   (emergency_contact_relation !== null || emergency_contact_relation !== '') ? false : true
                  // }
                  />
                </div>
              </div>
              <Typography className={classes.personalDataInformation}>Personal Data Information</Typography>
              <Grid container spacing={2} style={{marginBottom: 4}}>
                <Grid item xs={isMediaScreenMedium ? true : 10}>
                  <MInput
                    // disabled={editing ? true : false}
                    classNameFC={classes.formControl}
                    autoFocus
                    name="name"
                    label="Full Name *"
                    placeholder="Enter full name"
                    defaultValue={name}
                    onBlur={this.handleInputPersonal}
                    error={
                      errorMessage && errorMessage.name ?
                        errorMessage.name :
                        undefined
                    }
                    fullWidth
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2} style={{marginBottom: 4}}>
                <Grid item xs={isMediaScreenMedium ? true : 5}>
                  <MInput
                    classNameFC={classes.formControl}
                    name="nik"
                    label="NIK / Employee ID *"
                    placeholder="Enter nik"
                    defaultValue={nik}
                    onBlur={this.handleInputPersonal}
                    error={
                      errorMessage && errorMessage.nik ?
                        errorMessage.nik :
                        undefined
                    }
                    fullWidth
                  />
                </Grid>
                <Grid item xs={isMediaScreenMedium ? true : 5}>
                  <MDatepicker
                    className={classes.formControl}
                    name="dob"
                    label="Date Of Birth *"
                    value={dob}
                    onDateChange={this.handlePersonalDate}
                    error={
                      errorMessage && errorMessage.dob ? errorMessage.dob : undefined
                    }
                    format='dd MMMM yyyy'
                    placeholder="dd-mmmm-yyyy"
                    maxDate={new Date()}
                    inputVariant="outlined"
                    disableFuture
                    openTo="year"
                    views={['year', 'month', 'date']}
                    fullWidth
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2} style={{marginBottom: 4}}>
                <Grid item xs={isMediaScreenMedium ? true : 5}>
                  <MSelect
                    classNameFC={classes.formControl}
                    name="gender"
                    value={gender}
                    label="Gender"
                    onChange={this.handleInputPersonal}
                    error={
                      errorMessage && errorMessage.gender ?
                        errorMessage.gender :
                        undefined
                    }
                    keyPair={['id', 'name']}
                    options={genderOption()}
                  />
                </Grid>
                <Grid item xs={isMediaScreenMedium ? true : 5}>
                  <MSelect
                    classNameFC={classes.formControl}
                    name="marital_status"
                    value={marital_status}
                    label="Marital Status"
                    onChange={this.handleInputPersonal}
                    error={
                      errorMessage && errorMessage.marital_status ?
                        errorMessage.marital_status :
                        undefined
                    }
                    keyPair={['id', 'name']}
                    options={maritalStatusOption()}
                  />
                </Grid>
              </Grid>
              {marital_status === 'married' || marital_status === 'divorced' ? (
                <Grid container spacing={2} style={{marginBottom: 4}}>
                  <Grid item xs={isMediaScreenMedium ? true : 5}>
                    <MInput
                      classNameFC={classes.formControl}
                      name="number_of_children"
                      label="Number of Children"
                      placeholder="Enter number of children"
                      defaultValue={number_of_children}
                      onBlur={this.handleInputPersonal}
                      error={
                        errorMessage && errorMessage.number_of_children ?
                          errorMessage.number_of_children :
                          undefined
                      }
                      type='number'
                      onKeyDown={(event) => {
                        if (event.key === '.' || event.key === '-' || event.key === 'e' || event.key === ',') {
                          event.preventDefault();
                        }
                      }}
                      fullWidth
                    />
                  </Grid>
                </Grid>
              ) : (<></>)}
              <Grid container spacing={2} style={{marginBottom: 4}}>
                <Grid item xs={isMediaScreenMedium ? true : 5}>
                  <MSelect
                    classNameFC={classes.formControl}
                    name="religion"
                    value={religion}
                    label="Religion"
                    onChange={this.handleInputPersonal}
                    error={
                      errorMessage && errorMessage.religion ?
                        errorMessage.religion :
                        undefined
                    }
                    keyPair={['id', 'name']}
                    options={religionOption()}
                  />
                </Grid>
                <Grid item xs={5}>
                  <MInputMask
                    classNameFC={classes.formControl}
                    name="phone"
                    label="Phone Number *"
                    placeholder="Fill in user phone number"
                    type="number"
                    defaultValue={phone}
                    onBlur={this.handleInputPersonal}
                    error={
                      errorMessage && errorMessage.phone ?
                        errorMessage.phone.split(':')[0] :
                        undefined
                    }
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2} style={{marginBottom: 4}}>
                <Grid item xs={isMediaScreenMedium ? true : 10}>
                  <MInput
                    classNameFC={classes.formControl}
                    name="address"
                    label="Address"
                    placeholder="Enter Adress"
                    type="text"
                    defaultValue={address}
                    onBlur={this.handleInputPersonal}
                    error={
                      errorMessage && errorMessage.address ?
                        errorMessage.address.split(':')[0] :
                        undefined
                    }
                    rows={3}
                    multiline
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2} style={{marginBottom: 4}}>
                <Grid item xs={isMediaScreenMedium ? true : 10}>
                  <MInput
                    classNameFC={classes.formControl}
                    name="user_img"
                    label="Photo"
                    placeholder="Photo"
                    type="file"
                    onChange={this.handleInputPersonal}
                    inputProps={{accept: 'image/*'}}
                    error={
                      errorImageSize ?
                        errorImageSize :
                        errorMessage && errorMessage.user_img ?
                          errorMessage.user_img :
                          undefined
                    }
                  />
                  <span><i>Must be .jpg/.jpeg with recommended resolution 200 x 200 px</i></span>
                  {!isCropping && imageURL && (
                    <div style={{marginTop: 18}}>
                      <CardMedia
                        className={classes.photo}
                        title="Photo"
                        image={imageURL}
                      />
                      {user_img && (
                        <MButton
                          className={`${classes.defaultBtn} ${classes.btnCrop}`}
                          label="Retry"
                          icon={<Crop />}
                          onClick={() => this.toggleCropperModal(true)}
                        />
                      )}
                    </div>
                  )}
                  <ImageCropper
                    showModal={isCropping}
                    src={user_img}
                    showResult={showCropResult}
                    onClose={() => this.toggleCropperModal(false)}
                    onComplete={this.handleCrop}
                  />
                </Grid>
              </Grid>

              <Divider style={{marginTop: 20, marginBottom: 18}} />

              <Typography className={classes.personalDataInformation}>Finance and Tax Information</Typography>
              <Grid container spacing={2} style={{marginBottom: 4}}>
                <Grid item xs={isMediaScreenMedium ? true : 10}>
                  <MInput
                    classNameFC={classes.formControl}
                    name="npwp_id"
                    label="NPWP"
                    placeholder="Enter NPWP"
                    defaultValue={npwp_id}
                    onBlur={this.handleInputPersonal}
                    error={
                      errorMessage && errorMessage.npwp_id ?
                        errorMessage.npwp_id :
                        null
                    }
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2} style={{marginBottom: 4}}>
                <Grid item xs={isMediaScreenMedium ? true : 5}>
                  <MSelectSearch
                    disableClearable
                    name="bank_name"
                    options={bankOption()}
                    classNameFC={classes.formControl}
                    label="Bank Name"
                    value={bank_name}
                    onChange={(e, value) => {
                      this.handleSelectSearchPersonal(e, value, 'bank_name', 'form');
                    }}
                    variant={'outlined'}
                    error={
                      errorMessage && errorMessage.bank_name ?
                        errorMessage.bank_name :
                        undefined
                    }
                  />
                </Grid>
                <Grid item xs={isMediaScreenMedium ? true : 5}>
                  <MInput
                    classNameFC={classes.formControl}
                    name="bank_account_name"
                    label="Bank Account Name"
                    placeholder="Enter bank account name"
                    defaultValue={bank_account_name}
                    onBlur={this.handleInputPersonal}
                    error={

                      errorMessage && errorMessage.bank_account_name ?
                        errorMessage.bank_account_name :
                        null
                    }
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2} style={{marginBottom: 4}}>
                <Grid item xs={isMediaScreenMedium ? true : 5}>
                  <MInput
                    classNameFC={classes.formControl}
                    name="bank_account_number"
                    label="Bank Account Number"
                    placeholder="Enter bank account number"
                    defaultValue={bank_account_number}
                    onBlur={this.handleInputPersonal}
                    error={
                      errorMessage && errorMessage.bank_account_number ?
                        errorMessage.bank_account_number :
                        null
                    }
                  />
                </Grid>
              </Grid>

              <Divider style={{marginTop: 12, marginBottom: 24}} />

              <Typography className={classes.personalDataInformation}>Emergency Contact Information</Typography>

              <Grid container spacing={2} style={{marginBottom: 4}}>
                <Grid item xs={isMediaScreenMedium ? true : 10}>
                  <MInput
                    classNameFC={classes.formControl}
                    name="emergency_contact_name"
                    label="Emergency Contact Name"
                    placeholder="Enter emergency contact name"
                    defaultValue={emergency_contact_name}
                    onBlur={this.handleInputPersonal}
                    error={
                      !emergency_contact_name && errorMessage && errorMessage.emergency_contact_name ?
                        errorMessage.emergency_contact_name :
                        undefined
                    }
                  />
                </Grid>
              </Grid>

              <Grid container spacing={2} style={{marginBottom: 4}}>
                <Grid item xs={isMediaScreenMedium ? true : 5}>
                  <MSelect
                    classNameFC={classes.formControl}
                    name="emergency_contact_relation"
                    value={emergency_contact_relation}
                    label="Emergency Contact Relation"
                    onChange={this.handleInputPersonal}
                    error={
                      !emergency_contact_name && errorMessage && errorMessage.emergency_contact_relation ?
                        errorMessage.emergency_contact_relation :
                        undefined
                    }
                    keyPair={['id', 'name']}
                    options={relationOption()}
                  />
                </Grid>
                <Grid item xs={isMediaScreenMedium ? true : 5}>
                  <MInputMask
                    classNameFC={classes.formControl}
                    name="emergency_contact_number"
                    label="Emergency Contact Number"
                    placeholder="Enter emergency contact number"
                    type="number"
                    onKeyDown={(event) => {
                      if (event.key === '.' || event.key === '-' || event.key === 'e' || event.key === ',') {
                        event.preventDefault();
                      }
                    }}
                    defaultValue={emergency_contact_number}
                    onBlur={this.handleInputPersonal}
                    error={
                      errorMessage && errorMessage.emergency_contact_number ?
                        (!emergency_contact_number ? errorMessage.emergency_contact_number.required : errorMessage.emergency_contact_number.validate) :
                        undefined
                    }
                  />
                </Grid>
              </Grid>

            </form>
          ) : selectedMenu === 'personal' ? (
            <>
              <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                <Typography className={classes.personalTitle}>Personal</Typography>
                <MButton
                  className={classes.defaultBtn}
                  label={'UPDATE'}
                  error={errorMessage && errorMessage.name ? errorMessage.name : undefined}
                  onClick={
                    () => this.setState({
                      // Set Sub Menu
                      selectedIndex: 0,
                      selectedMenu: 'personalUpdate',
                      errorMessage: null,
                    })
                  }
                />
              </div>
              <Typography className={classes.personalDataInformation}>Personal Data Information</Typography>
              <Grid container spacing={2} style={{marginBottom: 4}}>
                <Grid item xs={isMediaScreenMedium ? 6 : 3}>
                  <Typography className={classes.titleText}>Full Name</Typography>
                </Grid>
                <Grid item xs={isMediaScreenMedium ? 6 : 9}>
                  <Typography className={classes.valueText}>{name ? name : '-'}</Typography>
                </Grid>
              </Grid>

              <Grid container spacing={2} style={{marginBottom: 4}}>
                <Grid item xs={isMediaScreenMedium ? 6 : 3}>
                  <Typography className={classes.titleText}>NIK / Employee ID</Typography>
                </Grid>
                <Grid item xs={isMediaScreenMedium ? 6 : 9}>
                  <Typography className={classes.valueText}>{nik ? nik : '-'}</Typography>
                </Grid>
              </Grid>

              <Grid container spacing={2} style={{marginBottom: 4}}>
                <Grid item xs={isMediaScreenMedium ? 6 : 3}>
                  <Typography className={classes.titleText}>Date Of Birth</Typography>
                </Grid>
                <Grid item xs={isMediaScreenMedium ? 6 : 9}>
                  <Typography className={classes.valueText}>{dob ? dob : '-'}</Typography>
                </Grid>
              </Grid>

              <Grid container spacing={2} style={{marginBottom: 4}}>
                <Grid item xs={isMediaScreenMedium ? 6 : 3}>
                  <Typography className={classes.titleText}>Gender</Typography>
                </Grid>
                <Grid item xs={isMediaScreenMedium ? 6 : 9}>
                  <Typography className={classes.valueText}>{gender ? gender : '-'}</Typography>
                </Grid>
              </Grid>

              <Grid container spacing={2} style={{marginBottom: 4}}>
                <Grid item xs={isMediaScreenMedium ? 6 : 3}>
                  <Typography className={classes.titleText}>Marital Status</Typography>
                </Grid>
                <Grid item xs={isMediaScreenMedium ? 6 : 9}>
                  <Typography className={classes.valueText}>{marital_status ? marital_status : '-'}</Typography>
                </Grid>
              </Grid>

              {marital_status === 'married' || marital_status === 'divorced' ? (
                <Grid container spacing={2} style={{marginBottom: 4}}>
                  <Grid item xs={isMediaScreenMedium ? 6 : 3}>
                    <Typography className={classes.titleText}>Number Of Children</Typography>
                  </Grid>
                  <Grid item xs={isMediaScreenMedium ? 6 : 9}>
                    <Typography className={classes.valueText}>{number_of_children ? number_of_children : '-'}</Typography>
                  </Grid>
                </Grid>
              ) : <></>}

              <Grid container spacing={2} style={{marginBottom: 4}}>
                <Grid item xs={isMediaScreenMedium ? 6 : 3}>
                  <Typography className={classes.titleText}>Religion</Typography>
                </Grid>
                <Grid item xs={isMediaScreenMedium ? 6 : 9}>
                  <Typography className={classes.valueText}>{religion ? religion : '-'}</Typography>
                </Grid>
              </Grid>

              <Grid container spacing={2} style={{marginBottom: 4}}>
                <Grid item xs={isMediaScreenMedium ? 6 : 3}>
                  <Typography className={classes.titleText}>Phone Number</Typography>
                </Grid>
                <Grid item xs={isMediaScreenMedium ? 6 : 9}>
                  <Typography className={classes.valueText}>{phone ? phone : '-'}</Typography>
                </Grid>
              </Grid>

              <Grid container spacing={2} style={{marginBottom: 4}}>
                <Grid item xs={isMediaScreenMedium ? 6 : 3}>
                  <Typography className={classes.titleText}>Address</Typography>
                </Grid>
                <Grid item xs={isMediaScreenMedium ? 6 : 9}>
                  <Typography className={classes.valueText}>{address ? address : '-'}</Typography>
                </Grid>
              </Grid>

              <Divider style={{marginTop: 20, marginBottom: 24}} />

              <Typography className={classes.bankDataInformation}>Finance and Tax Information</Typography>
              <Grid container spacing={2} style={{marginBottom: 4}}>
                <Grid item xs={isMediaScreenMedium ? 6 : 3}>
                  <Typography className={classes.titleText}>NPWP</Typography>
                </Grid>
                <Grid item xs={isMediaScreenMedium ? 6 : 9}>
                  <Typography className={classes.valueText}>{npwp_id ? npwp_id : '-'}</Typography>
                </Grid>
              </Grid>
              <Grid container spacing={2} style={{marginBottom: 4}}>
                <Grid item xs={isMediaScreenMedium ? 6 : 3}>
                  <Typography className={classes.titleText}>Bank Name</Typography>
                </Grid>
                <Grid item xs={isMediaScreenMedium ? 6 : 9}>
                  <Typography className={classes.valueText}>{bank_name ? bank_name : '-'}</Typography>
                </Grid>
              </Grid>
              <Grid container spacing={2} style={{marginBottom: 4}}>
                <Grid item xs={isMediaScreenMedium ? 6 : 3}>
                  <Typography className={classes.titleText}>Bank Account Number</Typography>
                </Grid>
                <Grid item xs={isMediaScreenMedium ? 6 : 9}>
                  <Typography className={classes.valueText}>{bank_account_number ? bank_account_number : '-'}</Typography>
                </Grid>
              </Grid>
              <Grid container spacing={2} style={{marginBottom: 4}}>
                <Grid item xs={isMediaScreenMedium ? 6 : 3}>
                  <Typography className={classes.titleText}>Bank Account Name</Typography>
                </Grid>
                <Grid item xs={isMediaScreenMedium ? 6 : 9}>
                  <Typography className={classes.valueText}>{bank_account_name ? bank_account_name : '-'}</Typography>
                </Grid>
              </Grid>

              <Divider style={{marginTop: 20, marginBottom: 24}} />

              <Typography className={classes.bankDataInformation}>Emergency Contact Information</Typography>
              <Grid container spacing={2} style={{marginBottom: 4}}>
                <Grid item xs={isMediaScreenMedium ? 6 : 3}>
                  <Typography className={classes.titleText}>Emergency Contact Name</Typography>
                </Grid>
                <Grid item xs={isMediaScreenMedium ? 6 : 9}>
                  <Typography className={classes.valueText}>{emergency_contact_name ? emergency_contact_name : '-'}</Typography>
                </Grid>
              </Grid>
              <Grid container spacing={2} style={{marginBottom: 4}}>
                <Grid item xs={isMediaScreenMedium ? 6 : 3}>
                  <Typography className={classes.titleText}>Emergency Contact Relation</Typography>
                </Grid>
                <Grid item xs={isMediaScreenMedium ? 6 : 9}>
                  <Typography className={classes.valueText}>{emergency_contact_relation ? emergency_contact_relation : '-'}</Typography>
                </Grid>
              </Grid>
              <Grid container spacing={2} style={{marginBottom: 4}}>
                <Grid item xs={isMediaScreenMedium ? 6 : 3}>
                  <Typography className={classes.titleText}>Emergency Contact Number</Typography>
                </Grid>
                <Grid item xs={isMediaScreenMedium ? 6 : 9}>
                  <Typography className={classes.valueText}>{emergency_contact_number ? emergency_contact_number : '-'}</Typography>
                </Grid>
              </Grid>
            </>
          ) : null}
        </div>
      </MuiThemeProvider>
    );
  }

  renderUserInformation = () => {
    const {classes} = this.props;

    const {name, imageURL} = this.state.personalData;
    const {role_id} = this.state.employmentData;
    const {
      toggleGeneral,
      toggleHistory,
      toggleSetting,
      toggleAccount,
      selectedIndex,
      selectedMenu,
      profileName,
      profileContract,
      profileStartDate,
      profileEndDate,
      profileStatus,
      employmentData,
      isMediaScreenMedium,
    } = this.state;

    const url_string = window.location.href;
    const url = new URL(url_string);
    const id = url.searchParams.get('id');

    return (
      <Paper variant='outlined' className={classes.paper}>
        <MuiThemeProvider theme={this.drawerInformationTheme}>
          <Box sx={{display: 'flex'}}>
            <CssBaseline />
            <Drawer
              sx={{
                'width': 240,
                'flexShrink': 0,
                '& .MuiDrawer-paper': {
                  width: 240,
                  boxSizing: 'border-box',
                },
              }}
              variant="permanent"
              anchor="left"
            >
              <div className={classes.employeeData}>
                <Avatar
                  alt={`${name} picture`}
                  src={imageURL}
                  sx={{width: 148, height: 148}}
                  style={profileStatus === 'active' ?
                    {border: '4px solid #00A2DE'} :
                    profileStatus === 'expired soon' ?
                      {border: '4px solid #E5AF5A'} :
                      profileStatus === 'inactive' ?
                        {border: '4px solid #F00B38'} :
                        null
                  }
                  className={classes.employeeAvatar}
                />
                <div style={{display: 'flex', justifyContent: 'center'}}>
                  <Typography style={{textTransform: 'capitalize'}} className={classes.generalName}>{profileName ? profileName : '-'}</Typography>
                  {role_id === 2 ? (
                    <Typography style={{textTransform: 'capitalize'}} className={classes.hrBadge}>HR</Typography>
                  ) : <></>}
                </div>
                {
                  profileStatus === 'inactive' ?
                    (<Typography className={classes.contractEmployee}>Inactive Employee</Typography>) :
                    ((profileStatus === 'active' || profileStatus === 'expired soon') && employmentData.employment_status !== null) ?
                      (<Typography className={classes.contractEmployee}>{profileContract} Employee</Typography>) :
                      ((profileStatus === 'active' || profileStatus === 'expired soon') && employmentData.employment_status === null) ?
                        (<Typography className={classes.contractEmployee}>{profileContract}No Active Contract</Typography>) :
                        (null)
                }
                {
                  profileContract === 'permanent' && profileStatus === 'active' ?
                    (<Typography className={classes.contractEmployee}>{convDate(profileStartDate, 'DD/MM/YYYY')}</Typography>) :
                    (profileContract === 'internship' || profileContract === 'contract') && profileStatus === 'active' ?
                      (<Typography className={classes.contractEmployee}>{convDate(profileStartDate, 'DD/MM/YYYY')} - {convDate(profileEndDate, 'DD/MM/YYYY')}</Typography>) :
                      profileStatus === 'inactive' ?
                        (<Typography className={classes.contractEmployee}></Typography>) :
                        (null)
                }
                <div style={{marginTop: 16}}>
                  {profileStatus === 'active' || profileStatus === 'expired soon' ? (
                    <>
                      <MButton
                        className={classes.defaultBtnContract}
                        label={'Add Contract'}
                        icon={<Add />}
                        style={{marginRight: 8}}
                        onClick={() => this.toggleFormContract('add')}
                      />
                      <MButton
                        className={classes.defaultBtnResign}
                        label={'Resign'}
                        onClick={() => {
                          showPopup(
                            'confirm',
                            'Are you sure? This action wil deactivate the employee. You can reactivate later on Employee’s profile.',
                            'Yes',
                            () => {
                              this.props.updateEmployeeStatus(id, {status: 'inactive'});
                            },
                          );
                        }}
                      />
                    </>
                  ) : (
                    <MButton
                      className={classes.defaultBtnContract}
                      label={'Activate Contract'}
                      onClick={() => {
                        showPopup(
                          'confirm',
                          'Are you sure you want to activate this employee?',
                          'Yes',
                          () => {
                            this.props.updateEmployeeStatus(id, {status: 'active'});
                          },
                        );
                      }}
                    />
                  )}
                </div>
              </div>
              <Divider />
              <List>
                <ListItemButton onClick={() => this.setState({
                  toggleGeneral: !toggleGeneral,
                })}>
                  <ListItemText primary="General" />
                  {toggleGeneral ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
                <Collapse in={toggleGeneral} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    <ListItemButton
                      sx={{pl: 4}}
                      selected={selectedIndex === 0}
                      onClick={() => {
                        this.props.clearStateDivision();
                        this.fetchUserContract();
                        this.setState({
                          // Toggle Submenu
                          selectedIndex: 0,
                          selectedMenu: 'personal',
                          errorMessage: null,
                        });
                      }
                      }
                    >
                      <ListItemText primary="Personal" />
                    </ListItemButton>

                    <ListItemButton
                      sx={{pl: 4}}
                      selected={selectedIndex === 1}
                      onClick={() => this.setState({
                        // Toggle Submenu
                        selectedIndex: 1,
                        selectedMenu: 'employment',
                        errorMessage: null,
                      })}
                    >
                      <ListItemText primary="Employment" />
                    </ListItemButton>

                  </List>
                </Collapse>
              </List>


              <List>
                <ListItemButton onClick={() => this.setState({
                  toggleHistory: !toggleHistory,
                })}>
                  <ListItemText primary="History" />
                  {toggleHistory ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
                <Collapse in={toggleHistory} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    <ListItemButton
                      sx={{pl: 4}}
                      selected={selectedIndex === 4}
                      onClick={() => {
                        this.fetchUserContract();
                        this.setState({
                          // Toggle Submenu
                          selectedIndex: 4,
                          selectedMenu: 'contract',
                          errorMessage: null,
                        });
                      }
                      }
                    >
                      <ListItemText primary="Contract" />
                    </ListItemButton>
                  </List>
                </Collapse>
              </List>

              <List>
                <ListItemButton onClick={() => this.setState({
                  toggleSetting: !toggleSetting,
                })}>
                  <ListItemText primary="Setting" />
                  {toggleSetting ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
                <Collapse in={toggleSetting} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    <ListItemButton
                      sx={{pl: 4}}
                      selected={selectedIndex === 2}
                      onClick={() => {
                        const url_string = window.location.href;
                        const url = new URL(url_string);
                        const id = url.searchParams.get('id');
                        this.props.getEmployeeDetail(id);
                        setTimeout(() => {
                          this.setState({
                            // Set Sub Menu
                            selectedIndex: 2,
                            selectedMenu: 'remote',
                            errorMessage: null,
                          });
                        }, 500);
                      }}
                    >
                      <ListItemText primary="Limit Remote Working" />
                    </ListItemButton>

                    <ListItemButton
                      sx={{pl: 4}}
                      selected={selectedIndex === 5}
                      onClick={() => {
                        this.fetchUserLeave();
                        this.setState({
                          // Toggle Submenu
                          selectedIndex: 5,
                          selectedMenu: 'leave',
                          errorMessage: null,
                        });
                      }
                      }
                    >
                      <ListItemText primary="Leave" />
                    </ListItemButton>

                    <ListItemButton
                      sx={{pl: 4}}
                      selected={selectedIndex === 3}
                      onClick={() => {
                        const url_string = window.location.href;
                        const url = new URL(url_string);
                        const id = url.searchParams.get('id');
                        this.props.getEmployeeDetail(id);
                        setTimeout(() => {
                          this.setState({
                            // Set Sub Menu
                            selectedIndex: 3,
                            selectedMenu: 'account',
                            errorMessage: null,
                          });
                        }, 500);
                      }}
                    >
                      <ListItemText primary="Account" />
                    </ListItemButton>

                  </List>
                </Collapse>
              </List>
            </Drawer>
            <Box
              component="main"
              sx={{flexGrow: 1, borderRadius: 18, p: 3, overflow: 'auto'}}
            >
              {
                selectedMenu === 'personal' || selectedMenu === 'personalUpdate' ? this.renderPersonalMenu() :
                  selectedMenu === 'employment' || selectedMenu === 'employmentUpdate' ? this.renderEmploymentMenu() :
                    selectedMenu === 'remote' ? this.renderRemoteMenu() :
                      selectedMenu === 'contract' ? this.renderContractMenu() :
                        selectedMenu === 'account' ? this.renderAccountMenu() :
                          selectedMenu === 'leave' ? this.renderNewLeaveMenu() : null
              }
            </Box>
          </Box>
        </MuiThemeProvider>
      </Paper>
    );
  }

  handleDivisionForm = (event) => {
    const {name, value} = event.target;
    this.setState({
      [name]: value,
      errorMessageEmployeeDivision: null,
    });
  }

  handleSubmitDivision = (e) => {
    e.preventDefault();
    const {createDivision} = this.props;
    const {division_name, description} = this.state;
    const params = {
      ...(division_name && {division_name}),
      ...(description && {description}),
    };
    createDivision(params);
  };

  renderModalCreateDivision = () => {
    const {
      classes,
      isLoadingDivision,
    } = this.props;
    const {
      modalCreateDivision,
      errorMessageEmployeeDivision,
      division_name,
      description,
    } = this.state;
    const titleDialog = 'Create Division';

    return (
      <Dialog
        open={modalCreateDivision}
        onClose={() => this.setState({
          modalCreateDivision: false,
          errorMessageDivision: {},
          division_name: null,
          description: null,
        })}
      >
        <MuiThemeProvider theme={this.modalTitleTheme()}>
          <ModalTitle
            className={classes.modalTitle}
            title={titleDialog}
            onClose={() => this.setState({
              modalCreateDivision: false,
              errorMessageDivision: {},
              division_name: null,
              description: null,
            })}
          />
        </MuiThemeProvider>
        <form onSubmit={this.handleSubmitDivision} autoComplete="off">
          <DialogContent className={classes.dialogContent}>
            <input type="hidden" value="no-autocomplete" />
            <MInput
              classNameFC={classes.formControl}
              name="division_name"
              label="Division Name"
              placeholder="Enter division name"
              defaultValue={division_name}
              onBlur={this.handleDivisionForm}
              error={
                errorMessageEmployeeDivision && errorMessageEmployeeDivision.division_name ?
                  errorMessageEmployeeDivision.division_name :
                  undefined
              }
              autoFocus
            />
            <MInput
              classNameFC={classes.formControl}
              name="description"
              label="Description"
              placeholder="Enter description"
              defaultValue={description}
              onBlur={this.handleDivisionForm}
              error={
                errorMessageEmployeeDivision && errorMessageEmployeeDivision.description ?
                  errorMessageEmployeeDivision.description :
                  undefined
              }
            />
          </DialogContent>
          <DialogActions className={classes.dialogFooter}>
            <MButton
              className={classes.cancelBtn}
              label="Cancel"
              onClick={() => this.setState({
                modalCreateDivision: false,
                errorMessageDivision: {},
                division_name: null,
                description: null,
              })}
            />
            <MButton
              className={classes.defaultBtn}
              label={titleDialog}
              type="submit"
              icon={<Send />}
              loading={isLoadingDivision ? true : false}
            />
          </DialogActions>
        </form>
      </Dialog>
    );
  };

  renderEmploymentMenu = () => {
    const {classes, isSuperAdmin} = this.props;
    const {
      name,
      division_name,
      division_id,
      job_title,
      job_title_id,
      manager,
      manager_name,
      approval_type,
      bpjs_ketenagakerjaan_id,
      health_insurance_id,
      health_insurance_name,
      health_insurance_menu,
      employment_status,
      register_date,
      employment_type,
      start_date,
      end_date,
      main_salary,
      allowance,
      overtime_fee,
      attachment,
      reminder,
      role_id,
      shift,
    } = this.state.employmentData;
    const { showShift } = this.state;
    const {
      errorMessage,
      selectedMenu,
      showPassword,
      isMediaScreenMedium,
    } = this.state;
    const {
      email,
      password,
    } = this.state.accountData;

    const {modalCreateDivision} = this.state;

    const {
      divisionData,
      divisionLoading,
      jobData,
      jobLoading,
      userData,
      managerData,
      isLoadingManager,
      divisionDataByOrg,
      errorMessageEmployeeDivision,
      roleData,
      statusData,
      roleLoading,
    } = this.props;

    const ManagerData = [];
    userData.map((value) => {
      return ManagerData.push(value.id);
    });

    const manager_id = userData.filter((value) => {
      return value.nik === manager;
    });
    const optionEdit = managerData.filter((value) => {
      return value.name !== name;
    });


    const divisionOption = [];
    divisionDataByOrg.map((data) => {
      divisionOption.push({
        id: data.id,
        name: data.division_name,
      });
    });
    divisionOption.push({id: 0, name: '+ Add New Division...'});

    const approvalTypeOption = () => {
      const options = [
        {id: 'none', name: 'None'},
        {id: 'master', name: 'Master'},
        {id: 'approval', name: 'Approval'},
      ];
      return options;
    };

    const RoleOption = [];

    roleData.map((value) => {
      if (value.name !== 'Partner' && value.name !== 'Super Admin') {
        if (value.name === 'HR Admin') {
          RoleOption.push({id: value.id, name: `${value.name}`, isDisabled: value.name === 'HR Admin' && statusData?.hr_limit < 1 ? true : false});
        } else {
          RoleOption.push({id: value.id, name: value.name, isDisabled: false});
        }
      }
    });

    // Render Employment Data Info
    return (
      <MuiThemeProvider theme={this.formTheme()}>
        <div>
          {selectedMenu === 'employmentUpdate' ? (
            <form autoComplete="off">
              <div style={{display: 'flex', justifyContent: 'space-between', flexWrap: isMediaScreenMedium ? 'wrap' : 'nowrap'}}>
                <Typography className={classes.personalTitle} style={{marginBottom: isMediaScreenMedium ? 8 : 0}}>Update Employment Data</Typography>
                <div style={{display: 'flex', flexWrap: isMediaScreenMedium ? 'wrap-reverse' : 'nowrap'}}>
                  <MButton
                    className={classes.cancelBtn}
                    label={'CANCEL'}
                    style={{marginRight: 8, marginBottom: isMediaScreenMedium ? 4 : 0}}
                    onClick={
                      () => {
                        const url_string = window.location.href;
                        const url = new URL(url_string);
                        const id = url.searchParams.get('id');
                        this.props.getEmployeeDetail(id);
                        setTimeout(() => {
                          this.setState({
                            // Set Sub Menu
                            searchManager: true,
                            selectedIndex: 1,
                            selectedMenu: 'employment',
                            errorMessage: null,
                          });
                        }, 500);
                      }
                    }
                  />
                  <MButton
                    className={classes.defaultBtn}
                    label={'SAVE UPDATE'}
                    loading={this.props.isLoading}
                    icon={<Send />}
                    onClick={() => this.handleSubmitEmployment()}
                    disabled={errorMessage}
                    style={{marginBottom: isMediaScreenMedium ? 4 : 0}}
                  />
                </div>
              </div>
              <Typography className={classes.personalDataInformation}>Employment Information</Typography>
              <Grid container spacing={2} style={{marginBottom: 4}}>
                <Grid item xs={isMediaScreenMedium ? true : 5}>
                  <Autocomplete
                    className={classes.formControl}
                    ListboxProps={{style: {maxHeight: '12rem'}}}
                    value={division_id}
                    onChange={(event, newValue) => {
                      if (newValue.id === 0) {
                        this.setState({modalCreateDivision: true});
                      } else {
                        this.setState({
                          employmentData: {
                            ...this.state.employmentData,
                            division_id: newValue,
                          },
                        });
                      }
                    }}
                    id="controllable-states-demo"
                    options={divisionOption}
                    getOptionLabel={(option) => option.name}
                    renderInput={(params) =>
                      <TextField
                        {...params}
                        label="Division *"
                        variant='outlined'
                        error={
                          errorMessage && (errorMessage.division_id || errorMessage.division) ?
                            true : false
                        }
                        helperText={
                          errorMessage && (errorMessage.division_id || errorMessage.division) ?
                            errorMessage.division ? errorMessage.division : errorMessage.division_id :
                            undefined
                        }
                      />
                    }
                    disableClearable
                  />
                </Grid>
                <Grid item xs={isMediaScreenMedium ? true : 5}>
                  <MSelect
                    classNameFC={classes.formControl}
                    name="job_title_id"
                    value={job_title_id}
                    label="Job Title *"
                    onChange={this.handleInputEmployment}
                    error={
                      errorMessage && errorMessage.job_title_id ?
                        errorMessage.job_title_id :
                        undefined
                    }
                    keyPair={['id', 'name']}
                    options={jobData ? jobData : []}
                    loading={jobLoading}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2} style={{marginBottom: 4}}>
                <Grid item xs={isMediaScreenMedium ? true : 5}>
                  <Autocomplete
                    className={classes.formControl}
                    id="free-solo-demo"
                    freeSolo
                    options={optionEdit}
                    value={manager ? {nik: manager.manager_id, name: manager.manager_name} : null}
                    getOptionLabel={(option) => option.name}
                    onChange={(event, value) => this.selectHandler(event, value)}
                    style={{position: 'relative'}}
                    renderInput={(params) => (
                      <>
                        <TextField
                          {...params}
                          label="Supervisor *"
                          variant="outlined"
                          fullWidth
                          onChange={(event, value) => this.searchCombo(event, value)}
                          // onClick={() => this.searchCombo()}
                          error={
                            errorMessage && errorMessage.manager ?
                              errorMessage.manager :
                              undefined
                          }
                          helperText={
                            errorMessage && errorMessage.manager ?
                              errorMessage.manager :
                              undefined
                          }
                        />
                        {isLoadingManager ? <CircularProgress color="inherit" size={24} style={{position: 'absolute', right: '35px', top: '15px'}} /> : null}
                      </>
                    )}
                  />
                </Grid>
                <Grid item xs={isMediaScreenMedium ? true : 5}>
                  <div>
                    <MSelect
                      classNameFC={classes.formControl}
                      name="approval_type"
                      value={approval_type}
                      label="Approval Type *"
                      placeholder="Enter supervisor"
                      onChange={this.handleInputEmployment}
                      error={
                        errorMessage && errorMessage.approval_type ?
                          errorMessage.approval_type :
                          undefined
                      }
                      keyPair={['id', 'name']}
                      options={approvalTypeOption()}
                    />
                    <div style={{display: 'flex', alignItems: 'center', justifyContent: 'end'}}>
                      <Typography style={{fontSize: 12, fontWeight: 400}}>Information</Typography>
                      <MuiThemeProvider theme={this.tooltipTheme()}>
                        <IconButton onClick={() => this.setState({modalApprovalInformation: !this.state.modalApprovalInformation})} style={{marginLeft: 4, bottom: 1}}>
                          <HelpOutline />
                        </IconButton>
                      </MuiThemeProvider>
                    </div>
                  </div>
                </Grid>
              </Grid>

              <Grid container spacing={2} style={{marginBottom: 4}}>
                {isSuperAdmin && (
                  <Grid item xs={isMediaScreenMedium ? true : 12}>
                    <MSelectRole
                      classNameFC={classes.formControl}
                      name="role_id"
                      label="Role"
                      value={role_id}
                      onChange={this.handleInputRole}
                      error={
                        errorMessage && errorMessage.role_id ?
                          errorMessage.role_id :
                          statusData?.hr_limit ? `HR Admin Remaining: ${statusData?.hr_limit < 1 ? 0 : statusData?.hr_limit}` : undefined
                      }
                      isError={errorMessage && errorMessage.role_id ? true : false}
                      keyPair={['id', 'name', 'isDisabled']}
                      options={RoleOption ? RoleOption : []}
                      loading={roleLoading}
                    />
                  </Grid>
                )}
              </Grid>

              {/* <Divider style={{marginTop: 20, marginBottom: 18}}/>

              <Typography className={classes.personalDataInformation}>Account Information</Typography>
              <Grid container spacing={2} style={{marginBottom: 4}}>
                <Grid item xs={5}>
                  <MInput
                    classNameFC={classes.formControl}
                    name="email"
                    label="Email *"
                    placeholder="Enter email *"
                    type="email"
                    defaultValue={email}
                    onBlur={this.handleInputEmployment}
                    error={

                      errorMessage && errorMessage.email ?
                        errorMessage.email :
                        null
                    }
                  />
                </Grid>
                <Grid item xs={5}>
                  <MInput
                    classNameFC={classes.formControl}
                    name="password"
                    label="Password *"
                    placeholder="***********"
                    autoComplete="new-password"
                    type={showPassword ? 'none' : 'password'}
                    defaultValue={password}
                    onBlur={this.handleInputChange}
                    error={
                      errorMessage && errorMessage.password ?
                        errorMessage.password :
                        undefined
                    }
                    InputProps={{
                      endAdornment: (
                        <IconButton
                          position="end"
                          onClick={this.handleTogglePassword}
                        >
                          {!showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      ),
                    }}
                  />
                </Grid>
              </Grid> */}

              <Divider style={{marginTop: 12, marginBottom: 24}} />

              <Typography className={classes.personalDataInformation}>Insurance Information</Typography>

              <Grid container spacing={2} style={{marginBottom: 4}}>
                <Grid item xs={isMediaScreenMedium ? true : 5}>
                  <MInput
                    classNameFC={classes.formControl}
                    name="health_insurance_name"
                    label="Health Insurance Name"
                    placeholder="Enter Health Insurance Name"
                    defaultValue={health_insurance_name}
                    onBlur={this.handleInputEmployment}
                    error={

                      errorMessage && errorMessage.health_insurance_name ?
                        errorMessage.health_insurance_name :
                        null
                    }
                  />
                </Grid>
                <Grid item xs={isMediaScreenMedium ? true : 5}>
                  <MInput
                    classNameFC={classes.formControl}
                    name="health_insurance_id"
                    label="Health Insurance ID"
                    placeholder="Enter Health Insurance ID"
                    defaultValue={health_insurance_id}
                    onBlur={this.handleInputEmployment}
                    error={
                      errorMessage && errorMessage.health_insurance_id ?
                        errorMessage.health_insurance_id :
                        null
                    }
                  />
                </Grid>
              </Grid>

              <Grid container spacing={2} style={{marginBottom: 4}}>

                <Grid item xs={isMediaScreenMedium ? true : 5}>
                  <MInput
                    classNameFC={classes.formControl}
                    name="bpjs_ketenagakerjaan_id"
                    label="BPJS Ketenagakerjaan ID"
                    placeholder="Enter BPJS Ketenagakerjaan ID"
                    defaultValue={bpjs_ketenagakerjaan_id}
                    onBlur={this.handleInputEmployment}
                    error={
                      errorMessage && errorMessage.bpjs_ketenagakerjaan_id ?
                        errorMessage.bpjs_ketenagakerjaan_id :
                        null
                    }
                  />
                </Grid>
              </Grid>

            </form>
          ) : selectedMenu === 'employment' ? (
            <>
              <div style={{display: 'flex', justifyContent: 'space-between', flexWrap: isMediaScreenMedium ? 'wrap' : 'nowrap'}}>
                <Typography className={classes.personalTitle}>Employment</Typography>
                <MButton
                  className={classes.defaultBtn}
                  label={'UPDATE'}
                  onClick={async () => {
                    const url_string = window.location.href;
                    const url = new URL(url_string);
                    const id = url.searchParams.get('id');
                    const manager = await this.props.getOneManager(id);
                    this.setState({
                      // Set Sub Menu
                      selectedMenu: 'employmentUpdate',
                      errorMessage: null,
                      employmentData: {
                        ...this.state.employmentData,
                        manager: manager,
                      },
                    });
                  }
                  }
                />
              </div>
              <Typography className={classes.personalDataInformation}>Employment Information</Typography>
              <Grid container spacing={2} style={{marginBottom: 4, wordWrap: 'break-word'}}>
                <Grid item xs={isMediaScreenMedium ? 6 : 3}>
                  <Typography className={classes.titleText}>Division</Typography>
                </Grid>
                <Grid item xs={isMediaScreenMedium ? 6 : 9}>
                  <Typography className={classes.valueText}>{division_name ? division_name : '-'}</Typography>
                </Grid>
              </Grid>

              <Grid container spacing={2} style={{marginBottom: 4, wordWrap: 'break-word'}}>
                <Grid item xs={isMediaScreenMedium ? 6 : 3}>
                  <Typography className={classes.titleText}>Job Title</Typography>
                </Grid>
                <Grid item xs={isMediaScreenMedium ? 6 : 9}>
                  <Typography className={classes.valueText}>{job_title ? job_title : '-'}</Typography>
                </Grid>
              </Grid>

              <Grid container spacing={2} style={{marginBottom: 4, wordWrap: 'break-word'}}>
                <Grid item xs={isMediaScreenMedium ? 6 : 3}>
                  <Typography className={classes.titleText}>Supervisor</Typography>
                </Grid>
                <Grid item xs={isMediaScreenMedium ? 6 : 9}>
                  <Typography className={classes.valueText}>{manager_name ? manager_name : '-'}</Typography>
                </Grid>
              </Grid>

              <Grid container spacing={2} style={{marginBottom: 4, wordWrap: 'break-word'}}>
                <Grid item xs={isMediaScreenMedium ? 6 : 3}>
                  <Typography className={classes.titleText}>Approval Type</Typography>
                </Grid>
                <Grid item xs={isMediaScreenMedium ? 6 : 9}>
                  <Typography className={classes.valueText}>{approval_type ? approval_type : '-'}</Typography>
                </Grid>
              </Grid>

              <Grid container spacing={2} style={{marginBottom: 4, wordWrap: 'break-word'}}>
                <Grid item xs={isMediaScreenMedium ? 6 : 3}>
                  <Typography className={classes.titleText}>Role</Typography>
                </Grid>
                <Grid item xs={isMediaScreenMedium ? 6 : 9}>
                  <Typography className={classes.valueText}>{role_id === 2 ? 'HR Admin' : 'User'}</Typography>
                </Grid>
              </Grid>
              
              {featureFlag.employees.enable_fix_shift ? 
                this.props.userStatusData ? 
                <Grid container spacing={2} style={{ marginBottom: 4, wordWrap: "break-word" }}>
                  <Grid item xs={isMediaScreenMedium ? 6 : 3}>
                    <Typography className={classes.titleText}>Shift</Typography>
                  </Grid>
                  <Grid item xs={isMediaScreenMedium ? 6 : 3}>
                    <Typography className={classes.valueText}>{shift ? shift : "-"}</Typography>
                  </Grid>
                </Grid> :<></> : <></>
              }

              <Divider style={{marginTop: 20, marginBottom: 24}} />

              <Typography className={classes.bankDataInformation}>Account Information</Typography>
              <Grid container spacing={2} style={{marginBottom: 4, wordWrap: 'break-word'}}>
                <Grid item xs={isMediaScreenMedium ? 6 : 3}>
                  <Typography className={classes.titleText}>Email Address</Typography>
                </Grid>
                <Grid item xs={isMediaScreenMedium ? 6 : 9}>
                  <Typography className={classes.emailText}>{email ? email : '-'}</Typography>
                </Grid>
              </Grid>
              {/* <Grid container spacing={2} style={{marginBottom: 4}}>
                <Grid item xs={3}>
                  <Typography className={classes.titleText}>Password</Typography>
                </Grid>
                <Grid item xs={9}>
                  <Typography className={classes.valueText}>{password ? password.replace(/./g, '*') : '-'}</Typography>
                </Grid>
              </Grid> */}

              <Divider style={{marginTop: 20, marginBottom: 24}} />

              <Typography className={classes.bankDataInformation}>Insurance Information</Typography>
              <Grid container spacing={2} style={{marginBottom: 4, wordWrap: 'break-word'}}>
                <Grid item xs={isMediaScreenMedium ? 6 : 3}>
                  <Typography className={classes.titleText}>Health Insurance Name</Typography>
                </Grid>
                <Grid item xs={isMediaScreenMedium ? 6 : 9}>
                  <Typography className={classes.valueText}>{health_insurance_name ? health_insurance_name : '-'}</Typography>
                </Grid>
              </Grid>
              <Grid container spacing={2} style={{marginBottom: 4, wordWrap: 'break-word'}}>
                <Grid item xs={isMediaScreenMedium ? 6 : 3}>
                  <Typography className={classes.titleText}>Health Insurance ID</Typography>
                </Grid>
                <Grid item xs={isMediaScreenMedium ? 6 : 9}>
                  <Typography className={classes.valueText}>{health_insurance_id ? health_insurance_id : '-'}</Typography>
                </Grid>
              </Grid>
              <Grid container spacing={2} style={{marginBottom: 4, wordWrap: 'break-word'}}>
                <Grid item xs={isMediaScreenMedium ? 6 : 3}>
                  <Typography className={classes.titleText}>BPJS Ketenagakerjaan ID</Typography>
                </Grid>
                <Grid item xs={isMediaScreenMedium ? 6 : 9}>
                  <Typography className={classes.valueText}>{bpjs_ketenagakerjaan_id ? bpjs_ketenagakerjaan_id : '-'}</Typography>
                </Grid>
              </Grid>

              <Divider style={{marginTop: 20, marginBottom: 24}} />

              <Typography className={classes.bankDataInformation}>Current Contract Information</Typography>
              <Grid container spacing={2} style={{marginBottom: 4, wordWrap: 'break-word'}}>
                <Grid item xs={isMediaScreenMedium ? 6 : 3}>
                  <Typography className={classes.titleText}>Employment Status</Typography>
                </Grid>
                <Grid item xs={isMediaScreenMedium ? 6 : 9}>
                  <Typography className={classes.valueText}>{employment_status ? employment_status : '-'}</Typography>
                </Grid>
              </Grid>
              {/* <Grid container spacing={2} style={{marginBottom: 4}}>
                <Grid item xs={3}>
                  <Typography className={classes.titleText}>Register Date</Typography>
                </Grid>
                <Grid item xs={9}>
                  <Typography className={classes.valueText}>{register_date ? register_date : '-'}</Typography>
                </Grid>
              </Grid> */}
              <Grid container spacing={2} style={{marginBottom: 4, wordWrap: 'break-word'}}>
                <Grid item xs={isMediaScreenMedium ? 6 : 3}>
                  <Typography className={classes.titleText}>Employee Type</Typography>
                </Grid>
                <Grid item xs={isMediaScreenMedium ? 6 : 9}>
                  <Typography className={classes.valueText}>{employment_type ? employment_type + ' Employee' : '-'}</Typography>
                </Grid>
              </Grid>
              <Grid container spacing={2} style={{marginBottom: 4, wordWrap: 'break-word'}}>
                <Grid item xs={isMediaScreenMedium ? 6 : 3}>
                  <Typography className={classes.titleText}>Start Date</Typography>
                </Grid>
                <Grid item xs={isMediaScreenMedium ? 6 : 9}>
                  <Typography className={classes.valueText}>{start_date ? convDate(start_date, 'DD MMMM YYYY') : '-'}</Typography>
                </Grid>
              </Grid>
              <Grid container spacing={2} style={{marginBottom: 4, wordWrap: 'break-word'}}>
                <Grid item xs={isMediaScreenMedium ? 6 : 3}>
                  <Typography className={classes.titleText}>End Date</Typography>
                </Grid>
                <Grid item xs={isMediaScreenMedium ? 6 : 9}>
                  <Typography className={classes.valueText}>{end_date ? convDate(end_date, 'DD MMMM YYYY') : '-'}</Typography>
                </Grid>
              </Grid>
              {employment_type !== 'permanent' ? (
                <Grid container spacing={2} style={{marginBottom: 4, wordWrap: 'break-word'}}>
                  <Grid item xs={isMediaScreenMedium ? 6 : 3}>
                    <Typography className={classes.titleText}>Reminder</Typography>
                  </Grid>
                  <Grid item xs={isMediaScreenMedium ? 6 : 9}>
                    <Typography className={classes.valueText}>{reminder ? `${reminder} days before end date` : '-'}</Typography>
                  </Grid>
                </Grid>
              ) : <></>}
              <Grid container spacing={2} style={{marginBottom: 4, wordWrap: 'break-word'}}>
                <Grid item xs={isMediaScreenMedium ? 6 : 3}>
                  <Typography className={classes.titleText}>Main Salary (Monthly)</Typography>
                </Grid>
                <Grid item xs={isMediaScreenMedium ? 6 : 9}>
                  <Typography className={classes.valueText}>{main_salary ? `Rp${thousandSeparator(main_salary)}` : '-'}</Typography>
                </Grid>
              </Grid>
              {/* <Grid container spacing={2} style={{marginBottom: 4}}>
                <Grid item xs={3}>
                  <Typography className={classes.titleText}>Allowance (Daily)</Typography>
                </Grid>
                <Grid item xs={9}>
                  <Typography className={classes.valueText}>{allowance ? `Rp${thousandSeparator(allowance)}` : '-'}</Typography>
                </Grid>
              </Grid>
              <Grid container spacing={2} style={{marginBottom: 4}}>
                <Grid item xs={3}>
                  <Typography className={classes.titleText}>Overtime Fee (Hourly)</Typography>
                </Grid>
                <Grid item xs={9}>
                  <Typography className={classes.valueText}>{overtime_fee ? `Rp${thousandSeparator(overtime_fee)}` : '-'}</Typography>
                </Grid>
              </Grid> */}
              <Grid container spacing={2} style={{marginBottom: 4, wordWrap: 'break-word'}}>
                <Grid item xs={isMediaScreenMedium ? 6 : 3}>
                  <Typography className={classes.titleText}>External Document</Typography>
                </Grid>
                <Grid item xs={isMediaScreenMedium ? 6 : 9}>
                  {attachment ? (
                    <ButtonLink href={attachment.search('http') !== -1 ? attachment : `https://${attachment}`} target='_blank'>
                      <MButton
                        className={classes.defaultBtnContract}
                        label={'Open Link'}
                        icon={<AttachFile />}
                      />
                    </ButtonLink>
                  ) : (
                    <Typography className={classes.titleText}>-</Typography>
                  )}
                </Grid>
              </Grid>
            </>
          ) : null}
        </div>
      </MuiThemeProvider>
    );
  }

  render() {
    const {selectedMenu} = this.state;

    return (
      <div>
        {selectedMenu === 'personal' && this.renderPersonalMenu()}
        {selectedMenu === 'employment' && this.renderEmploymentMenu()}
        {selectedMenu === 'remote' && this.renderRemoteMenu()}
        {selectedMenu === 'contract' && this.renderContractMenu()}
        {selectedMenu === 'account' && this.renderAccountMenu()}
        {selectedMenu === 'leave' && this.renderNewLeaveMenu()}
      </div>
    );
  }
  
  handleSwitch = (event) => {
    const {checked} = event.target;
    this.setState({remoteWorking: checked});
  }

  renderRemoteMenu = () => {
    const {classes} = this.props;
    // Render Personal Data Info

    const {remoteWorking, errorMessageRemote, isMediaScreenMedium} = this.state;
    const {limit_remote, additional_limit_remote} = this.state.remoteData;
    return (
      <MuiThemeProvider theme={this.formTheme()}>
        <div>
          <form onSubmit={this.handleSubmitRemote} autoComplete="off">
            <div style={{display: 'flex', justifyContent: 'space-between', flexWrap: isMediaScreenMedium ? 'wrap' : 'nowrap'}}>
              <Typography className={classes.personalTitle} style={{marginBottom: isMediaScreenMedium ? 8 : 0}}>Limit Remote Working</Typography>
              <div style={{display: 'flex'}}>
                <MButton
                  className={classes.defaultBtn}
                  label={'SAVE UPDATE'}
                  type="submit"
                  loading={this.props.isLoading}
                  icon={<Send />}
                />
              </div>
            </div>

            <div style={{display: 'flex', alignItems: 'center', marginTop: 24}}>
              <Typography className={classes.limitRemoteTitle}>Limit Remote Working</Typography>
              <MuiThemeProvider theme={this.switchTheme()}>
                <Switch color='primary' size="small" checked={remoteWorking} onChange={(event) => this.handleSwitch(event)} />
              </MuiThemeProvider>
            </div>
            <Typography className={classes.remoteDescription}>
              Jika nonaktif maka karyawan tidak memiliki limit kuota remote working, jika aktif maka anda dapat mengatur jumlah kuota dalam satu bulan
            </Typography>

            <Grid container spacing={2} style={{marginBottom: 4}}>
              <Grid item xs={isMediaScreenMedium ? true : 5}>
                <MInput
                  classNameFC={classes.formControl}
                  name="limit_remote"
                  label="Remote Working Quota"
                  placeholder="Enter remote working quota"
                  type="number"
                  onKeyDown={(event) => {
                    if (event.key === '.' || event.key === '-' || event.key === 'e' || event.key === ',') {
                      event.preventDefault();
                    }
                  }}
                  defaultValue={limit_remote}
                  onBlur={this.handleInputRemote}
                  error={
                    errorMessageRemote && errorMessageRemote.limit_remote ?
                      errorMessageRemote.limit_remote :
                      null
                  }
                  disabled={!remoteWorking}
                />
              </Grid>
              <Grid item xs={isMediaScreenMedium ? true : 5}>
                <MInput
                  classNameFC={classes.formControl}
                  name="additional_limit_remote"
                  label="Additional Remote Limit"
                  placeholder="Enter additional remote limit"
                  type="number"
                  onKeyDown={(event) => {
                    if (event.key === '.' || event.key === '-' || event.key === 'e' || event.key === ',') {
                      event.preventDefault();
                    }
                  }}
                  defaultValue={additional_limit_remote}
                  onBlur={this.handleInputRemote}
                  error={

                    errorMessageRemote && errorMessageRemote.additional_limit_remote ?
                      errorMessageRemote.additional_limit_remote :
                      null
                  }
                  disabled={!remoteWorking}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={isMediaScreenMedium ? true : 8}>
                <Paper variant='outlined' className={classes.remotePaper}>
                  <div style={{display: 'flex'}}>
                    <Typography className={classes.paperTextRemote} style={{marginBottom: 4}}>
                      1.&nbsp;
                    </Typography>
                    <Typography className={classes.paperTextRemote}>
                      Remote Working Kuota adalah kuota yang dimiliki setiap karyawan dalam satu bulan. Jika diisi 0 maka tidak dapat melakukan remote working, jika diisi 5 maka user memiliki kuota 5 dalam satu bulan
                    </Typography>
                  </div>
                  <div style={{display: 'flex'}}>
                    <Typography className={classes.paperTextRemote} style={{marginBottom: 4}}>
                      2.&nbsp;
                    </Typography>
                    <Typography className={classes.paperTextRemote}>
                      Additional remote limit adalah kuota tambahan yang hanya berlaku dalam satu bulan dan akan kembali nol di bulan berikutnya.
                    </Typography>
                  </div>
                </Paper>
              </Grid>
            </Grid>

          </form>
        </div>
      </MuiThemeProvider>
    );
  }

  handleConfirmDelete = (data) => {
    showPopup(
      'confirm',
      'Are you sure you want to delete this data ?',
      'Yes',
      () => {
        this.props.deleteContractUser(data[1]);
      },
    );
  }

  toggleFormContract = (type, data) => {
    if (type === 'edit') {
      this.setState({
        contractData: {
          ...this.state.contractData,
          user_id: data[1],
          employment_type: data[3],
          main_salary: data[7],
          start_date: data[2],
          end_date: data[5],
          allowance: data[8],
          overtime_fee: data[9],
          reminder: data[10],
          attachment: data[4],
        },
        modalEditContract: true,
        errorMessage: null,
        editingContract: true,
      });
    } else if (type === 'add') {
      // this.props.getListEmployeeType();
      this.setState({
        modalEditContract: true,
        errorMessage: null,
        selectedIndex: 4,
        selectedMenu: 'contract',

        contractData: {
          user_id: null,
          employment_status: false,
          register_date: null,
          employment_type: null,
          start_date: null,
          end_date: null,
          main_salary: null,
          allowance: null,
          overtime_fee: null,
          reminder: null,
          attachment: null,
        },
      });
    } else if (type === 'close') {
      this.setState({
        modalEditContract: false,
        errorMessage: null,
      });
    } else {
      this.setState({
        modalEditContract: true,
        errorMessage: null,
      });
    }
  }

  renderActions = (params) => {
    const {orgStatusData, isSuperAdmin} = this.props;
    const rowDataDisable = 12;
    const disableCondition = orgStatusData && orgStatusData.adv_sync;
    return (
      <Grid container wrap='nowrap' direction='row'>
        <Grid item>
          <Tooltip title="Detail">
            <Button
              variant="contained"
              // color="primary"
              size="small"
              style={{
                'backgroundColor': '#00AFF0',
                'boxShadow': 'none',
                'color': '#fff',
                '&:hover': {
                  boxShadow: 'none',
                  backgroundColor: '#0198D0',
                },
                'minWidth': '10px',
                'paddingRight': '0px',
                'marginRight': '4px',
              }}
              onClick={() => {
                this.setState({
                  modalDetailContract: true,
                  contractData: {
                    ...this.state.contractData,
                    employment_status: params[6],
                    employment_type: params[3],
                    start_date: params[2],
                    end_date: params[5],
                    main_salary: params[7],
                    allowance: params[8],
                    overtime_fee: params[9],
                    reminder: params[10],
                    attachment: params[4],
                  },
                });
              }}
              startIcon={<Search />}>
            </Button>
          </Tooltip>
        </Grid>
        <Grid item>
          <Tooltip title="Edit">
            <Button
              variant="contained"
              // color="primary"
              size="small"
              style={{
                'backgroundColor': '#00AFF0',
                'boxShadow': 'none',
                'color': '#fff',
                '&:hover': {
                  boxShadow: 'none',
                  backgroundColor: '#0198D0',
                },
                'minWidth': '10px',
                'paddingRight': '0px',
                'marginRight': '4px',
              }}
              onClick={() => {
                this.toggleFormContract('edit', params);
              }}
              startIcon={<Edit />}>
            </Button>
          </Tooltip>
        </Grid>
        <Grid item>
          <Tooltip title="Delete">
            <Button
              variant="contained"
              // color="primary"
              size="small"
              style={{
                'backgroundColor': '#E5AF5A',
                'boxShadow': 'none',
                'color': '#fff',
                '&:hover': {
                  boxShadow: 'none',
                  backgroundColor: '#D09F53',
                },
                'minWidth': '10px',
                'paddingRight': '0px',
              }}
              onClick={() => this.handleConfirmDelete(params)}
              startIcon={<Delete />}>
            </Button>
          </Tooltip>
        </Grid>
      </Grid>
    );
  };

  renderModalDetailContract = () => {
    const {classes} = this.props;
    const {modalDetailContract, file} = this.state;

    const {
      employment_status,
      employment_type,
      start_date,
      end_date,
      main_salary,
      allowance,
      overtime_fee,
      reminder,
      attachment,
    } = this.state.contractData;

    return (
      <Dialog
        open={modalDetailContract}
        onClose={() => this.setState({modalDetailContract: false})}
        maxWidth='sm'
        fullWidth
      >
        <ModalTitle
          title={'Contract Information'}
          onClose={() => this.setState({modalDetailContract: false})}
        />
        <DialogContent className={classes.dialogContentContract}>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <Typography className={classes.valueText}>Employment Status</Typography>
            </Grid>
            <Grid item>
              <Typography className={classes.valueText}>:</Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography className={classes.valueText}>{employment_status === true ? 'Active' : 'Inactive'}</Typography>
            </Grid>
          </Grid>

          {/* <Grid container spacing={2}>
            <Grid item xs={4}>
              <Typography className={classes.valueText}>Register Date</Typography>
            </Grid>
            <Grid item>
              <Typography className={classes.valueText}>:</Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography className={classes.valueText}>12 Desember 2019</Typography>
            </Grid>
          </Grid> */}

          <Grid container spacing={2}>
            <Grid item xs={4}>
              <Typography className={classes.valueText}>Employee Type</Typography>
            </Grid>
            <Grid item>
              <Typography className={classes.valueText}>:</Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography className={classes.valueText}>{employment_type ? `${employment_type} Employee` : '-'} </Typography>
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={4}>
              <Typography className={classes.valueText}>Start Date</Typography>
            </Grid>
            <Grid item>
              <Typography className={classes.valueText}>:</Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography className={classes.valueText}>{start_date ? convDate(start_date, 'DD-MMMM-YYYY') : '-'}</Typography>
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={4}>
              <Typography className={classes.valueText}>End Date</Typography>
            </Grid>
            <Grid item>
              <Typography className={classes.valueText}>:</Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography className={classes.valueText}>{end_date ? convDate(end_date, 'DD-MMMM-YYYY') : '-'}</Typography>
            </Grid>
          </Grid>

          {employment_type !== 'permanent' ? (
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <Typography className={classes.valueText}>Reminder</Typography>
              </Grid>
              <Grid item>
                <Typography className={classes.valueText}>:</Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography className={classes.valueText}>{reminder ? `${reminder} days before end date` : '-'}</Typography>
              </Grid>
            </Grid>
          ) : null}

          <Grid container spacing={2}>
            <Grid item xs={4}>
              <Typography className={classes.valueText}>Main Salary (Monthly)</Typography>
            </Grid>
            <Grid item>
              <Typography className={classes.valueText}>:</Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography className={classes.valueText}>{main_salary ? 'Rp' + thousandSeparator(main_salary) : '-'}</Typography>
            </Grid>
          </Grid>

          {/* <Grid container spacing={2}>
            <Grid item xs={4}>
              <Typography className={classes.valueText}>Allowance (Daily)</Typography>
            </Grid>
            <Grid item>
              <Typography className={classes.valueText}>:</Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography className={classes.valueText}>{allowance ? 'Rp' + thousandSeparator(allowance) : '-'}</Typography>
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={4}>
              <Typography className={classes.valueText}>Overtime Fee (Hourly)</Typography>
            </Grid>
            <Grid item>
              <Typography className={classes.valueText}>:</Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography className={classes.valueText}>{overtime_fee ? 'Rp' + thousandSeparator(overtime_fee ) : '-'}</Typography>
            </Grid>
          </Grid> */}

          <Grid container spacing={2}>
            <Grid item xs={4}>
              <Typography className={classes.valueText}>External Document</Typography>
            </Grid>
            <Grid item>
              <Typography className={classes.valueText}>:</Typography>
            </Grid>
            <Grid item xs={4}>
              {attachment ? (
                <ButtonLink href={attachment.search('http') !== -1 ? attachment : `https://${attachment}`} target='_blank'>
                  <MButton
                    className={classes.defaultBtnContract}
                    label={'Open Link'}
                    icon={<AttachFile />}
                  />
                </ButtonLink>
              ) : (
                <Typography className={classes.valueText}>-</Typography>
              )}
            </Grid>
          </Grid>

        </DialogContent>
      </Dialog>
    );
  }

  handleResetFormContract = (closeModal) => {
    this.setState({
      errorMessage: null,
      contractData: {
        user_id: null,
        employment_status: false,
        register_date: null,
        employment_type: null,
        start_date: null,
        end_date: null,
        main_salary: null,
        allowance: null,
        overtime_fee: null,
        reminder: null,
        attachment: null,
      },
      modalEditContract: false,
      editingContract: false,
    });
  }

  renderModalEditContract = () => {
    const NumericFormatCustom = React.forwardRef(function NumericFormatCustom(
      props,
      ref,
    ) {
      const {onChange, ...other} = props;
      return (
        <NumericFormat
          {...other}
          getInputRef={ref}
          onChange={(values) => {
            this.setState({
              contractData: {
                ...this.state.contractData,
                [props.name]: values.value,
              },
              errorMessage: {...errorMessage, [props.name]: null},
            });
          }}
          thousandSeparator='.'
          decimalSeparator=','
          valueIsNumericString
          prefix="Rp"
        />
      );
    });

    NumericFormatCustom.propTypes = {
      name: PropTypes.string.isRequired,
      onChange: PropTypes.func.isRequired,
    };

    const {classes, isLoadingContract, employeeTypeList} = this.props;
    const {modalEditContract, errorMessage, editingContract} = this.state;
    const {
      employment_status,
      register_date,
      employment_type,
      start_date,
      end_date,
      main_salary,
      allowance,
      overtime_fee,
      reminder,
      attachment,
    } = this.state.contractData;
    const titleDialog = editingContract ? 'Update Contract' : 'Add Contract';

    const employeeTypeOption = () => {
      const options = employeeTypeList.map((el) => ({
        id: el?.name,
        name: el?.name,
      }));
      return options;
    };

    return (
      <Dialog
        className={classes.dialogContent}
        open={modalEditContract}
        onClose={() => this.handleResetFormContract()}
        maxWidth='sm'
        fullWidth
      >
        <ModalTitle
          title={titleDialog}
          onClose={() => this.handleResetFormContract()}
        />
        <form onSubmit={this.handleSubmitContract}>
          <DialogContent className={classes.dialogContentContract}>
            <Typography className={classes.contractSubTitle}>Current Contract Information</Typography>

            {/* <div style={{display: 'flex', marginTop: 24, marginBottom: 8, alignItems: 'center'}}>
              <Typography className={classes.employeeStatus}>Employee Status</Typography>
              <Switch checked={employment_status} size="small" color='primary' handleCh />
            </div>

            <Typography className={classes.employeeDesc}>Jika status karyawan ini dinonaktifkan, maka tidak lagi terkait dengan perusahaan, sehingga karyawan tersebut akan dikeluarkan dari daftar karyawan aktif perusahaan, begitu pula sebaliknya.</Typography> */}

            <Grid container spacing={2}>
              <Grid item xs={6}>
                <MSelect
                  classNameFC={classes.formControl}
                  name="employment_type"
                  value={employment_type}
                  label="Employment Type *"
                  onChange={this.handleInputContract}
                  error={
                    errorMessage && errorMessage.employment_type ?
                      errorMessage.employment_type :
                      undefined
                  }
                  keyPair={['id', 'name']}
                  options={employeeTypeOption()}
                  fullWidth
                />
              </Grid>
              <Grid item xs={6}>
                <NumericFormat
                  className={classes.formControl}
                  fullWidth
                  name='main_salary'
                  label="Main Salary"
                  value={main_salary}
                  customInput={TextField}
                  InputProps={{
                    startAdornment: <InputAdornment position="start">Rp</InputAdornment>,
                  }}
                  allowNegative={false}
                  thousandSeparator='.'
                  decimalSeparator=','
                  variant='outlined'
                  placeholder="0"
                  onValueChange={({value}) =>
                    this.setState({
                      contractData: {
                        ...this.state.contractData,
                        main_salary: Number(value),
                      },
                      errorMessage: null,
                    })
                  }
                  error={errorMessage && errorMessage.main_salary ? true : false}
                  helperText={errorMessage && errorMessage.main_salary ? errorMessage.main_salary : null}
                />
                {/* <MInput
                  classNameFC={classes.formControl}
                  name="main_salary"
                  label="Main Salary *"
                  placeholder="Enter main salary"
                  type="number"
                  onKeyDown={(event) => {
                    if (event.key === '.' || event.key === '-' || event.key === 'e' || event.key === ',') {
                      event.preventDefault();
                    }
                  }}
                  value={main_salary}
                  onBlur={this.handleInputContract}
                  error={
                    errorMessage && errorMessage.main_salary ?
                      errorMessage.main_salary :
                      null
                  }
                  InputProps={{
                    inputComponent: NumericFormatCustom,
                  }}
                /> */}
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid item xs={6}>
                <MDatepicker
                  className={classes.formControl}
                  name="start_date"
                  label="Start Date *"
                  value={convDate(start_date)}
                  onDateChange={this.handleContractDate}
                  error={
                    errorMessage && errorMessage.start_date ? errorMessage.start_date : undefined
                  }
                  placeholder="dd-mm-yyyy"
                  inputVariant="outlined"
                  openTo="year"
                  views={['year', 'month', 'date']}
                  fullWidth
                />
              </Grid>
              {employment_type !== 'permanent' ? <Grid item xs={6}>
                <MDatepicker
                  className={classes.formControl}
                  name="end_date"
                  label="End Date *"
                  value={convDate(end_date)}
                  onDateChange={this.handleContractDate}
                  error={
                    errorMessage && errorMessage.end_date ? errorMessage.end_date : undefined
                  }
                  placeholder="dd-mm-yyyy"
                  inputVariant="outlined"
                  openTo="year"
                  views={['year', 'month', 'date']}
                  fullWidth
                  disabled={start_date ? false : true}
                  minDate={start_date}
                />
              </Grid> : ''
              }
            </Grid>
            {/* <Grid container spacing={2}>
              <Grid item xs={6}>
                <NumericFormat
                  className={classes.formControl}
                  fullWidth
                  name='allowance'
                  label="Allowance (Daily)"
                  value={allowance}
                  customInput={TextField}
                  InputProps={{
                    startAdornment: <InputAdornment position="start">Rp</InputAdornment>,
                  }}
                  allowNegative={false}
                  thousandSeparator='.'
                  decimalSeparator=','
                  variant='outlined'
                  placeholder="0"
                  onValueChange={({value}) =>
                    this.setState({
                      contractData: {
                        ...this.state.contractData,
                        allowance: Number(value),
                      },
                      errorMessage: {...errorMessage, allowance: null},
                    })
                  }
                  error={errorMessage && errorMessage.allowance ? true : false}
                  helperText={errorMessage && errorMessage.allowance ? errorMessage.allowance : null}
                />
              </Grid>
              <Grid item xs={6}>
                <NumericFormat
                  className={classes.formControl}
                  fullWidth
                  name='overtime_fee'
                  label="Overtime Fee (Hourly)"
                  value={overtime_fee}
                  customInput={TextField}
                  InputProps={{
                    startAdornment: <InputAdornment position="start">Rp</InputAdornment>,
                  }}
                  allowNegative={false}
                  thousandSeparator='.'
                  decimalSeparator=','
                  variant='outlined'
                  placeholder="0"
                  onValueChange={({value}) =>
                    this.setState({
                      contractData: {
                        ...this.state.contractData,
                        overtime_fee: Number(value),
                      },
                      errorMessage: {...errorMessage, overtime_fee: null},
                    })
                  }
                  error={errorMessage && errorMessage.overtime_fee ? true : false}
                  helperText={errorMessage && errorMessage.overtime_fee ? errorMessage.overtime_fee : null}
                />
              </Grid>
            </Grid> */}

            {employment_type === 'permanent' ? (null) : (
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <div>
                    <MInput
                      classNameFC={classes.formControl}
                      name="reminder"
                      label="Reminder *"
                      placeholder="Enter reminder"
                      type="number"
                      onKeyDown={(event) => {
                        if (event.key === '.' || event.key === '-' || event.key === 'e' || event.key === ',') {
                          event.preventDefault();
                        }
                      }}
                      defaultValue={reminder}
                      onBlur={this.handleInputContract}
                      error={
                        errorMessage && errorMessage.reminder ?
                          errorMessage.reminder :
                          null
                      }
                    />
                    <div style={{display: 'flex', alignItems: 'center', justifyContent: 'end'}}>
                      <Typography style={{fontSize: 12, fontWeight: 400}}>Reminder Information</Typography>
                      <MuiThemeProvider theme={this.tooltipTheme()}>
                        <Tooltip placement='bottom-start' followCursor title="If you fill this field, you will get notified X days before employee’s end date.">
                          <IconButton style={{marginLeft: 4, bottom: 1}}>
                            <HelpOutline />
                          </IconButton>
                        </Tooltip>
                      </MuiThemeProvider>
                    </div>
                  </div>
                </Grid>
              </Grid>
            )}
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <MInput
                  classNameFC={classes.formControl}
                  name="attachment"
                  label="External Document * "
                  placeholder="Insert external document link"
                  onChange={this.handleInputContract}
                  defaultValue={attachment}
                  error={
                    errorMessage && errorMessage.attachment ?
                      errorMessage.attachment :
                      undefined
                  }
                />
              </Grid>
            </Grid>

          </DialogContent>
          <DialogActions className={classes.dialogFooter}>
            <MButton
              id="cancel"
              className={classes.cancelBtn}
              label='Cancel'
              size='small'
              onClick={() => this.handleResetFormContract()}
            />
            <MButton
              id="submit"
              className={classes.defaultBtn}
              label={titleDialog}
              color="primary"
              size='small'
              type="submit"
              icon={<Send />}
              disabled={errorMessage}
            // loading={isLoadingContract}
            />
          </DialogActions>
        </form>
      </Dialog>
    );
  };

  renderContractMenu = () => {
    const {classes, contractData, contractMeta, isLoading} = this.props;
    // Render Contract Data Info

    const columns = [
      {name: 'id', label: 'id', display: false}, // Data [1]
      {
        name: 'start_date', label: 'Start Date', display: true, // Data [2]
        customBodyRender: (value) => {
          return value ? convDate(value, 'DD-MM-YYYY') : '-';
        },
      },
      {
        name: 'employment_type', label: 'Employment Status', display: true, // Data [3]
        customBodyRender: (value) => {
          return value ?
            <Typography className={classes.tableText}>{value} Employee</Typography> :
            '-';
        },
      },
      {
        name: 'attachment', label: 'External Document', display: true, // Data [4]
        customBodyRender: (value, tableMeta) => {
          const {page, limit} = this.state;
          const number = tableMeta.rowIndex + ((page - 1) * limit) + 1;
          return value ?
            (
              <ButtonLink href={value.search('http') !== -1 ? value : `https://${value}`} target='_blank'>
                <Tooltip title="External Document">
                  <Button
                    variant="contained"
                    // color="primary"
                    size="small"
                    style={{
                      'backgroundColor': '#00AFF0',
                      'boxShadow': 'none',
                      'color': '#fff',
                      '&:hover': {
                        boxShadow: 'none',
                        backgroundColor: '#0198D0',
                      },
                      'minWidth': '10px',
                      'paddingRight': '0px',
                      'marginRight': '4px',
                    }}
                    startIcon={<AttachFile />}>
                  </Button>
                </Tooltip>
              </ButtonLink>
            ) : '-';
        },
      },
      {
        name: 'end_date', label: 'End Date', display: true, // Data [5]
        customBodyRender: (value) => {
          return value ? convDate(value, 'DD-MM-YYYY') : '-';
        },
      },
      {
        name: 'employment_status', label: 'Status', display: true, // Data [6]
        customBodyRender: (value, params) => {
          return (
            <MuiThemeProvider theme={this.switchTheme}>
              <Switch color='primary' size="small" checked={value} onChange={() => {
                if (value) {
                  showPopup(
                    'confirm',
                    'Are you sure you want to deactivate this contract ?',
                    'Yes',
                    () => {
                      this.props.updateContractStatus(params.rowData[1], {employment_status: !value});
                    },
                  );
                } else {
                  showPopup(
                    'confirm',
                    'Are you sure you want to activate this contract ?',
                    'Yes',
                    () => {
                      this.props.updateContractStatus(params.rowData[1], {employment_status: !value});
                    },
                  );
                }
              }}
              />
            </MuiThemeProvider>
          );
        },
      },
      {
        name: 'main_salary', label: 'Main Salary', display: false, // Data [7]
        customBodyRender: (value) => {
          return value ? value : '-';
        },
      },
      {
        name: 'allowance', label: 'Allowance', display: false, // Data [8]
        customBodyRender: (value) => {
          return value ? value : '-';
        },
      },
      {
        name: 'overtime_fee', label: 'Overtime Fee', display: false, // Data [9]
        customBodyRender: (value) => {
          return value ? value : '-';
        },
      },
      {
        name: 'reminder', label: 'Reminder', display: false, // Data [10]
        customBodyRender: (value) => {
          return value ? value : '-';
        },
      },
    ];


    return (
      <MuiThemeProvider theme={this.formTheme()}>
        <div style={{display: 'flex', justifyContent: 'space-between', marginBottom: 24}}>
          <Typography className={classes.personalTitle}>Contract History</Typography>
        </div>

        <Datatable
          title={'Contract List'}
          loading={isLoading}
          dataSource={contractData}
          total={contractMeta ? contractMeta.total : 0}
          page={contractMeta ? contractMeta.page : 1}
          hideActions={false}
          columns={columns}
          handleReload={(params) => this.handleReloadContract(params)}
          customActions={(data) => this.renderActions(data)}
          creatable={false}
          paging={contractMeta.total > 10 ? true : false}
        // handleEdit={(data) => this.handleFormToggle('edit', data)}
        // handleSearch={(params) => this.handleSearch(params)}
        />
      </MuiThemeProvider>
    );
  }

  renderAccountMenu = () => {
    const {classes} = this.props;
    // Render Personal Data Info

    const {remoteWorking, errorMessage, isMediaScreenMedium} = this.state;
    const {email, password} = this.state.accountData;
    return (
      <MuiThemeProvider theme={this.formTheme()}>
        <div>
          <form onSubmit={this.handleSubmitAccount} autoComplete="off">
            <div style={{display: 'flex', justifyContent: 'space-between', flexWrap: isMediaScreenMedium ? 'wrap' : 'nowrap'}}>
              <Typography className={classes.personalTitle} style={{marginBottom: isMediaScreenMedium ? 8 : 0}}>Account</Typography>
              <div style={{display: 'flex'}}>
                <MButton
                  className={classes.defaultBtn}
                  label={'SAVE UPDATE'}
                  type="submit"
                  // loading={this.props.isLoading}
                  icon={<Send />}
                />
              </div>
            </div>

            <Grid container spacing={2} style={{marginBottom: 4}}>
              <Grid item xs={isMediaScreenMedium ? true : 5}>
                <MInput
                  classNameFC={classes.formControl}
                  name="email"
                  label="Email *"
                  placeholder="Enter email *"
                  type="email"
                  defaultValue={email}
                  onBlur={this.handleInputAccount}
                  error={

                    errorMessage && errorMessage.email ?
                      errorMessage.email :
                      null
                  }
                />
              </Grid>
              <Grid item xs={isMediaScreenMedium ? true : 5}>
                <MInput
                  classNameFC={classes.formControl}
                  name="password"
                  label="Password *"
                  placeholder="***********"
                  autoComplete="new-password"
                  type={'password'}
                  defaultValue={password}
                  onBlur={this.handleInputAccount}
                  error={
                    errorMessage && errorMessage.password ?
                      errorMessage.password :
                      undefined
                  }
                />
              </Grid>
            </Grid>
          </form>
        </div>
      </MuiThemeProvider>

    );
  }


  toggleModalAdjusmentLeave = (params) => {
    if (params === 'open') {
      const {updateHistoryData} = this.props;
      let filteredData = null;
      updateHistoryData.map((data) => {
        if (data.year === moment().year()) {
          filteredData = data;
        }
      });
      this.setState({
        modalAdjustmentLeave: true,
        adjustmentLeave: {
          totalLeave: filteredData?.total_leave ? filteredData?.total_leave : '-',
          accumulativeLeave: filteredData?.accumulative_leave ? filteredData?.accumulative_leave : '-',
          annualLeave: filteredData?.annual_leave ? filteredData?.annual_leave : '-',
          leaveRemaining: filteredData?.remaining ? filteredData?.remaining : '-',
        },
      });
    } else if (params === 'close') {
      this.setState({
        modalAdjustmentLeave: false,
        adjustmentLeave: {
          total_leave: null,
          accumulativeLeave: null,
          annualLeave: null,
          leaveRemaining: null,
        },
      });
    }
  };

  toggleModalLeaveHistory = (type, params) => {
    if (type === 'open') {
      this.setState({
        modalLeaveHistoryDetail: true,
        modalLeaveInformationData: {
          details: params[2],
          start_date: params[3],
          end_date: params[4],
          total_days: params[5],
          file: params[6],
          approval_history: params[7],
          status: params[8],
        },
        timelineAccordion: false,
      });
    } else if (type === 'close') {
      this.setState({
        modalLeaveHistoryDetail: false,
        modalLeaveInformationData: {
          approval_history: [],
          start_date: null,
          end_date: null,
          total_days: null,
          details: null,
          file: null,
          status: null,
        },
        timelineAccordion: false,
      });
    }
  };

  toggleTimelineAccordion = () => {
    this.setState({
      timelineAccordion: !this.state.timelineAccordion,
    });
  }

  handleInputAdjustmentLeave = (event) => {
    const {name, value} = event.target;
    const {errorMessageAdjustmentLeave} = this.state;

    if (name === 'days') {
      this.setState({
        adjustmentLeaveForm: {
          ...this.state.adjustmentLeaveForm,
          [name]: value,
        },
        errorMessageAdjustmentLeave: {...errorMessageAdjustmentLeave, total_days: null},
      });
    } else {
      this.setState({
        adjustmentLeaveForm: {
          ...this.state.adjustmentLeaveForm,
          [name]: value,
        },
        errorMessageAdjustmentLeave: {...errorMessageAdjustmentLeave, [name]: null},
      });
    }
  }

  handleSubmitAdjustmentLeave = (e) => {
    e.preventDefault();
    const url_string = window.location.href;
    const url = new URL(url_string);
    const id = url.searchParams.get('id');
    const {type, days, description} = this.state.adjustmentLeaveForm;

    const params = {
      ...(id && {user_id: id * 1}),
      ...(type && {type: type}),
      ...(days && {total_days: days * 1}),
      ...(description && {description: description}),
    };
    return this.props.createAdjustmentLeave(params);
  }

  renderModalAdjustmentLeave = () => {
    const {classes, isLoading} = this.props;
    const {modalAdjustmentLeave, errorMessageAdjustmentLeave} = this.state;
    const titleDialog = 'Adjustment Leave';

    const {
      year,
      annual_leave,
      deductive,
      additional,
      leave_remaining,
      total_leave,
    } = this.state.leaveData;

    const {
      totalLeave, accumulativeLeave, annualLeave, leaveRemaining,
    } = this.state.adjustmentLeave;

    const {
      type,
      days,
      description,
    } = this.state.adjustmentLeaveForm;

    const typeOption = () => {
      const options = [
        {id: 'increase', name: 'Increase'},
        {id: 'reduce', name: 'Reduce'},
      ];
      return options;
    };

    return (
      <MuiThemeProvider theme={this.modalTheme()}>
        <Dialog
          className={classes.dialogContent}
          open={modalAdjustmentLeave}
          onClose={() => this.toggleModalAdjusmentLeave('close')}
        >
          <ModalTitle
            title={<Typography className={classes.modalTitle}>{titleDialog}</Typography>}
            onClose={() => this.toggleModalAdjusmentLeave('close')}
          />

          <form onSubmit={this.handleSubmitAdjustmentLeave}>
            <DialogContent className={classes.dialogContent}>
              <Typography className={classes.modalTitleLeave} style={{marginTop: 8}}>Current Leave Information</Typography>
              <Grid container spacing={2} style={{marginBottom: 4, marginTop: 8}}>
                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    <Grid item xs={4}>
                      <Typography className={classes.valueText}>Total Leave</Typography>
                    </Grid>
                    <Grid item>
                      <Typography className={classes.valueText}>:</Typography>
                    </Grid>
                    <Grid item>
                      <Typography className={classes.modalValueText}>{totalLeave ? `${totalLeave} day(s)` : '-'}</Typography>
                    </Grid>
                  </Grid>

                  <Grid container spacing={2}>
                    <Grid item xs={4}>
                      <Typography className={classes.valueText}>Accumulative Leave</Typography>
                    </Grid>
                    <Grid item>
                      <Typography className={classes.valueText}>:</Typography>
                    </Grid>
                    <Grid item>
                      <Typography className={classes.modalValueText}>{accumulativeLeave ? `${additional} day(s)` : '-'}</Typography>
                    </Grid>
                  </Grid>

                  <Grid container spacing={2}>
                    <Grid item xs={4}>
                      <Typography className={classes.valueText}>Annual Leave</Typography>
                    </Grid>
                    <Grid item>
                      <Typography className={classes.valueText}>:</Typography>
                    </Grid>
                    <Grid item>
                      <Typography className={classes.modalValueText}>{annualLeave ? `${annualLeave} day(s)` : '-'}</Typography>
                    </Grid>
                  </Grid>

                  <Grid container spacing={2}>
                    <Grid item xs={4}>
                      <Typography className={classes.valueText}>Leave Remaining</Typography>
                    </Grid>
                    <Grid item>
                      <Typography className={classes.valueText}>:</Typography>
                    </Grid>
                    <Grid item>
                      <Typography className={classes.modalValueText}>{leaveRemaining ? `${leaveRemaining} day(s)` : '-'}</Typography>
                    </Grid>
                  </Grid>

                </Grid>
              </Grid>

              <Typography className={classes.modalTitleLeave} style={{marginTop: 24}}>Set New Leave</Typography>
              <Typography className={classes.modalSubtitleLeave} style={{marginTop: 8}}>Adjust new leave for this employee by increase or reduce from leave remaining.</Typography>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <MSelect
                    classNameFC={classes.formControl}
                    name="type"
                    value={type}
                    label="Type"
                    onChange={this.handleInputAdjustmentLeave}
                    error={
                      errorMessageAdjustmentLeave && errorMessageAdjustmentLeave.type ?
                        errorMessageAdjustmentLeave.type :
                        undefined
                    }
                    keyPair={['id', 'name']}
                    options={typeOption()}
                  />
                </Grid>
                <Grid item xs={6}>
                  <MInput
                    // disabled={editing ? true : false}
                    classNameFC={classes.formControl}
                    autoFocus
                    name="days"
                    label="Total Days"
                    placeholder="Enter total days"
                    defaultValue={days}
                    onBlur={this.handleInputAdjustmentLeave}
                    error={errorMessageAdjustmentLeave && errorMessageAdjustmentLeave.total_days ? errorMessageAdjustmentLeave.total_days : undefined}
                    fullWidth
                    readonly="readonly"
                    type='number'
                    onKeyDown={(event) => {
                      if (type === 'number') {
                        if (event.key === '.' || event.key === '-' || event.key === 'e' || event.key === ',') {
                          event.preventDefault();
                        }
                      }
                    }}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <MInput
                    classNameFC={classes.formControl}
                    name="description"
                    label="Description"
                    placeholder="Enter description"
                    defaultValue={description}
                    onBlur={this.handleInputAdjustmentLeave}
                    error={
                      errorMessageAdjustmentLeave && errorMessageAdjustmentLeave.description ?
                        errorMessageAdjustmentLeave.description :
                        undefined
                    }
                    rows={2}
                    rowsMax={4}
                    multiline
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions className={classes.dialogFooter}>
              <MButton
                id="close"
                className={classes.cancelBtn}
                label='Close'
                size='small'
                onClick={() => this.toggleModalAdjusmentLeave('close')}
              />
              <MButton
                id="submit"
                className={classes.defaultBtn}
                label={'SAVE'}
                color="primary"
                size='small'
                type="submit"
                icon={<Send />}
                loading={isLoading}
              />
            </DialogActions>
          </form>
        </Dialog>
      </MuiThemeProvider>
    );
  }

  renderModalLeaveDetail = () => {
    const {classes, isLoading} = this.props;
    const {modalLeaveHistoryDetail, errorMessageAdjustmentLeave, timelineAccordion} = this.state;
    const titleDialog = 'Leave Detail';

    const {
      details,
      start_date,
      end_date,
      total_days,
      file,
      approval_history,
      status,
    } = this.state.modalLeaveInformationData;


    const typeOption = () => {
      const options = [
        {id: 'increase', name: 'Increase'},
        {id: 'reduce', name: 'Reduce'},
      ];
      return options;
    };

    const reversed = approval_history ? approval_history.reverse() : [];

    return (
      <MuiThemeProvider theme={this.modalTheme()}>
        <Dialog
          className={classes.dialogContent}
          open={modalLeaveHistoryDetail}
          onClose={() => this.toggleModalLeaveHistory('close', null)}
        >
          <ModalTitle
            title={<Typography className={classes.modalTitle}>{titleDialog}</Typography>}
            onClose={() => this.toggleModalLeaveHistory('close', null)}
          />

          <DialogContent className={classes.dialogContent}>

            {reversed.length > 0 ? (
              <div style={{marginBottom: 24}}>
                <ThemeProviderTimeline theme={this.accordionTheme()}>
                  <Accordion expanded={timelineAccordion} onChange={this.toggleTimelineAccordion} variant='outlined'>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                    >
                      <Typography className={classes.accordionTitle}>
                        Status
                      </Typography>
                      <Typography
                        className={status === 'request' ? classes.statusRequest :
                          status === 'on_going' ? classes.statusOngoing :
                            status === 'rejected' ? classes.statusRejected :
                              status === 'approved' ? classes.statusCompleted : null}
                      >
                        {status === 'request' ? 'Request' :
                          status === 'on_going' ? 'On Going' :
                            status === 'rejected' ? 'Rejected' :
                              status === 'approved' ? 'Approved' : null}
                      </Typography>                </AccordionSummary>
                    <AccordionDetails>
                      <Divider style={{marginBottom: 8}} />
                      <ThemeProvider theme={this.timelineTheme()}>
                        <Timeline
                          sx={{
                            [`& .${timelineItemClasses.root}:before`]: {
                              flex: 0,
                              padding: 0,
                            },
                          }}
                        >
                          {reversed?.map((data, index) => {
                            return (
                              <TimelineItem key={index.toString()}>
                                <TimelineSeparator>
                                  <TimelineDot color={index === 0 && data.status === 'request' ? 'primary' :
                                    index === 0 && data.status === 'on_going' ? 'secondary' :
                                      index === 0 && data.status === 'rejected' ? 'warning' :
                                        index === 0 && data.status === 'approved' ? 'success' : 'grey'} />
                                  {index === reversed?.length - 1 ? (null) : (<TimelineConnector />)}
                                </TimelineSeparator>
                                <TimelineContent>
                                  <div style={{display: 'flex', justifyContent: 'space-between'}}>
                                    <div>
                                      <Typography
                                        className={data.status === 'request' ? classes.statusRequest :
                                          data.status === 'on_going' ? classes.statusOngoing :
                                            data.status === 'rejected' ? classes.statusRejected :
                                              data.status === 'approved' ? classes.statusCompleted : null}
                                      >
                                        {data.status === 'request' ? 'Request' :
                                          data.status === 'on_going' ? 'On Going' :
                                            data.status === 'rejected' ? 'Rejected' :
                                              data.status === 'approved' ? 'Approved' : null}
                                      </Typography>
                                      <Typography
                                        className={classes.timelineDate}
                                      >
                                        {data.status === 'rejected' ? data.notes : null}
                                      </Typography>
                                    </div>
                                    <Typography style={{textTransform: 'capitalize'}} className={classes.timelineDate}>{data ? `${data.name} - ${data.level}` : '-'}</Typography>
                                  </div>
                                </TimelineContent>
                              </TimelineItem>
                            );
                          })}
                        </Timeline>
                      </ThemeProvider>
                    </AccordionDetails>
                  </Accordion>
                </ThemeProviderTimeline>
              </div>
            ) : (<></>)}

            <Typography className={classes.modalTitleLeave} style={{marginTop: 8}}>Leave Information</Typography>
            <Grid container spacing={2} style={{marginBottom: 4, marginTop: 8}}>
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <Grid item xs={4}>
                    <Typography className={classes.valueText}>Status</Typography>
                  </Grid>
                  <Grid item>
                    <Typography className={classes.valueText}>:</Typography>
                  </Grid>
                  <Grid item>
                    <Typography style={{textTransform: 'capitalize'}} className={classes.modalValueText}>{status ? status : '-'}</Typography>
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={4}>
                    <Typography className={classes.valueText}>Start Date</Typography>
                  </Grid>
                  <Grid item>
                    <Typography className={classes.valueText}>:</Typography>
                  </Grid>
                  <Grid item>
                    <Typography className={classes.modalValueText}>{start_date ? convDate(start_date, 'DD/MM/YYYY') : '-'}</Typography>
                  </Grid>
                </Grid>

                <Grid container spacing={2}>
                  <Grid item xs={4}>
                    <Typography className={classes.valueText}>End Date</Typography>
                  </Grid>
                  <Grid item>
                    <Typography className={classes.valueText}>:</Typography>
                  </Grid>
                  <Grid item>
                    <Typography className={classes.modalValueText}>{start_date ? convDate(end_date, 'DD/MM/YYYY') : '-'}</Typography>
                  </Grid>
                </Grid>

                <Grid container spacing={2}>
                  <Grid item xs={4}>
                    <Typography className={classes.valueText}>Leave Duration</Typography>
                  </Grid>
                  <Grid item>
                    <Typography className={classes.valueText}>:</Typography>
                  </Grid>
                  <Grid item>
                    <Typography className={classes.modalValueText}>{total_days ? `${total_days} day(s)` : ''}</Typography>
                  </Grid>
                </Grid>

                <Grid container spacing={2}>
                  <Grid item xs={4}>
                    <Typography className={classes.valueText}>Leave Statement</Typography>
                  </Grid>
                  <Grid item>
                    <Typography className={classes.valueText}>:</Typography>
                  </Grid>
                  <Grid item>
                    <div>
                      <Typography style={{width: 300}} className={classes.modalValueText}>{details ? details : '-'}</Typography>
                    </div>
                  </Grid>
                </Grid>

                <Grid container spacing={2}>
                  <Grid item xs={4}>
                    <Typography className={classes.valueText}>Attachment</Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <div style={{display: 'flex'}}>
                      <Typography className={classes.valueText}>:</Typography>
                      {file ? (
                        <img onClick={() => {
                          window.open(file, '_blank', 'noopener,noreferrer');
                        }} style={{
                          width: '40%',
                          padding: '6px 0px 0px',
                          marginLeft: 10,
                          cursor: 'pointer',
                        }} src={file} alt='hr-img' />
                      ) : (
                        <Typography className={classes.valueText}></Typography>
                      )}
                    </div>
                  </Grid>
                </Grid>

              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions className={classes.dialogFooter}>
            <MButton
              id="close"
              className={classes.cancelBtn}
              label='Close'
              size='small'
              onClick={() => this.toggleModalLeaveHistory('close', null)}
            />
          </DialogActions>
        </Dialog>
      </MuiThemeProvider>
    );
  }

  toggleResetPageUpdateHistory = () => {
    this.setState({resetPageUpdateHistory: true}, () =>
      this.setState({resetPageUpdateHistory: null}),
    );
  };
  toggleResetPageLeaveHistory = () => {
    this.setState({resetPageLeaveHistory: true}, () =>
      this.setState({resetPageLeaveHistory: null}),
    );
  };
  toggleResetPageuserPersonalLeave = () => {
    this.setState({resetPageUserPersonalLeave: true}, () =>
      this.setState({resetPageUserPersonalLeave: null}),
    );
  };

  handleFilterDateChange = (params) => {
    const {value, name} = params;
    this.setState({
      userPersonalLeaveTable: {
        ...this.state.userPersonalLeaveTable,
        [name]: value, page: null,
      },
    });
  }

  handleResetAdjustmentLeave = () => {
    const state = {
      userPersonalLeaveTable: {
        page: 1,
        limit: 10,
        order: 'desc',
        leaveStartDate: null,
        leaveEndDate: null,
      },
    };
    this.setState(state, () => this.fetchUserPersonalLeave());
    // this.toggleResetDatatable(true);
    this.toggleResetPage();
  }

  // toggleResetDatatable = (status) => {
  //   this.setState(
  //       {resetPageLeaveHistory: status},
  //       () => this.setState({resetPageLeaveHistory: null}),
  //   );
  // }

  handleButtonFilter = () => {
    const url_string = window.location.href;
    const url = new URL(url_string);
    const id = url.searchParams.get('id');
    const {page, limit, leaveStartDate, leaveEndDate, sortBy, order} = this.state.userPersonalLeaveTable;

    this.props.getUserPersonalLeave({
      ...(page && {page}),
      ...(limit && {limit}),
      ...(leaveStartDate && {start_date: convDate(leaveStartDate)}),
      ...(leaveEndDate && {end_date: convDate(leaveEndDate)}),
      ...(sortBy && {sort_by: sortBy}),
      ...(order && {order}),
    }, id);
  }

  toggleResetPage = () => {
    this.setState({resetPageLeaveHistory: true, page: 1, limit: 10}, () =>
      this.setState({resetPageLeaveHistory: null}),
    );
  };

  renderNewLeaveMenu = () => {
    const {
      classes,
      updateHistoryData,
      updateHistoryMeta,
      leaveHistoryData,
      leaveHistoryMeta,
      userPersonalLeaveData,
      userPersonalLeaveMeta,
    } = this.props;

    const {
      year,
      annual_leave,
      deductive,
      additional,
      leave_remaining,
      total_leave,
    } = this.state.leaveData;

    const updateHistoryColumn = [
      {name: 'id', label: 'id', display: false},
      {
        name: 'year', label: 'Year', display: true,
        customBodyRender: (value) => {
          return value ? value : '-';
        },
      },
      {
        name: 'remaining', label: 'Leave Remaining', display: true,
        customBodyRender: (value) => {
          return value === 0 ? value : value ? value : '-';
        },
      },
      {
        name: 'annual_leave', label: 'Annual Leave', display: true,
        customBodyRender: (value) => {
          return value === 0 ? value : value ? value : '-';
        },
      },
      {
        name: 'total_leave', label: 'Total Leave', display: true,
        customBodyRender: (value) => {
          return value === 0 ? value : value ? value : '-';
        },
      },
      {
        name: 'taken', label: 'Leave Taken', display: false,
        customBodyRender: (value) => {
          return value === 0 ? value : value ? value : '-';
        },
      },
      {
        name: 'update', label: 'Update', display: true,
        customBodyRender: (value) => {
          return value ? convDate(value, 'DD-MM-YYYY') : '-';
        },
      },
    ];

    const leaveHistoryColumn = [
      {name: 'id', label: 'id', display: false},
      {
        name: 'details', label: 'Leave Reason', display: true,
        customBodyRender: (value, rowData) => {
          return (
            <div style={{}}>
              <Typography style={{fontSize: 13}}>
                {value ? value : '-'}
              </Typography>
              <Typography onClick={() => this.toggleModalLeaveHistory('open', rowData.rowData)} className={classes.guideLinkPopup} color='primary'>Lihat Detail</Typography>
            </div>
          );
        },
      },
      {
        name: 'start_date', label: 'Start Date', display: true,
        customBodyRender: (value) => {
          return value ? convDate(value, 'DD-MM-YYYY') : '-';
        },
      },
      {
        name: 'end_date', label: 'End Date', display: true,
        customBodyRender: (value) => {
          return value ? convDate(value, 'DD-MM-YYYY') : '-';
        },
      },
      {
        name: 'total_days', label: 'Duration', display: true,
        customBodyRender: (value) => {
          return value ? `${value} Day(s)` : '-';
        },
      },
      {
        name: 'file', label: 'Image', display: false,
        customBodyRender: (value) => {
          return value ? value : '-';
        },
      },
      {
        name: 'approval_history', label: 'History', display: false,
        customBodyRender: (value) => {
          return value ? value : '-';
        },
      },
      {
        name: 'status', label: 'Status', display: false,
        customBodyRender: (value) => {
          return value ? value : '-';
        },
      },
    ];

    const userPersonalLeaveColumn = [
      {name: 'id', label: 'id', display: false},
      {
        name: 'created_at', label: 'Created Date', display: true,
        customBodyRender: (value) => {
          return value ? convDate(value, 'DD-MM-YYYY') : '-';
        },
      },
      {
        name: 'description', label: 'Description', display: true,
        customBodyRender: (value) => {
          return (
            <div style={{}}>
              <Typography style={{fontSize: 13}}>
                {value ? value : '-'}
              </Typography>
            </div>
          );
        },
      },
      {
        name: 'total_days', label: 'Total', display: true,
        customBodyRender: (value) => {
          return value && value > 0 ? `${value}` : value && value < 0 ? `${value}` : '-';
        },
      },
      {
        name: 'leave_remaining', label: 'Leave Remaining', display: true,
        customBodyRender: (value) => {
          return value ? value : '-';
        },
      },
    ];


    const {isLoadingUpdateHistory, isLoadingLeaveHistory, isLoadingUserPersonalLeave} = this.props;
    const {errorMessage, resetPageUpdateHistory, resetPageLeaveHistory, resetPageUserPersonalLeave, isMediaScreenMedium} = this.state;
    const {leaveStartDate, leaveEndDate} = this.state.userPersonalLeaveTable;
    return (
      <MuiThemeProvider theme={this.formTheme()}>
        <div>
          <form onSubmit={this.handleSubmitLeave} autoComplete="off">
            <Grid container spacing={2} className={classes.titleStyle}>
              <div style={{display: 'flex', justifyContent: 'space-between', flexWrap: isMediaScreenMedium ? 'wrap' : 'nowrap'}}>
                <Typography className={classes.leaveMenuTitle} style={{marginBottom: isMediaScreenMedium ? 8 : 0}}>Leave Configuration</Typography>
              </div>
              <MButton
                style={{marginLeft: 18}}
                className={classes.defaultBtn}
                label={'ADJUSTMENT LEAVE'}
                onClick={() => this.toggleModalAdjusmentLeave('open')}
                loading={this.props.isLoading}
              />
            </Grid>
            {/* <Divider style={{marginTop: 24, marginBottom: 24}} /> */}

            <Datatable
              hideActions={true}
              title={'Update General History'}
              loading={isLoadingUpdateHistory}
              dataSource={updateHistoryData}
              total={updateHistoryMeta ? updateHistoryMeta.total : 0}
              page={updateHistoryMeta ? updateHistoryMeta.page : 1}
              columns={updateHistoryColumn}
              handleReload={(params) => this.handleReloadUpdateHistory(params)}
              toggleResetPage={resetPageUpdateHistory}
              // dataSource={dummyUpdate}
              // total={dummyUpdate ? dummyUpdate.length : 0}
              // page={1}

              // handleReload={(params) => this.handleReloadUpdateHistory(params)}
              creatable={false}
            // handleEdit={(data) => this.handleFormToggle('edit', data)}
            // handleSearch={(params) => this.handleSearch(params)}
            // customActions={(data) => this.renderActions(data)}
            />

            <Grid container spacing={'auto'} style={{display: 'flex', justifyContent: isMediaScreenMedium ? 'space-between' : 'end', flexWrap: isMediaScreenMedium ? 'wrap' : 'nowrap'}}>
              <Grid item xs={isMediaScreenMedium ? true : false}>
                <MKeyBoardDatepicker
                  className={classes.filterDatePicker}
                  name="leaveStartDate"
                  label="Start Date"
                  value={leaveStartDate}
                  onDateChange={this.handleFilterDateChange}
                  placeholder="dd-mm-yyyy"
                  maxDate={leaveEndDate ? new Date(leaveEndDate) : undefined}
                  // error={message && message.start_date ? message.start_date : undefined}
                  error={
                    this.state.leaveStartDate === null && this.state.leaveEndDate !== null ? 'Start Date is required' :
                      leaveStartDate === null && this.state.errorFilterDate ? 'Start Date is required' :
                        undefined
                  }
                />
              </Grid>
              <Grid item xs={isMediaScreenMedium ? true : false}>
                <MKeyBoardDatepicker
                  style={{marginLeft: 18}}
                  className={classes.filterDatePicker}
                  name="leaveEndDate"
                  label="End Date"
                  value={leaveEndDate}
                  onDateChange={this.handleFilterDateChange}
                  placeholder="dd-mm-yyyy"
                  minDate={new Date(leaveStartDate)}
                  // error={message && message.end_date ? message.end_date : undefined}space-between
                  error={
                    this.state.leaveStartDate !== null && this.state.leaveEndDate === null ? 'End Date is required' :
                      leaveEndDate === null && this.state.errorFilterDate ? 'End Date is required' :
                        undefined
                  }
                />
              </Grid>
              <Grid item xs={isMediaScreenMedium ? true : false} style={{display: 'flex', justifyContent: 'space-between'}}>
                <MButton
                  style={{marginLeft: 18}}
                  className={classes.filterBtnLeave}
                  label="Apply"
                  color="primary"
                  onClick={this.handleButtonFilter}
                />
                <MButton
                  className={classes.filterBtnLeave}
                  label="Reset"
                  color="primary"
                  icon={<Refresh />}
                  onClick={this.handleResetAdjustmentLeave}
                />
              </Grid>
            </Grid>
            <Datatable
              hideActions={true}
              title={'Adjustment Leave History'}
              loading={isLoadingUserPersonalLeave}
              dataSource={userPersonalLeaveData}
              total={userPersonalLeaveMeta ? userPersonalLeaveMeta.total : 0}
              page={userPersonalLeaveMeta ? userPersonalLeaveMeta.page : 1}
              columns={userPersonalLeaveColumn}
              handleReload={(params) => this.handleReloadUserPersonalLeave(params)}
              toggleResetPage={resetPageLeaveHistory}
              // dataSource={dummyUpdate}
              // total={dummyUpdate ? dummyUpdate.length : 0}
              // page={1}

              // handleReload={(params) => this.handleReloadUpdateHistory(params)}
              creatable={false}
            // handleEdit={(data) => this.handleFormToggle('edit', data)}
            // handleSearch={(params) => this.handleSearch(params)}
            // customActions={(data) => this.renderActions(data)}
            />

            <Datatable
              hideActions={true}
              title={'Leave History'}
              loading={isLoadingLeaveHistory}
              dataSource={leaveHistoryData}
              total={leaveHistoryMeta ? leaveHistoryMeta.total : 0}
              page={leaveHistoryMeta ? leaveHistoryMeta.page : 1}
              columns={leaveHistoryColumn}
              handleReload={(params) => this.handleReloadLeaveHistory(params)}
              toggleResetPage={resetPageUserPersonalLeave}
              // dataSource={dummyUpdate}
              // total={dummyUpdate ? dummyUpdate.length : 0}
              // page={1}

              // handleReload={(params) => this.handleReloadUpdateHistory(params)}
              creatable={false}
            // handleEdit={(data) => this.handleFormToggle('edit', data)}
            // handleSearch={(params) => this.handleSearch(params)}
            // customActions={(data) => this.renderActions(data)}
            />

          </form>
        </div>
      </MuiThemeProvider>

    );
  }

  render() {
    const {classes, isLoading, userData, userMeta, orgStatusData, isLoadingOrg, isSuperAdmin, menusData} = this.props;
    const displayJobTitle = this.props.isSuperAdmin ? false : true;
    const {resetDatatable, resetPage, advantech, profileStatus, contractStatus, contractEndDate} = this.state;
    return (
      <div className={classes.root}>
        {this.renderModalCreateDivision()}
        {this.renderApproalTypeGuide()}
        {this.renderNavigationPaper()}
        {this.renderNoActiveContract()}
        {this.renderUserInformation()}
        {this.renderModalDetailContract()}
        {this.renderModalEditContract()}
        {this.renderModalAdjustmentLeave()}
        {this.renderModalLeaveDetail()}
      </div>
    );
  }
}

const stateToProps = ({userManagement, jobTitle, upgradeAccount, auth, organization, xendit, generalSetting, divisionManagement}) => ({
  token: auth.data.authorization,
  isSuperAdmin: checkSuperadmin(auth),

  isLoading: userManagement.isLoading,
  isError: userManagement.isError,
  isSuccess: userManagement.isSuccess,
  message: userManagement.message,
  trigger: userManagement.trigger,
  userData: userManagement.userData,
  userMeta: userManagement.userMeta,
  roleLoading: userManagement.roleLoading,
  roleData: userManagement.roleData,
  managerData: userManagement.managerData,
  oneManagerData: userManagement.oneManagerData,
  isLoadingManager: userManagement.isLoadingManager,
  employeeDetailData: userManagement.employeeDetailData,
  isSuccessGet: userManagement.isSuccessGet,
  isErrorGet: userManagement.isErrorGet,
  triggerGet: userManagement.triggerGet,
  messageGet: userManagement.messageGet,

  isSuccessUpdate: userManagement.isSuccessUpdate,
  isErrorUpdate: userManagement.isErrorUpdate,

  jobLoading: jobTitle.jobLoading,
  jobData: jobTitle.jobDataByOrg,

  data: generalSetting.data,
  menusData: auth.data.menus,

  isLoadingDivision: divisionManagement.isLoading,
  isErrorDivision: divisionManagement.isError,
  isSuccessDivision: divisionManagement.isSuccess,
  divisionMessage: divisionManagement.message,
  triggerDivision: divisionManagement.trigger,
  divisionDataByOrg: divisionManagement.divisionDataByOrg,
  divisionData: divisionManagement.divisionData,
  divisionMeta: divisionManagement.divisionMeta,

  bankData: userManagement.bankData,
  isSuccessBank: userManagement.isSuccessBank,
  isErrorBank: userManagement.isErrorBank,
  triggerBank: userManagement.triggerBank,
  messageBank: userManagement.messageBank,

  isLoadingContract: userManagement.isLoadingContract,
  isErrorContract: userManagement.isErrorContract,
  triggerContract: userManagement.triggerContract,
  messageContract: userManagement.messageContract,
  contractData: userManagement.contractData,
  contractMeta: userManagement.contractMeta,

  updateHistoryData: userManagement.updateHistoryData,
  updateHistoryMeta: userManagement.updateHistoryMeta,
  leaveHistoryData: userManagement.leaveHistoryData,
  leaveHistoryMeta: userManagement.leaveHistoryMeta,
  userPersonalLeaveData: userManagement.userPersonalLeaveData,
  userPersonalLeaveMeta: userManagement.userPersonalLeaveMeta,
  isLoadingLeave: userManagement.isLoadingLeave,
  isSuccessLeave: userManagement.isSuccessLeave,
  isLoadingLeaveHistory: userManagement.isLoadingLeaveHistory,
  isErrorLeaveHistory: userManagement.isErrorLeaveHistory,
  isLoadingUpdateHistory: userManagement.isLoadingUpdateHistory,
  isErrorUpdateHistory: userManagement.isErrorUpdateHistory,
  isLoadingUserPersonalLeave: userManagement.isLoadingUserPersonalLeave,
  isErrorUserPersonalLeave: userManagement.isErrorUserPersonalLeave,


  isLoadingAddAdjustment: userManagement.isLoadingAddAdjustment,
  isErrorAddAdjustment: userManagement.isErrorAddAdjustment,
  isSuccessAddAdjustment: userManagement.isSuccessAddAdjustment,
  triggerAddAdjustment: userManagement.triggerAddAdjustment,
  messageAddAdjustment: userManagement.messageAddAdjustment,

  employeeTypeList: userManagement.employeeTypeList,
  isSuccessEmployeeType: userManagement.isSuccessEmployeeType,
  triggerEmployeeType: userManagement.triggerEmployeeType,

  generalSettingData: generalSetting.data,
  generalSettingIsLoading: generalSetting.isLoading,
  generalSettingIsSuccess: generalSetting.isSuccess,
  generalSettingIsError: generalSetting.isError,
  generalSettingMessage: generalSetting.message,
  generalSettingTrigger: generalSetting.trigger,

  userStatusData: userManagement.menus.shift,
});

const dispatchToProps = (dispatch) => ({
  getEmployeeDetail: (id) => dispatch(getEmployeeDetail(id)),
  getOneManager: (id) => dispatch(getOneManager(id)),
  getSingleManager: (id) => dispatch(getSingleManager(id)),

  updatePersonalData: (id, params) => dispatch(updatePersonalData(id, params)),
  updateEmploymentData: (id, params) => dispatch(updateEmploymentData(id, params)),
  updateRemoteData: (id, params) => dispatch(updateRemoteData(id, params)),
  updateAccountData: (id, params) => dispatch(updateAccountData(id, params)),
  deleteContractUser: (id) => dispatch(deleteContractUser(id)),
  getAllRole: () => dispatch(getAllRole()),
  checkPremium: () => dispatch(checkPremium()),
  clearState: () => dispatch(clearState()),
  getJobTitleByOrg: (params) => dispatch(getJobTitleByOrg(params)),
  checkStatus: () => dispatch(checkStatus()),
  checkStatusUser: () => dispatch(checkStatusUser()),
  getDivisionByOrg: (params) => dispatch(getDivisionByOrg(params)),
  createDivision: (params) => dispatch(createDivision(params)),
  clearStateDivision: () => dispatch(clearState()),

  getAllManager: (params) => dispatch(getAllManager(params)),

  getBankList: (params) => dispatch(getBankList(params)),

  getContractUser: (params) => dispatch(getContractUser(params)),
  updateContractUser: (id, params) => dispatch(updateContractUser(id, params)),
  addContractUser: (params) => dispatch(addContractUser(params)),
  updateContractStatus: (id, params) => dispatch(updateContractStatus(id, params)),
  updateEmployeeStatus: (id, params) => dispatch(updateEmployeeStatus(id, params)),
  updateDeductedLeave: (id, params) => dispatch(updateDeductedLeave(id, params)),

  getUpdateHistory: (params, id) => dispatch(getUpdateHistory(params, id)),
  getLeaveHistory: (params, id) => dispatch(getLeaveHistory(params, id)),
  getUserPersonalLeave: (params, id) => dispatch(getUserPersonalLeave(params, id)),
  createAdjustmentLeave: (params) => dispatch(createAdjustmentLeave(params)),

  // getListEmployeeType: (params) => dispatch(getListEmployeeType(params)),

  getGeneralSetting: (key) => dispatch(getGeneralSetting(key)),
});

export default connect(
  stateToProps,
  dispatchToProps,
)(withStyles(styles)(EmployeeDetail));
