/* eslint-disable max-len */
import {ATTENDANCE} from '../../services';
import {ATTENDANCE_STATE as STATE} from './attendance.types';
import {transformObject, isArray} from '../../helpers';

export const getAttendanceHistory = (params) => {
  return (dispatch) => {
    dispatch(request(STATE.FETCH));
    ATTENDANCE.getAttendanceHistory(params)
        .then(
            (response) => {
              dispatch(requestSuccess(STATE.FETCH_SUCCESS, response.data));
            },
            (error) => {
              const errors = error.response.data.data.errors;
              const message = isArray(errors) ? transformObject(errors) : error.response.data.data.message;
              dispatch(requestFailed(STATE.FETCH_FAILED, message));
            },
        );
  };
};

export const getAttendanceDetail = (params) => {
  return (dispatch) => {
    dispatch(request(STATE.FETCH_DETAIL, true));
    ATTENDANCE.getAttendanceDetail(params)
        .then(
            (response) => {
              dispatch(requestSuccess(STATE.FETCH_DETAIL_SUCCESS, response.data.data));
            },
            (error) => {
              const response = error.response.data;
              dispatch(requestFailed(STATE.FETCH_DETAIL_FAILED, response.data.message));
            },
        );
  };
};

export const exportExcel = (params) => {
  return (dispatch) => {
    dispatch(request(STATE.EXPORT, true));
    ATTENDANCE.exportExcel(params)
        .then(
            (response) => {
              dispatch(requestSuccess(STATE.EXPORT_SUCCESS, response.data.data));
            },
            (error) => {
              const errors = error.response.data.data.errors;
              const message = isArray(errors) ? transformObject(errors) : error.response.data.data.message;
              dispatch(exportFailed(STATE.EXPORT_FAILED, message));
            },
        );
  };
};

const request = (requestType, custom = false) => ({
  type: custom ? requestType : STATE.REQUEST,
  requestType,
});

const requestFailed = (requestType, response) => ({
  type: STATE.REQUEST_FAILED,
  requestType,
  payload: response,
});

const requestSuccess = (requestType, response) => ({
  type: requestType,
  payload: response,
});

const exportFailed = (requestType, response) => ({
  type: requestType,
  payload: response,
});
