import React, { Component, createRef } from "react";
import { connect } from "react-redux";
import {
  withStyles,
  Typography,
  Button,
  Tooltip,
  MuiThemeProvider,
  Dialog,
  DialogContent,
  Grid,
  createTheme as createMuiTheme,
} from "@material-ui/core";
import { Paper } from '@mui/material'
import {
  Send,
  Save,
  Cancel,
} from "@material-ui/icons";
import styles from "../styles";
import {
  MButton,
  MInput,
  ModalTitle,
  MSelect,
} from "../../../../components/form";
import AutocompleteMultiselect from "../../../../components/form/select-multiple";
import {
  allBlastingNotif,
  createBlastingNotif,
  detailBlastingNotif,
  organizationsBLastingNotif,
  updateBlastingNotif,
  usersBLastingNotif,
} from "../../../../redux/blastingNotif/blastingNotif.action";
import { debounce } from "lodash";
import { Datatable } from "../../../../components";
import { Edit, ScheduleSend, Search } from "@mui/icons-material";
import { convDate } from "../../../../helpers";

class BlastingNotif extends Component {
  constructor(props) {
    super(props);
    this.state = {
      blasting_id: null,
      is_detail: false,
      formData: {
        subject: "",
        message: "",
        target_type: "all",
        user_ids: [],
        organization_ids: [],
        notification_type: ["push_notif"],
        schedule_type: "now",
        schedule_date_time: null,
        status: "sent",
      },
      filterState: {
        page: 1,
        limit: 10,
        search: ''
      },
      filterDatatable: {
        page: 1,
        limit: 10,
      },
      users: [],
      organizations: [],
      blastingData: [],
      blastingMeta: {
        total: 10,
        totalPages: 1,
        page: "1",
        perPage: "10"
      }
    };
  }

  paperRef = createRef()
  scrollPositionRef = createRef(0)
  lastPageRef = createRef(1)

  handleInputChange = (field, value) => {
    this.setState((prevState) => ({
      formData: {
        ...prevState.formData,
        [field]: value,
      },
    }));
  };

  handleFilterState = (field, value) => {
    this.setState((prevState) => ({
      filterState: {
        ...prevState.filterState,
        [field]: (prevState)
      }
    }))
  }

  handleScroll = (page, type) => {
    if (type === "user") {
      if (this.state.filterState.page < this.props?.usersBlasting?.meta?.totalPages) {
        this.setState(prevState => ({
          ...prevState,
          filterState: {
            ...prevState.filterState,
            page
          }
        }))
        this.props.usersBLastingNotif({page: page, limit: 10, search: this.state.filterState.search})
      }
    }

    if (type === 'organization') {
      if (this.state.filterState.page && this.props?.organizationsBlasting?.meta?.totalPages > 0) {
        this.setState(prevState => ({
          ...prevState,
          filterState: {
            ...prevState.filterState,
            page
          }
        }))
        this.props.organizationsBLastingNotif({page: page, limit: 10, search: this.state.filterState.search})
      }
    }
  }

  appendDataUsers = () => {
    this.setState(prevState => ({
      ...prevState,
      users: [...prevState.users, ...this.props.usersBlasting?.data]
    }))
  }

  appendDataOrganizations = () => {
    this.setState(prevState => ({
      ...prevState,
      organizations: [...prevState.organizations, ...this.props.organizationsBlasting?.data]
    }))
  }

  fetchDatatable = () => {
    const {page, limit} = this.state.filterDatatable;
    this.props.allBlastingNotif({
      ...(page && {page}),
      ...(limit && {limit}),
    })
  }

  fetchDetail = (id, isDetail) => {
    this.props.detailBlastingNotif(id)

    if (isDetail === true) {
      this.setState((prevState) => ({
        ...prevState,
        is_detail: isDetail,
      }))
    }
  }

  componentWillReceiveProps(next) {
    if (next.isSuccess && next.isSuccess !== this.props.isSuccess) {
      this.fetchDatatable()
      this.setState((prevState) => ({
        ...prevState,
        blasting_id: null,
        formData: {
          subject: "",
          message: "",
          target_type: "",
          user_ids: [],
          organization_ids: [],
          notification_type: ["push_notif"],
          schedule_type: "now",
          schedule_date_time: null,
          status: "sent",
        }
      }))
    }
  }

  UNSAFE_componentWillMount() {
    this.fetchDatatable();
  }

  componentDidUpdate(prevProps, prevState) {
    const { usersBlasting, organizationsBlasting, blastingDetail, blastingData, blastingMeta } = this.props;
    const { is_detail, filterDatatable } = this.state;

    if(prevProps.usersBlasting?.data !== usersBlasting?.data) {
      this.appendDataUsers()
    }

    if(prevProps.organizationsBlasting?.data !== organizationsBlasting?.data) {
      this.appendDataOrganizations()
    }

    if(this.state.filterState.page > 1 && this.state.filterState.page > this.lastPageRef.current) {
      this.lastPageRef.current = this.state.filterState.page
    }

    if (prevProps.blastingDetail !== blastingDetail) {
      if (is_detail === false) {
        const newForm = {}
        newForm['subject'] = blastingDetail.subject
        newForm['message'] = blastingDetail.message
        newForm['target_type'] = blastingDetail.target_type
        newForm['schedule_type'] = blastingDetail.schedule_type
        newForm['status'] = blastingDetail.status

        if (blastingDetail.target_type === 'user') {
          this.props.usersBLastingNotif({ page: 1, limit: 10 });
          newForm['user_ids'] = blastingDetail.user_data.map((e) => {return { id: e.id, name: e.name }});
          newForm['organization_ids'] = undefined;
        }
  
        if (blastingDetail.target_type === 'organization') {
          this.props.organizationsBLastingNotif({ page: 1, limit: 10 });
          newForm['user_ids'] = undefined;
          newForm['organization_ids'] = blastingDetail.organizations.map((e) => {return { id: e.id, name: e.name }});
        }
  
        if (blastingDetail.schedule_type !== 'now') {
          newForm['schedule_date_time'] = blastingDetail.schedule_date_time;
        }
  
        this.setState((prevState) => ({
          ...prevState,
          blasting_id: blastingDetail.id,
          formData: {
            ...prevState.formData,
            ...newForm
          },
        }))
      }
    }

    if (prevProps.blastingData !== blastingData) {
      this.setState((prevState) => ({
        ...prevState,
        blastingData: blastingData,
        blastingMeta: blastingMeta,
      }))
    }

    if (prevState.filterDatatable !== filterDatatable) {
      this.fetchDatatable()
    }
  }

  handleReload = (params) => {
    this.setState(
      (prevState) => ({
        ...prevState,
        filterDatatable: {
          ...prevState.filterDatatable,
          page: params.page,
          limit: params.limit,
        }
      })
    );
  }

  handleSubmit = (status) => {
    const { blasting_id, formData } = this.state
    let payload = {
      ...formData,
      status
    }

    if (formData.target_type === 'user') {
      payload.user_ids = formData.user_ids.map(user => user.id)
      delete payload.organization_ids
    } else if (formData.target_type === 'organization') {
      payload.organization_ids = formData.organization_ids.map(organization => organization.id)
      delete payload.user_ids
    } else if (formData.target_type === 'all') {
      delete payload.organization_ids
      delete payload.user_ids
    }

    if (formData.schedule_type === 'now') {
      delete payload.schedule_date_time
    }

    if (!blasting_id) {
      this.props.createBlastingNotif(payload)
    } else {
      this.props.updateBlastingNotif(blasting_id, payload)
    }
  };

  dialogTheme = () => createMuiTheme({
    palette: {
      primary: {
        main: '#00AFF0',
      },
    },
    overrides: {
      MuiDialogTitle: {
        root: {
          display: 'flex',
          justifyContent: 'center',
        },
      },
    },
  })

  renderModal = () => {
    const { is_detail } = this.state;
    const { classes, blastingDetail } = this.props;

    if (!blastingDetail) return null;

    let target = ''
    let schedule = ''

    switch (blastingDetail.target_type) {
      case 'user':
        target = 'Specific Users';
        break;
      case 'organization':
        target = 'Organizations';
        break;
      default:
        target = 'All Users';
        break;
    }

    switch (blastingDetail.schedule_type) {
      case 'now':
        schedule = 'Now';
        break;
      default:
        schedule = 'Scheduled';
        break;
    }

    return (
      <MuiThemeProvider theme={this.dialogTheme()}>
        <Dialog
          open={is_detail}
          onClose={() => {
            this.setState(prevState => ({
              ...prevState,
              is_detail: false,
            }))
          }}
          maxWidth='md'
          fullWidth={true}
          scroll='paper'
          PaperProps={{
            style: {borderRadius: 15, paddingTop: 20, paddingBottom: 20},
          }}
        >
          <ModalTitle
            className={classes.modalTitle}
            title="Blasting Notification Detail"
            onClick={() => {
              this.setState(prevState => ({
                ...prevState,
                is_detail: false,
              }))
            }}
            style={{display: 'flex', alignSelf: 'center'}}
          />
          <DialogContent className={classes.dialogContent}>
            <div>
              <Grid container wrap="nowrap" spacing={2}>
                <Grid item xs={2}><Typography>Subject </Typography></Grid>
                <Grid item><Typography> : </Typography></Grid>
                <Grid item xs zeroMinWidth><Typography>{blastingDetail.subject}</Typography></Grid>
              </Grid>
              <Grid container wrap="nowrap" spacing={2}>
                <Grid item xs={2}><Typography>Message </Typography></Grid>
                <Grid item><Typography> : </Typography></Grid>
                <Grid item xs zeroMinWidth><Typography>{blastingDetail.message}</Typography></Grid>
              </Grid>
              <Grid container wrap="nowrap" spacing={2}>
                <Grid item xs={2}><Typography>Target Type </Typography></Grid>
                <Grid item><Typography> : </Typography></Grid>
                <Grid item xs zeroMinWidth><Typography>{target}</Typography></Grid>
              </Grid>
              {blastingDetail.target_type === 'user' ? (
                <Grid container wrap="nowrap" spacing={2}>
                  <Grid item xs={2}><Typography>Users </Typography></Grid>
                  <Grid item><Typography> : </Typography></Grid>
                  <Grid item xs zeroMinWidth><Typography>{
                    blastingDetail.user_data.map((e) => {return e.name}).join(', ')
                  }</Typography></Grid>
                </Grid>
              ) : blastingDetail.target_type === 'organization' ? (
                <Grid container wrap="nowrap" spacing={2}>
                  <Grid item xs={2}><Typography>Organizations </Typography></Grid>
                  <Grid item><Typography> : </Typography></Grid>
                  <Grid item xs zeroMinWidth><Typography>{
                    blastingDetail.organizations.map((e) => {return e.name}).join(', ')
                  }</Typography></Grid>
                </Grid>
              ) : null}
              <Grid container wrap="nowrap" spacing={2}>
                <Grid item xs={2}><Typography>Notification Type </Typography></Grid>
                <Grid item><Typography> : </Typography></Grid>
                <Grid item xs zeroMinWidth><Typography>{
                  JSON
                    .parse(blastingDetail.notification_type)
                    .map((e) => {
                      switch (e) {
                        case 'push_notif':
                          return 'Push Notification'
                        default:
                          return '-'
                      }
                    })
                    .join(', ')
                }</Typography></Grid>
              </Grid>
              <Grid container wrap="nowrap" spacing={2}>
                <Grid item xs={2}><Typography>Schedule Type </Typography></Grid>
                <Grid item><Typography> : </Typography></Grid>
                <Grid item xs zeroMinWidth><Typography>{schedule}</Typography></Grid>
              </Grid>
              {blastingDetail.schedule_type !== 'now' && blastingDetail.schedule_date_time !== null ? (
                <Grid container wrap="nowrap" spacing={2}>
                  <Grid item xs={2}><Typography>Schedule Date & Time </Typography></Grid>
                  <Grid item><Typography> : </Typography></Grid>
                  <Grid item xs zeroMinWidth><Typography>{convDate(blastingDetail.schedule_date_time, 'DD-MM-YYYY HH:mm:ss')} </Typography></Grid>
                </Grid>
              ) : null}
              <Grid container wrap="nowrap" spacing={2}>
                <Grid item xs={2}><Typography>Status </Typography></Grid>
                <Grid item><Typography> : </Typography></Grid>
                <Grid item xs zeroMinWidth><Typography>{blastingDetail.status}</Typography></Grid>
              </Grid>
            </div>
          </DialogContent>
        </Dialog>
      </MuiThemeProvider>
    );
  }

  renderForm = () => {
    const { classes } = this.props;
    const { formData, blasting_id, is_detail } = this.state;

    const targetOptions = [
      { id: "all", name: "All Users" },
      { id: "user", name: "Specific Users" },
      { id: "organization", name: "Organizations" },
    ];

    const scheduleOptions = [
      { id: "now", name: "Send Now" },
      { id: "scheduled", name: "Schedule for Later" },
    ];

    return (
      <div>
        <Typography className={classes.paperTitle}>
        {`${!is_detail ? !blasting_id ? 'Create' : 'Update' : 'Create'} Blasting Notifications`}
        </Typography>
        <MInput
          classNameFC={classes.formControl}
          name="subject"
          label="Subject *"
          placeholder="Enter the subject"
          value={formData.subject}
          onChange={(e) => this.handleInputChange("subject", e.target.value)}
          autoComplete="off"
        />
        <MInput
          classNameFC={classes.formControl}
          name="message"
          label="Message *"
          placeholder="Enter the message content"
          value={formData.message}
          onChange={(e) => this.handleInputChange("message", e.target.value)}
          autoComplete="off"
          multiline
          rows={5}
          rowsMin={5}
          rowsMax={20}
        />
        <div spacing={2} style={{display: 'flex', gap: '12px'}}>
          <div xs={6} style={{width: '50%'}}>
            <MSelect
              classNameFC={classes.formControl}
              name="target_type"
              label="Target Type"
              keyPair={["id", "name"]}
              options={targetOptions}
              value={!formData.target_type ? 'all' : formData.target_type}
              onChange={(e) => {
                if (e.target.value === "user") {
                  this.props.usersBLastingNotif({ page: 1, limit: 10 });
                } else if (e.target.value === "organization") {
                  this.props.organizationsBLastingNotif({ page: 1, limit: 10 });
                }
                this.handleInputChange("target_type", e.target.value);
              }}
            />
            {formData.target_type === "user" && <AutocompleteMultiselect
              name="user_ids"
              label="Users"
              placeholder="Select Users"
              options={this.state.users}
              value={formData.user_ids}
              scrollToNextPage={(page) => this.handleScroll(page, "user")}
              onInputChange={debounce(({page, search, limit}) => {
                this.setState(prevState => ({
                  ...prevState,
                  users: [],
                  filterState: {
                    ...prevState.filterState,
                    page: 1
                  }
                }))
                this.props.usersBLastingNotif({ page: 1, search, limit});
              }, 1000)}
              onChange={(ids) => {
                  this.setState((prevState) => ({
                    ...prevState,
                    formData: {
                      ...prevState.formData,
                      user_ids: ids
                    }
                  }
                ))
              }}
            />}
            {formData.target_type === "organization" && <AutocompleteMultiselect
              name="organization_ids"
              label="Organizations"
              placeholder="Select Organizations"
              options={this.state.organizations}
              value={formData.organization_ids}
              scrollToNextPage={(page) => this.handleScroll(page, "organization")}
              onInputChange={debounce(({page, search, limit}) => {
                this.setState(prevState => ({
                  ...prevState,
                  organizations: [],
                  filterState: {
                    ...prevState.filterState,
                    page: 1
                  }
                }))
                this.props.organizationsBLastingNotif({ page: 1, search, limit});
              }, 1000)}
              onChange={(ids) => {
                  this.setState((prevState) => ({
                    ...prevState,
                    formData: {
                      ...prevState.formData,
                      organization_ids: ids
                    }
                  }
                ))
              }}
            />}
          </div>
          <div xs={6} style={{width: '50%'}}>
            <MSelect
              classNameFC={classes.formControl}
              name="schedule_type"
              label="Schedule Type *"
              keyPair={["id", "name"]}
              options={scheduleOptions}
              value={!formData.schedule_type ? 'now' : formData.schedule_type}
              onChange={(e) =>
                this.handleInputChange("schedule_type", e.target.value)
              }
            />
            {formData.schedule_type === "scheduled" && (
              <MInput
                classNameFC={classes.formControl}
                name="schedule_date_time"
                label="Schedule Date & Time"
                type="datetime-local"
                value={formData.schedule_date_time || ""}
                onChange={(e) =>
                  this.handleInputChange("schedule_date_time", e.target.value)
                }
              />
            )}
          </div>
        </div>
      </div>
    );
  };

  

  renderActions = (params) => {
    const detail = (
      <div style={{display: 'inline', marginRight: '5px'}}>
        <Tooltip title="Detail">
          <Button
            variant="contained"
            size="small"
            style={{
              'backgroundColor': '#00aff0',
              'boxShadow': 'none',
              'color': '#fff',
              '&:hover': {
                boxShadow: 'none',
                backgroundColor: '#D09F53',
              },
              'minWidth': '10px',
              'paddingRight': '0px',
            }}
            onClick={() => this.fetchDetail(params[1], true)}
            startIcon={<Search />}>
          </Button>
        </Tooltip>
      </div>
    );
    const edit = (
      <div style={{display: 'inline', marginRight: '5px'}}>
        <Tooltip title="Edit">
          <Button
            variant="contained"
            style={{
              'backgroundColor': '#008000',
              'boxShadow': 'none',
              'color': '#fff',
              '&:hover': {
                boxShadow: 'none',
                backgroundColor: '#007000',
              },
              'minWidth': '10px',
              'paddingRight': '0px',
            }}
            size="small"
            onClick={() => this.fetchDetail(params[1], false)}
            startIcon={<Edit />}
          >
          </Button>
        </Tooltip>
      </div>
    );

    const canceled = (
      <div style={{display: 'inline', marginRight: '5px'}}>
        <Tooltip title="Canceled">
          <Button
            variant="contained"
            size="small"
            style={{
              'backgroundColor': '#ff5733',
              'boxShadow': 'none',
              'color': '#fff',
              '&:hover': {
                boxShadow: 'none',
                backgroundColor: '#D09F53',
              },
              'minWidth': '10px',
              'paddingRight': '0px',
            }}
            onClick={() => this.props.updateBlastingNotif(params[1], {status: 'canceled'})}
            startIcon={<Cancel />}>
          </Button>
        </Tooltip>
      </div>
    );

    switch (params[6]) {
      case 'waiting':
        return (
          <div style={{whiteSpace: 'nowrap'}}>
            {detail}
            {canceled}
          </div>
        );
      case 'draft':
        return (
          <div style={{whiteSpace: 'nowrap'}}>
            {detail}
            {edit}
            {canceled}
          </div>
        );
      default:
        return (
          <div style={{whiteSpace: 'nowrap'}}>
            {detail}
          </div>
        );
    }
  };

  render() {
    const { classes, isLoading } = this.props;
    const { blastingData, blastingMeta } = this.state;

    const columns = [
      {
        name: 'id',
        display: false,
        customBodyRender: (value) => (value ? value : null),
      },
      {
        name: 'subject',
        label: 'Subject',
        display: true,
        customBodyRender: (value) => (value ? value : '-'),
      },
      {
        name: 'target_type',
        label: 'Target Type',
        display: true,
        customBodyRender: (value) => {
          switch (value) {
            case 'all':
              return 'All'
            case 'user':
              return 'User'
            case 'organization':
              return 'Organization'
            default:
              return '-';
          };
        },
      },
      {
        name: 'schedule_type',
        label: 'Schedule Type',
        display: true,
        customBodyRender: (value) => {
          switch (value) {
            case 'now':
              return 'Now'
            case 'scheduled':
              return 'Scheduled'
            default:
              return '-';
          };
        },
      },
      {
        name: 'schedule_date_time',
        label: 'Schedule Date',
        display: true,
        customBodyRender: (value) => {
          return value ? (
            <Button
              variant="outlined"
              size="small"
              startIcon={<ScheduleSend className={classes.scheduleDateTime} />}
            >
              {convDate(value, 'DD-MM-YYYY HH:mm')}
            </Button>
          ) : (
            '-'
          );
        },
      },
      {
        name: 'status',
        label: 'Status',
        display: true,
        customBodyRender: (value) => {
          switch (value) {
            case 'sent':
              return <strong style={{color: '#008000'}}>{'Sent'}</strong>;
            case 'waiting':
              return <strong style={{color: '#FFC226'}}>{'Waiting'}</strong>;
            case 'draft':
              return <strong style={{color: '#E5AF5A'}}>{'Draft'}</strong>;
            case 'canceled':
              return <strong style={{color: '#F00B38'}}>{'Canceled'}</strong>;
            default:
              return '-';
          }
        }
      },
    ];

    let button = null;

    const draft = (
      <MButton
        className={`${classes.btnSaveDraft} ${classes.mr}`}
        label="Save Draft"
        icon={<Save />}
        onClick={() => this.handleSubmit("draft")}
        loading={isLoading}
      />
    );

    const submit = (
      <MButton
        className={classes.btnSubmit}
        label={`${!this.state.is_detail ? !this.state.blasting_id ? "Submit" : "Update" : "Submit"}`}
        icon={<Send />}
        onClick={() => this.handleSubmit("sent")}
        loading={isLoading}
      />
    )

    const cancel = (
      <MButton
        className={classes.btnCancelForm}
        label="Cancel Form"
        icon={<Cancel />}
        onClick={() => {
          this.setState(prevState => ({
            ...prevState,
            blasting_id: null,
            formData: {
              ...prevState,
              subject: "",
              message: "",
              target_type: "",
              user_ids: [],
              organization_ids: [],
              notification_type: ["push_notif"],
              schedule_type: "now",
              schedule_date_time: null,
              status: "sent",
            }
          }))
        }}
        loading={isLoading}
      />
    )
    if (!this.state.is_detail) {
      if (!this.state.blasting_id) {
        button = (
          <div className={classes.btnContainer}>
            {draft}
            {submit}
          </div>
        )
      } else {
        button = (
          <div className={classes.btnContainerBlasting}>
            {draft}
            {submit}
            {cancel}
          </div>
        )
      }
    } else {
      button = (
        <div className={classes.btnContainer}>
          {draft}
          {submit}
        </div>
      )
    }

    return (
      <div className={classes.root} style={{display: "flex", flexDirection: "column", gap: 10}}>
        <Paper variant="outlined" className={classes.paper}>
          <form>
            {this.renderForm()}
            {button}
          </form>
        </Paper>
        <Paper variant="outlined" className={classes.paper}>
        <Datatable
          title={'Blasting Notification History'}
        
          loading={isLoading}
          dataSource={blastingData}
          total={blastingMeta ? blastingMeta.total : 0}
          page={blastingMeta ? blastingMeta.page : 1}
          columns={columns}
          handleReload={(params) => this.handleReload(params)}
          customActions={(params) => this.renderActions(params)}
          creatable={false}
          paging={true}
        />
        </Paper>
        {this.renderModal()}
      </div>
    );
  }
}



const mapStateToProps = ({ blastingNotif }) => ({
  usersBlasting: blastingNotif.blastingNotifDataUsers.data,
  organizationsBlasting: blastingNotif.blastingNotifDataOrganizations.data,
  blastingData: blastingNotif.blastingData,
  blastingMeta: blastingNotif.blastingMeta,
  blastingDetail: blastingNotif.blastingDetail,
  isLoading: blastingNotif.isLoading,
  isSuccess: blastingNotif.isSuccess,
});

const mapDispatchToProps = (dispatch) => ({
  usersBLastingNotif: (params) => dispatch(usersBLastingNotif(params)),
  organizationsBLastingNotif: (params) => dispatch(organizationsBLastingNotif(params)),
  createBlastingNotif: (params) => dispatch(createBlastingNotif(params)),
  updateBlastingNotif: (id, params) => dispatch(updateBlastingNotif(id, params)),
  allBlastingNotif: (params) => dispatch(allBlastingNotif(params)),
  detailBlastingNotif: (params) => dispatch(detailBlastingNotif(params)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(BlastingNotif));
