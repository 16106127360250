/* eslint-disable max-len */
import {REIMBURSE} from '../../services';
import {REIMBURSE_HISTORY_STATE as STATE} from './reimburse.types';
import {transformObject, isArray} from '../../helpers';
export const getReimburseHistory = (params) => {
  return (dispatch) => {
    dispatch(request(STATE.GET_REIMBURSE_HISTORY, true));
    REIMBURSE.getReimburseHistory(params)
        .then(
            (response) => {
              dispatch(requestSuccess(STATE.GET_REIMBURSE_HISTORY_SUCCESS, response.data));
            },
            (error) => {
              const errors = error.response.data.data.errors;
              const message = isArray(errors) ? transformObject(errors) : error.response.data.data.message;
              dispatch(requestFailed(STATE.GET_REIMBURSE_HISTORY_FAILURE, message));
            },
        );
  };
};

export const exportExcel = (params) => {
  return (dispatch) => {
    dispatch(request(STATE.EXPORT, true));
    REIMBURSE.exportExcel(params)
        .then(
            (response) => {
              dispatch(requestSuccess(STATE.EXPORT_SUCCESS, response.data.data));
            },
            (error) => {
              const errors = error.response.data.data.errors;
              const message = isArray(errors) ? transformObject(errors) : error.response.data.data.message;
              dispatch(exportFailed(STATE.EXPORT_FAILED, message));
            },
        );
  };
};

export const putReimbursStatus = (id, params) => {
  return (dispatch) => {
    dispatch(request(STATE.PUT_CHANGE_STATUS, true));
    REIMBURSE.putReimburseStatus(id, params)
        .then(
            (response)=> {
              dispatch(requestSuccess(STATE.PUT_CHANGE_STATUS_SUCCESS, response.data, params.status));
            },
            (error) => {
              const errors = error.response.data.data.errors;
              const message = isArray(errors) ? transformObject(errors) : error.response.data.data.message;
              // console.log('error', errors)
              dispatch(requestFailed(STATE.PUT_CHANGE_STATUS_FAILURE, message));
            },
        );
  };
};

export const resetReimburseStatus = () => {
  return (dispatch) => {
    dispatch(resetReimburse(STATE.RESET_REIMBURSE));
  };
};

export const clearFormMessage = () => {
  return (dispatch) => {
    dispatch(clearForm(STATE.CLEAR_FORM_REIMBURSE));
  };
};

// DISPATCH
const request = (requestType, custom = false) => ({
  type: custom ? requestType : STATE.REQUEST,
  requestType,
});

const requestFailed = (requestType, response) => ({
  type: requestType,
  requestType,
  payload: response,
});

const requestSuccess = (requestType, response, status = null) => ({
  type: requestType,
  payload: response,
  status: status,
});

const exportFailed = (requestType, response) => ({
  type: requestType,
  payload: response,
});

const resetReimburse = (requestType) => ({type: requestType});

const clearForm = (requestType) => ({type: requestType});
