import {apiService, ENDPOINTS} from '../config';

export const allBlastingNotif = (params) => {
  return apiService.get(
    ENDPOINTS.BLASTING_NOTIF.ALL,
    {params},
  );
};

export const createBlastingNorif = (params) => {
  return apiService.post(
    ENDPOINTS.BLASTING_NOTIF.CREATE,
    params,
  );
};

export const updateBlastingNotif = (id, params) => {
  return apiService.put(
    ENDPOINTS.BLASTING_NOTIF.UPDATE + id,
    params,
  );
};

export const detailBlastingNotif = (id) => {
  return apiService.get(ENDPOINTS.BLASTING_NOTIF.DETAIL + id);
};

export const usersBlastingNotif = (params) => {
  return apiService.get(
    ENDPOINTS.BLASTING_NOTIF.USERS,
    {params},
  );
};

export const organizationsBlastingNotif = (params) => {
  return apiService.get(
    ENDPOINTS.BLASTING_NOTIF.ORGANIZATIONS,
    {params},
  );
};
