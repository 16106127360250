export const initialState = {
  isLoading: false,
  isLoadingDetail: false,
  isError: false,
  isErrorDetail: false,
  isSuccessDetail: false,
  isSuccess: false,
  trigger: '',
  blastingNotifDetail: null,
  blastingNotifData: [],
  blastingNotifDataById: [],
  blastingNotifDataUsers: [],
  blastingNotifDataOrganizations: [],
  blastingNotifMeta: {page: 1, total: 0},
  message: {
    name: '',
  },

  exportLoading: false,
  exportData: {file: null},
  isLoadingPost: false,
  isSuccessPost: false,
  isErrorPost: false,
  messagePost: '',
  status: null,
};

export const BLASTING_NOTIF_STATE = {
  REQUEST: 'BLASTING_NOTIF_STATE_REQUEST',
  REQUEST_FAILED: 'BLASTING_NOTIF_STATE_REQUEST_FAILED',
  CLEAR_STATE: 'BLASTING_NOTIF_STATE_CLEAR_STATE',

  FETCH: 'BLASTING_NOTIF_STATE_FETCH',
  FETCH_SUCCCESS: 'BLASTING_NOTIF_STATE_FETCH_SUCCCESS',
  FETCH_FAILED: 'BLASTING_NOTIF_STATE_FETCH_FAILED',

  FETCH_BY_ID: 'BLASTING_NOTIF_STATE_FETCH_BY_ID',
  FETCH_BY_ID_SUCCCESS: 'BLASTING_NOTIF_STATE_FETCH_BY_ID_SUCCCESS',
  FETCH_BY_ID_FAILED: 'BLASTING_NOTIF_STATE_FETCH_BY_ID_FAILED',

  CREATE: 'BLASTING_NOTIF_STATE_CREATE',
  CREATE_SUCCCESS: 'BLASTING_NOTIF_STATE_CREATE_SUCCCESS',
  CREATE_FAILED: 'BLASTING_NOTIF_STATE_CREATE_FAILED',

  UPDATE: 'BLASTING_NOTIF_STATE_UPDATE',
  UPDATE_SUCCCESS: 'BLASTING_NOTIF_STATE_UPDATE_SUCCCESS',
  UPDATE_FAILED: 'BLASTING_NOTIF_STATE_UPDATE_FAILED',

  FETCH_USERS: 'BLASTING_NOTIF_STATE_FETCH_USERS',
  FETCH_USERS_SUCCCESS: 'BLASTING_NOTIF_STATE_FETCH_USERS_SUCCCESS',
  FETCH_USERS_FAILED: 'BLASTING_NOTIF_STATE_FETCH_USERS_FAILED',

  FETCH_ORGANIZATIONS: 'BLASTING_NOTIF_STATE_FETCH_ORGANIZATIONS',
  FETCH_ORGANIZATIONS_SUCCCESS: 'BLASTING_NOTIF_STATE_FETCH_ORGANIZATIONS_SUCCCESS',
  FETCH_ORGANIZATIONS_FAILED: 'BLASTING_NOTIF_STATE_FETCH_ORGANIZATIONS_FAILED',
};
