/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
import {PERMIT} from '../../services';
import {PERMIT_STATE as STATE} from './permit.type';
import {transformObject, isArray} from '../../helpers';

export const getPermitHistory = (params) => {
  return (dispatch) => {
    dispatch(request(STATE.GET_PERMIT_HISTORY, true));
    PERMIT.getPermitHistory(params)
        .then((response) => {
          dispatch(requestSuccess(STATE.GET_PERMIT_HISTORY_SUCCESS, response.data));
        })
        .catch((error) => {
          const errors = error.response.data.data.errors;
          const message = isArray(errors) ? transformObject(errors) : error.response.data.data.message;
          dispatch(requestFailed(STATE.GET_PERMIT_HISTORY_FAILURE, message));
        });
  };
};

export const putPermitStatus = (params, id) => {
  return (dispatch) => {
    dispatch(request(STATE.PUT_PERMIT_CHANGE_STATUS, true));
    PERMIT.putPermitOvertime(params, id)
        .then((response) => {
          dispatch(requestSuccess(STATE.PUT_PERMIT_CHANGE_STATUS_SUCCESS, response.data, response.data.data.permit.status));
        })
        .catch((error) => {
          const errors = error.response.data.data.errors;
          const message = isArray(errors) ? transformObject(errors) : error.response.data.data.message;
          dispatch(requestFailed(STATE.PUT_PERMIT_CHANGE_STATUS_SUCCESS, message));
        });
  };
};

export const exportExcel = (params) => {
  return (dispatch) => {
    dispatch(request(STATE.EXPORT, true));
    PERMIT.exportExcel(params)
        .then(
            (response) => {
              dispatch(requestSuccess(STATE.EXPORT_SUCCESS, response.data.data));
            },
            (error) => {
              const errors = error.response.data.data.errors;
              const message = isArray(errors) ? transformObject(errors) : error.response.data.data.message;
              dispatch(exportFailed(STATE.EXPORT_FAILED, message));
            },
        );
  };
};

// DISPATCH
const request = (requestType, custom = false) => ({
  type: custom ? requestType : STATE.REQUEST,
  requestType,
});

const requestFailed = (requestType, response) => ({
  type: requestType,
  requestType,
  payload: response,
});

const requestSuccess = (requestType, response, status = null) => ({
  type: requestType,
  payload: response,
  status: status,
});

const exportFailed = (requestType, response) => ({
  type: requestType,
  payload: response,
});
