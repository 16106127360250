/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
import {OFFICE_INVENTORY} from '../../services';
import {OFFICE_INVENTORY_STATE as STATE} from './officeInventory.types';
import {transformObject, isArray} from '../../helpers';

export const getOfficeInventory = (params) => {
  return (dispatch) => {
    dispatch(request(STATE.FETCH));
    OFFICE_INVENTORY.getOfficeInventory(params)
        .then(
            (response) => {
              dispatch(requestSuccess(STATE.FETCH_SUCCCESS, response.data));
            },
            (error) => {
              const response = error.response.data;
              dispatch(requestFailed(STATE.FETCH_FAILED, response.data.message));
            },
        );
  };
};

export const getOfficeInventoryDetail = (id) => {
  return (dispatch) => {
    dispatch(request(STATE.FETCH_BY_ID));
    OFFICE_INVENTORY.getOfficeInventoryDetail(id)
        .then(
            (response) => {
              dispatch(requestSuccess(STATE.FETCH_BY_ID_SUCCCESS, response.data));
            },
            (error) => {
              const response = error.response.data;
              dispatch(requestFailed(STATE.FETCH_BY_ID_FAILED, response.data.message));
            },
        );
  };
};

export const exportExcel = (params) => {
  return (dispatch) => {
    dispatch(request(STATE.EXPORT, true));
    OFFICE_INVENTORY.exportExcel(params)
        .then(
            (response) => {
              dispatch(requestSuccess(STATE.EXPORT_SUCCESS, response.data.data));
            },
            (error) => {
              const errors = error.response.data.data.errors;
              const message = isArray(errors) ? transformObject(errors) : error.response.data.data.message;
              dispatch(exportFailed(STATE.EXPORT_FAILED, message));
            },
        );
  };
};

export const completeOfficeInventory = (id, params) => {
  return (dispatch) => {
    dispatch(request(STATE.REVIEW));
    OFFICE_INVENTORY.completeOfficeInventory(id, params)
        .then(
            (response) => {
              dispatch(requestSuccess(STATE.REVIEW_SUCCESS, response.data.data.message));
            },
            (error) => {
              const errors = error.response.data.data.errors;
              const message = isArray(errors) ? transformObject(errors) : error.response.data.data.message;
              dispatch(requestFailed(STATE.REVIEW_FAILED, message));
            },
        );
  };
};

export const acceptOfficeInventory = (id, params) => {
  return (dispatch) => {
    dispatch(request(STATE.ACCEPT));
    OFFICE_INVENTORY.acceptOfficeInventory(id, params)
        .then(
            (response) => {
              dispatch(requestSuccess(STATE.ACCEPT_SUCCESS, response.data.data.message));
            },
            (error) => {
              const errors = error.response.data.data.errors;
              const message = isArray(errors) ? transformObject(errors) : error.response.data.data.message;
              dispatch(requestFailed(STATE.ACCEPT_FAILED, message));
            },
        );
  };
};

export const denyOfficeInventory = (id, params) => {
  return (dispatch) => {
    dispatch(request(STATE.DENY));
    OFFICE_INVENTORY.denyOfficeInventory(id, params)
        .then(
            (response) => {
              dispatch(requestSuccess(STATE.DENY_SUCCESS, response.data.data.message));
            },
            (error) => {
              const errors = error.response.data.data.errors;
              const message = isArray(errors) ? transformObject(errors) : error.response.data.data.message;
              dispatch(requestFailed(STATE.DENY_FAILED, message));
            },
        );
  };
};

export const clearState = () => {
  return (dispatch) => dispatch(resetState());
};

const request = (requestType) => ({
  type: STATE.REQUEST,
  requestType,
});

const requestId = (requestType) => ({
  type: STATE.REQUEST_BY_ID,
  requestType,
});

const requestFailed = (requestType, response) => ({
  type: STATE.REQUEST_FAILED,
  requestType,
  payload: response,
});

const requestSuccess = (requestType, response) => ({
  type: requestType,
  payload: response,
});

const exportFailed = (requestType, response) => ({
  type: requestType,
  payload: response,
});

const resetState = () => ({
  type: STATE.CLEAR_STATE,
});
