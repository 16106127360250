/* eslint-disable max-len */
/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
/* eslint-disable react/react-in-jsx-scope */
import React, {Component, Fragment} from 'react';
import {
  Paper,
  Button,
  Grid,
  Typography,
  Dialog,
  DialogContent,
  DialogActions,
  IconButton,
  CardMedia,
  Link,
  Icon,
} from '@material-ui/core';
import Badge from '@mui/material/Badge';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Tooltip from '@material-ui/core/Tooltip';
import Divider from '@mui/material/Divider';
import {
  Search,
  Refresh,
  CheckCircle,
  Cancel,
  Send,
  SaveAlt,
  Add,
} from '@material-ui/icons';
import {
  MInput,
  MKeyBoardDatepicker,
  MButton,
  ModalTitle,
  MSelect,
  MDatepicker,
} from '../../../components/form';
import ImageCropper from '../../../components/cropper';
import {connect} from 'react-redux';
import {withStyles} from '@material-ui/core/styles';
import styles from './styles';
import {Datatable} from '../../../components';
import {toast, isObject, convDate, convText, showPopup, thousandSeparator, blobToFile, checkFileUploadSize} from '../../../helpers';
import {checkStatus as checkStatusUser} from '../../../redux/userManagement/userManagement.actions';
import {checkStatus} from '../../../redux/organization/organization.actions';
import _ from 'lodash';
import moment from 'moment';
import Swal from 'sweetalert2';
import {createTheme, ThemeProvider} from '@mui/material/styles';
import {Checkbox, FormControlLabel, Stack, Snackbar, createTheme as themeTemplate} from '@mui/material';
import {Verified} from '@mui/icons-material';
import Slider from 'react-slick';
import confirmationIcon from '../../../assets/icon/partner/confirm.svg';
import completedIcon from '../../../assets/icon/employeeFacilities/completed.svg';

import {
  getOfficeInventory,
  getOfficeInventoryDetail,
  completeOfficeInventory,
  acceptOfficeInventory,
  denyOfficeInventory,
  exportExcel,
  clearState,
} from '../../../redux/officeInventory/officeInventory.actions';
import {updateExportNotification} from '../../../redux/exportActivity/exportActivity.actions';
import {OFFICE_INVENTORY_STATE as STATE} from '../../../redux/officeInventory/officeInventory.types';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandMore';
import Timeline from '@mui/lab/Timeline';
import TimelineItem, {timelineItemClasses} from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineOppositeContent, {
  timelineOppositeContentClasses,
} from '@mui/lab/TimelineOppositeContent';


class OfficeInventory extends Component {
  constructor() {
    super();
    this.state = {
      page: 1,
      limit: 10,
      query: null,
      sortBy: null,
      order: 'desc',
      status: 'all',
      resetDatatable: null,
      resetPage: null,
      errorMessage: null,
      start_date: null,
      end_date: null,
      timelineAccordion: false,
      showMoreDesc: false,

      modalDetailShow: false,
      modalDenyShow: false,
      modalCompleteShow: false,
      modalAcceptShow: false,
      formData: {
        id: null,
        status: null,
        name: null,
        date: null,
        goods_name: null,
        approval_history: [],
        image: null,
        inventory_category: null,
        inventory_no: null,
        inventory_description: null,
        condition_history: [],
        inventory_id: null,
        images: [],
      },
      notes: null,
      approval_date: null,
      error_date: null,
      error_date_message: null,
      errorNotes: null,
      errorUpload: false,

      snackbar: false,
      snackbarVertical: 'top',
      snackbarHorizontal: 'right',
    };
  }

  checkboxTheme = () => createTheme({
    palette: {
      primary: {
        main: '#00AFF0',
      },
    },
  })

  imageListTheme = () => createTheme({
    palette: {
      primary: {
        main: '#00AFF0',
      },
      secondary: {
        main: '#000',
      },
    },
    components: {
      MuiBadge: {
        styleOverrides: {
          badge: {
            color: '#FFF',
            backgroundColor: '#000',
            opacity: 0.5,
          },
        },
      },
    },
  })

  timelineTheme = () => createTheme({
    palette: {
      primary: {
        main: '#00AFF0',
      },
      secondary: {
        main: '#FFC226',
      },
      warning: {
        main: '#F00B38',
      },
      success: {
        main: '#008000',
      },
    },
    components: {
      MuiTimelineItem: {
        styleOverrides: {
          root: {
            minHeight: '46px !important',
          },
        },
      },
      MuiTimelineContent: {
        styleOverrides: {
          root: {
            padding: '6px 4px 6px 12px !important',
          },
        },
      },
      MuiTimeline: {
        styleOverrides: {
          root: {
            padding: '6px 16px 0px 16px !important',
          },
        },
      },
    },
  })

  snackbarTheme = () => themeTemplate({
    palette: {
      primary: {
        main: '#00AFF0',
      },
    },
    components: {
      MuiSnackbarContent: {
        styleOverrides: {
          root: {
            color: '#000000 !important',
            backgroundColor: '#FFFFFF !important',
          },
        },
      },
    },
  });

  accordionTheme = () => createTheme({
    palette: {
      primary: {
        main: '#00AFF0',
      },
    },
    components: {
      MuiAccordionSummary: {
        styleOverrides: {
          content: {
            display: 'flex',
            justifyContent: 'space-between',
            minHeight: '0px !important',
            margin: '13px 0px 8px 0px !important',
          },
          expanded: {
            // display: 'flex',
            justifyContent: 'space-between',
            minHeight: '0px !important',
            // margin: '8px 0px 0px 0px !important',
          },
          root: {
            minHeight: '0px !important',
          },
        },
      },
      MuiAccordionDetails: {
        styleOverrides: {
          root: {
            display: '',
            padding: '0px !important',
          },
        },
      },
      MuiIconButton: {
        styleOverrides: {
          root: {
            padding: '8px 4px 8px 12px !important',
            marginTop: '6px !important',
          },
        },
      },
    },
  })

  UNSAFE_componentWillMount() {
    const url_string = window.location.href;
    const url = new URL(url_string);
    const name = url.searchParams.get('query');

    if (name) {
      this.setState({query: name}, () => {
        this.handleDebounceSearch(name);
      });
    } else {
      this.fetchDatatable();
    }
    this.props.checkStatus();
    this.props.checkStatusUser();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const {trigger, isSuccess, isLoading, message, isError, isSuccessPost, exportData} = nextProps;

    if (trigger === STATE.EXPORT_SUCCESS ) {
      // toast('success', 'File successfully exported & downloaded');
      this.setState({snackbar: true});
      this.props.updateExportNotification({
        fileName: 'Office Invetory History',
        ...exportData,
      });
      this.fetchDatatable();
      this.props.clearState();
    }

    if (isSuccess) {
      if (
        trigger === STATE.REVIEW_SUCCESS
      ) {
        toast('success', 'Successfully complete the request');
        this.fetchDatatable();
        this.setState({
          denyModalShow: false,
          modalDetailShow: false,
        });
        this.props.clearState();
      }
      if (
        trigger === STATE.ACCEPT_SUCCESS
      ) {
        toast('success', 'Successfully accept the request');
        this.fetchDatatable();
        this.setState({
          denyModalShow: false,
          modalDetailShow: false,
        });
        this.props.clearState();
      }
      if (
        trigger === STATE.DENY_SUCCESS
      ) {
        toast('success', 'Successfully reject the request');
        this.fetchDatatable();
        this.setState({
          denyModalShow: false,
          modalDetailShow: false,
        });
        this.props.clearState();
      }
    }
    if (isSuccess) {
      if (
        trigger === STATE.DENY_SUCCESS
      ) {
        toast('success', message);
        this.setState({
          denyModalShow: false,
        });
        this.fetchDatatable();
        this.props.clearState();
      }
    }

    if (isError && message) {
      if (!isObject(message)) toast('error', message);
    }
  }

  handleResetForm = (closeModal) => {
    this.setState({
      errorMessage: null,
      formData: {
        id: null,
        status: null,
        name: null,
        date: null,
        goods_name: null,
        approval_history: [],
        image: null,
        inventory_category: null,
        inventory_no: null,
        inventory_description: null,
        condition_history: [],
        inventory_id: null,
      },
    });
    if (closeModal) {
      this.setState({
        modalDetailShow: false,
        modalDenyShow: false,
        showReject: false,
        showApprove: false,
      });
    }
  };

  handleSearch = (event) => {
    const {value} = event.target;
    this.setState({query: value}, () => {
      this.handleDebounceSearch(value);
    });
  };

  toggleResetPage = () => {
    this.setState({resetPage: true, page: 1, limit: 10}, () =>
      this.setState({resetPage: null}),
    );
  };

  handleButtonFilter = () => {
    const {
      page,
      limit,
      start_date,
      end_date,
      query,
      sortBy,
      order,
      method,
      status,
      searchBy,
    } = this.state;

    if (start_date === null || end_date === null) {
      return this.setState({
        errorFilterDate: true,
      });
    }

    this.props.getOfficeInventory({
      ...(page && {page}),
      ...(limit && {limit}),
      ...(query && {query}),
      ...(start_date && {start_date: convDate(start_date)}),
      ...(end_date && {end_date: convDate(end_date)}),
      ...(sortBy && {sort_by: sortBy}),
      ...(order && {order}),
      ...(status && status !== 'all' && {status}),
    });
  }

  handleReset = () => {
    const state = {
      page: 1,
      limit: 10,
      query: null,
      sortBy: null,
      order: 'desc',
      status: 'all',
      resetDatatable: null,
      resetPage: null,
      errorMessage: null,
      errorFilterDate: false,
      checkboxFilter: false,
      start_date: convDate(moment()),
      end_date: convDate(moment()),

      modalDetailShow: false,
      modalDenyShow: false,
      formData: {
        id: null,
        status: null,
        name: null,
        date: null,
        goods_name: null,
        approval_history: [],
        image: null,
        inventory_category: null,
        inventory_no: null,
        inventory_description: null,
        condition_history: [],
        inventory_id: null,
      },
    };
    this.setState(state, () => this.fetchDatatable());
    this.toggleResetDatatable(true);
    this.toggleResetPage();
  };

  toggleResetDatatable = (status) => {
    this.setState({resetDatatable: status}, () =>
      this.setState({resetDatatable: null}),
    );
  };

  handleDebounceSearch = _.debounce((query) => {
    this.toggleResetPage();
    this.setState({page: null}, () => this.fetchDatatable());
  }, 500);


  handleFilterChange = (event) => {
    const {value, name} = event.target;
    this.setState({[name]: value, page: null});
  };

  handleFilterDateChange = (params) => {
    const {value, name} = params;
    this.setState(
        {[name]: value, page: null},
    );
  };

  handleFilterCheckbox = (event) => {
    const {checked} = event.target;

    if (checked) {
      this.setState({
        filterCheckbox: checked,
        start_date: convDate(moment()),
        end_date: convDate(moment()),
      });

      const state = {
        page: 1,
        limit: 10,
        query: '',
        sortBy: null,
        order: 'desc',
        method: 'all',
        status: 'all',
        errorFilterDate: false,
      };
      this.setState(state, () => this.fetchDatatable());
      this.toggleResetDatatable(true);
      this.toggleResetPage();
    } else {
      this.setState({
        filterCheckbox: checked,
      });

      const state = {
        page: 1,
        limit: 10,
        query: '',
        start_date: null,
        end_date: null,
        sortBy: null,
        order: 'desc',
        method: 'all',
        status: 'all',
        errorFilterDate: false,
      };
      this.setState(state, () => this.fetchDatatable());
      this.toggleResetDatatable(true);
      this.toggleResetPage();
    }
  }

  handleExport = () => {
    const {
      page,
      limit,
      sortBy,
      start_date,
      end_date,
      order,
      query,
      status,
    } = this.state;
    this.props.exportExcel({
      ...(page && {page}),
      ...(limit && {limit}),
      ...(start_date && {start_date: convDate(start_date)}),
      ...(end_date && {end_date: convDate(end_date)}),
      ...(sortBy && {sort_by: sortBy}),
      ...(order && {order}),
      ...(query && {query}),
      ...(status && status !== 'all' && {status}),
    });
  }

  handleCloseSnackbar = () => {
    this.setState({snackbar: false});
  };

  renderSnackbar = () => {
    const {snackbar, snackbarVertical, snackbarHorizontal} = this.state;
    const action = (
      <Button color="primary" size="small" onClick={() => this.props.history.push('/export-activity')}>
        View Activity
      </Button>
    );
    return (
      <ThemeProvider theme={this.snackbarTheme()}>
        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={snackbar}
          onClose={this.handleCloseSnackbar}
          message="Your file is in the queue, click here to see the progress"
          key={snackbarVertical + snackbarHorizontal}
          action={action}
        />
      </ThemeProvider>
    );
  }

  renderFilter = () => {
    const {classes, message, exportLoading, officeInventoryData} = this.props;
    const {query, start_date, end_date, status} = this.state;
    const renderMethodOptions = () => {
      const options = [
        {id: 'all', name: 'All'},
        {id: 'request', name: 'Request'},
        {id: 'on_going', name: 'On Going'},
        {id: 'completed', name: 'Completed'},
        {id: 'rejected', name: 'Rejected'},
      ];
      return options;
    };

    return (
      <Paper variant='outlined' className={classes.paper}>
        {this.state.filterCheckbox ? (
          <>
            <MInput
              autoFocus
              className={`${classes.filter} ${classes.searchInput}`}
              fullWidth={false}
              variant="standard"
              name="query"
              label="Search"
              placeholder="Search keyword"
              value={query}
              onChange={this.handleSearch}
              InputProps={{
                endAdornment: (
                  <IconButton position="end">
                    <Search className={classes.searchIcon} />
                  </IconButton>
                ),
              }}
            />
            <ThemeProvider theme={this.checkboxTheme()}>
              <FormControlLabel
                style={{display: 'inline-flex', verticalAlign: 'bottom'}}
                control={
                  <Checkbox
                    color='primary'
                    checked={this.state.filterCheckbox}
                    onChange={(e) => this.handleFilterCheckbox(e)}
                  />
                }
                label="Filter"
              />
            </ThemeProvider>
            <MSelect
              shrink={true}
              fullWidth={false}
              classNameFC={`${classes.formControlStatus} ${classes.filter}`}
              variant="standard"
              name="status"
              label="Status"
              keyPair={['id', 'name']}
              options={renderMethodOptions()}
              value={status}
              onChange={this.handleFilterChange}
            />
            <MKeyBoardDatepicker
              className={classes.filterDatePicker}
              name="start_date"
              label="Start Date"
              value={start_date}
              onDateChange={this.handleFilterDateChange}
              placeholder="dd-mm-yyyy"
              maxDate={end_date ? new Date(end_date) : undefined}
              error={
                this.state.start_date === null && this.state.end_date !== null ? 'Start Date is required' :
                  start_date === null && this.state.errorFilterDate ? 'Start Date is required' :
                    undefined
              }
            />
            <MKeyBoardDatepicker
              className={classes.filterDatePicker}
              name="end_date"
              label="End Date"
              value={end_date}
              onDateChange={this.handleFilterDateChange}
              placeholder="dd-mm-yyyy"
              minDate={new Date(start_date)}
              error={
                this.state.start_date !== null && this.state.end_date === null ? 'End Date is required' :
                  end_date === null && this.state.errorFilterDate ? 'End Date is required' :
                    undefined
              }
            />
            <div className={classes.btnFilterContainer}>
              <MButton
                id='btn_apply_office'
                className={classes.filterBtn}
                label="Apply"
                color="primary"
                onClick={this.handleButtonFilter}
              />
              <MButton
                id='btn_reset_office'
                className={classes.filterBtn}
                label="Reset"
                color="primary"
                icon={<Refresh />}
                onClick={this.handleReset}
              />
              <MButton
                id='btn_export_office'
                className={`${classes.btnExport} ${classes.filterBtnEnd}`}
                label="Export"
                icon={<SaveAlt />}
                onClick={this.handleExport}
                loading={exportLoading}
                disabled={officeInventoryData?.length < 1 ? true : false}
              />
            </div>
          </>
        ) : (
          <Grid container justifyContent='space-between'>
            <Grid item>
              <MInput
                autoFocus
                className={`${classes.filter} ${classes.searchInput}`}
                fullWidth={false}
                variant="standard"
                name="query"
                label="Search"
                placeholder="Search keyword"
                value={query}
                onChange={this.handleSearch}
                InputProps={{
                  endAdornment: (
                    <IconButton position="end">
                      <Search className={classes.searchIcon} />
                    </IconButton>
                  ),
                }}
              />
              <ThemeProvider theme={this.checkboxTheme()}>
                <FormControlLabel
                  style={{display: 'inline-flex', verticalAlign: 'bottom'}}
                  control={
                    <Checkbox
                      color='primary'
                      checked={this.state.filterCheckbox}
                      onChange={(e) => this.handleFilterCheckbox(e)}
                    />
                  }
                  label="Filter"
                />
              </ThemeProvider>
            </Grid>
            <Grid item>
              <div className={classes.btnFilterContainer}>
                <MButton
                  id='btn_apply_office'
                  className={`${classes.btnExport} ${classes.filterBtnEnd}`}
                  label="Export"
                  icon={<SaveAlt />}
                  onClick={this.handleExport}
                  loading={exportLoading}
                  disabled={officeInventoryData?.length < 1 ? true : false}
                />
                <br />
              </div>
            </Grid>
          </Grid>
        )}
      </Paper>
    );
  }

  fetchDatatable = () => {
    this.props.checkStatus();
    this.props.checkStatusUser();
    const {
      page,
      limit,
      sortBy,
      start_date,
      end_date,
      order,
      query,
      status,
    } = this.state;
    this.props.getOfficeInventory({
      ...(page && {page}),
      ...(limit && {limit}),
      ...(start_date && {start_date: convDate(start_date)}),
      ...(end_date && {end_date: convDate(end_date)}),
      ...(sortBy && {sort_by: sortBy}),
      ...(order && {order}),
      ...(query && {query}),
      ...(status && status !== 'all' && {status}),
    });
  };

  handleReload = (params) => {
    this.setState(params, () => this.fetchDatatable());
  };

  handleAcceptRequest = (data) => {
    showPopup(
        'confirm',
        'Are you sure you want to accept this request?',
        'Yes',
        () => this.props.acceptOfficeInventory(data[0],
            {
              loan_reason: data[6],
              date: data[3],
              goods_name: data[4],
              status: 'on_going',
            }),
    );
  };
  handleDenyRequest = (params) => {
    this.setState({
      modalDenyShow: !this.state.modalDenyShow,
      notes: null,
      formData: {
        id: params[0],
        status: 'rejected',
        name: params[2],
        date: params[3],
        goods_name: params[4],
        loan_reason: params[6],
      },
    });
  }

  toggleAcceptRequest = (params, type) => {
    this.setState({
      modalAcceptShow: !this.state.modalAcceptShow,
      notes: null,
      formData: {
        ...this.state.formData,
        id: type === 'modal' ? params : params[0],
        status: 'on_going',
        images: [],
      },
      errorUpload: false,
      errorNotes: false,
    });
  }

  handleDenyRequests = (id, name, date, goods_name, loan_reason) => {
    this.toggleModalDetail('close');
    this.setState({
      modalDenyShow: !this.state.modalDenyShow,
      notes: null,
      formData: {
        id: id,
        name: name,
        date: date,
        goods_name: goods_name,
        loan_reason: loan_reason,
      },
    });
  }


  handleCompleteRequest = (params, type) => {
    this.setState({
      modalCompleteShow: !this.state.modalCompleteShow,
      approval_date: null,
      formData: {
        id: type === 'modal' ? params : params[0],
        status: 'completed',
        name: params[2],
        date: params[3],
        goods_name: params[4],
        loan_reason: params[6],
        images: [],
      },
      error_date: false,
      notes: null,
      errorNotes: false,
      errorUpload: false,
    });
  }

  handleCompleteRequestDetail = (id) => {
    this.setState({
      modalCompleteShow: !this.state.modalCompleteShow,
      approval_date: null,
      formData: {
        ...this.state.formData,
        id: id,
      },
      error_date: false,
    });
  }

  handleAccept = () => {
    const {id, images, status} = this.state.formData;
    const {notes} = this.state;


    if (notes === null || notes === '' || notes === undefined) {
      this.setState({
        errorNotes: true,
      });
    }

    if (images?.length === 0) {
      this.setState({
        errorUpload: 'Photo of condition is required',
      });
    }

    if (images.length > 0 && notes) {
      const dataForm = new FormData();
      if (images) {
        for (let i = 0; i < images?.length; i++) {
          dataForm.append('history_inventory_img', (blobToFile(images[i])));
        }
      }
      if (status) {
        dataForm.append('status', status);
      }
      if (notes) {
        dataForm.append('notes', notes);
      }
      this.props.acceptOfficeInventory(id, dataForm);
      this.setState({
        modalAcceptShow: !this.state.modalAcceptShow,
        modalDetailShow: false,
      });
    }
  }

  handleAcceptRequests = (id, loan_reason, date, goods_name) => {
    showPopup(
        'confirm',
        'Are you sure you want to accept this request?',
        'Yes',
        () => this.props.acceptOfficeInventory(id,
            {
              loan_reason: loan_reason,
              date: date,
              goods_name: goods_name,
              status: 'on_going',
            }),
    );
  }

  handleReasonChange = (event) => {
    const {name, value} = event.target;
    this.setState({
      notes: value,
      errorNotes: null,
    });
  }

  handleUploadMultiple = (e) => {
    const {images} = this.state.formData;
    const listImage = [];
    const data = e.target.files;

    for (let i = 0; i < data?.length; i++) {
      const imageSizeIsAllowed = checkFileUploadSize(data[i]);
      let message;
      if (imageSizeIsAllowed === false) {
        message =
          'Maximum size each file is ' +
          process.env.REACT_APP_MAX_UPLOAD_SIZE_MB +
            ' MB';
        return this.setState({
          errorUpload: message,
        });
      }
      if (imageSizeIsAllowed === null) {
        message = 'Please select image';
        return this.setState({
          errorUpload: message,
        });
      }
    }

    for (let i = 0; i < data?.length; i++) {
      listImage.push(data[i]);
    }

    for (let i = 0; i < images?.length; i++) {
      listImage.push(images[i]);
    }

    if (listImage?.length > 10) {
      return this.setState({
        errorUpload: 'Maximum upload size exceeded, max 10 photo',
      });
    }
    this.setState({
      formData: {
        ...this.state.formData,
        images: listImage,
      },
      errorUpload: false,
    });
  }

  handleRemoveImage = (index) => {
    const {images} = this.state.formData;
    images.splice(index, 1);

    this.setState({
      formData: {
        ...this.state.formData,
        images: images,
      },
    });
  }

  handleApprovalDateChange = (params) => {
    const {value, name} = params;
    const selectedDate = new Date(value);
    const today = new Date();

    if (selectedDate > today) {
      this.setState({
        [name]: value,
        error_date: true,
        error_date_message: 'Completed date can not be greater than today',
      });
    } else {
      this.setState({
        [name]: value,
        error_date: false,
      });
    }
  };

  handleRejectOfficeInventory = () => {
    const {notes, errorNotes} = this.state;
    const {
      id,
      loan_reason,
      date,
      goods_name,
      status,
      name,
    } = this.state.formData;

    if (notes === null || notes === '' || notes === undefined) {
      return this.setState({errorNotes: true});
    } else {
      const denied_reason = notes;
      this.props.denyOfficeInventory(id,
          {
            loan_reason: loan_reason,
            denied_reason: denied_reason,
            date: date,
            goods_name: goods_name,
            status: 'rejected',
          });
      this.setState({
        modalDenyShow: !this.state.modalDenyShow,
      });
    }
  }

  handleCompleteOfficeInventory = () => {
    const {approval_date, error_date} = this.state;
    const {
      id,
      loan_reason,
      date,
      goods_name,
      status,
      name,
      images,
    } = this.state.formData;
    const {notes} = this.state;

    if (approval_date === null || approval_date === '' || approval_date === undefined) {
      this.setState({
        error_date: true,
        error_date_message: 'Completed Date is required',
      });
    }

    if (notes === null || notes === '' || notes === undefined) {
      this.setState({
        errorNotes: true,
      });
    }

    if (images?.length === 0) {
      this.setState({
        errorUpload: 'Photo of condition is required',
      });
    }


    if (images.length > 0 && notes && approval_date && !error_date) {
      const dataForm = new FormData();

      showPopup(
          'confirm',
          'Are you sure you want to complete this request?',
          'Yes',
          () => {
            if (images) {
              for (let i = 0; i < images?.length; i++) {
                dataForm.append('history_inventory_img', (blobToFile(images[i])));
              }
            }
            if (approval_date) {
              dataForm.append('approval_date', convDate(approval_date));
            }
            if (status) {
              dataForm.append('status', 'completed');
            }
            if (notes) {
              dataForm.append('notes', notes);
            }
            this.props.completeOfficeInventory(id, dataForm);
            this.setState({
              modalCompleteShow: !this.state.modalCompleteShow,
              modalDetailShow: false,
            });
          },
      );
    }
  }

  renderModalAccept = () => {
    const {classes} = this.props;
    const {modalAcceptShow, notes, errorNotes, errorUpload} = this.state;
    const {images} = this.state.formData;

    return (
      <Dialog
        open={modalAcceptShow}
        onClose={() => this.toggleAcceptRequest('close')}
      >
        <form autoComplete="off" style={{width: 460}}>
          <DialogContent style={{paddingTop: '0 !important'}}>
            <input type="hidden" value="no-autocomplete" />
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <div className="logo" style={{display: 'flex', justifyContent: 'center', marginTop: 8, marginBottom: 24}}>
                  <img src={confirmationIcon} alt="img" style={{width: '56px'}} />
                </div>
              </Grid>
              <Grid item xs={12}>
                <div className="logo" style={{display: 'flex', justifyContent: 'center', marginBottom: 8}}>
                  <Typography style={{
                    fontSize: '24px',
                    fontWeight: '700',
                    color: '#656464',
                    fontFamily: 'Poppins !important',
                    marginTop: -16,
                    marginBottom: -10,
                  }}>Confirmation</Typography>
                </div>
              </Grid>
              <Grid item xs={12}>
                <div className="logo" style={{display: 'flex', justifyContent: 'center', marginBottom: 8, paddingTop: '0px !important', paddingBottom: '0px !important'}}>
                  <Typography style={{
                    fontSize: '14px',
                    fontWeight: '400',
                    color: '#656464',
                    fontFamily: 'Poppins !important',
                    textAlign: 'center',
                    display: 'flex',
                  }}>{`Are you sure you want to accept this request? Please upload a photo of the condition of the item`}</Typography>
                </div>
              </Grid>
              <Grid item>
                <MInput
                  style={{width: 420}}
                  classNameFC={classes.formControlNotes}
                  name="notes"
                  label="Condition*"
                  placeholder="Enter inventory condition"
                  // value={notes}
                  onChange={(event) => this.handleReasonChange(event)}
                  // onClick={() => this.setState({errorNotes: false})}
                  multiline={true}
                  rows={3}
                  // notched={true}
                  shrink={true}
                  error={
                    errorNotes ? 'Reason is required' : false
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <div>
                  <Typography className={errorUpload === false ? classes.photoTitle : classes.photoTitleError}>
                    Photo of Condition*
                  </Typography>
                  <Button
                    variant="contained"
                    component="label"
                    endIcon={<Add />}
                    className={classes.buttonUpload}
                    disabled={images?.length >= 10 ? true : false}
                  >
                    Upload Image
                    <input
                      type="file"
                      id='fileUpload'
                      multiple
                      hidden
                      accept="image/*"
                      onChange= {(e) => this.handleUploadMultiple(e)}
                    />
                  </Button>
                  <Typography style={{marginBottom: 4, marginTop: 2}} className={errorUpload === false ? classes.photoSubTitle : classes.photoSubTitleError}>
                    {errorUpload === false ? `Must be .jpg/.jpeg with max size ${process.env.REACT_APP_MAX_UPLOAD_SIZE_MB} MB & max 10 photo` : errorUpload}
                  </Typography>
                </div>
              </Grid>
              {images && images?.length > 0 ?
                images.map((value, index) => {
                  return (
                    <Grid item xs={3} key={index}>
                      <Stack direction="row" sx={{color: 'action.active'}}>
                        <ThemeProvider theme={this.imageListTheme()}>
                          <Badge style={{cursor: 'pointer'}} onClick={() => this.handleRemoveImage(index)} color='primary' badgeContent={'x'}>
                            <img
                              src={URL.createObjectURL(value)}
                              srcSet={URL.createObjectURL(value)}
                              alt={`img-${index + 1}`}
                              loading="lazy"
                              style={{width: 72, height: 72, borderRadius: 8}}
                            />
                          </Badge>
                        </ThemeProvider>
                      </Stack>
                    </Grid>
                  );
                }) : null}
            </Grid>
          </DialogContent>
          <DialogActions style={{
            padding: '20px 20px 20px 20px',
            placeContent: 'center',
          }}>
            <MButton
              id="btnYes"
              className={classes.approveButton }
              label="Yes"
              onClick={() => this.handleAccept()}
            />
            <MButton
              id="btnNo"
              style={{marginRight: 8}}
              className={classes.rejectButton}
              label="No"
              onClick={() => this.toggleAcceptRequest('close')}
            />
          </DialogActions>
        </form>
      </Dialog>
    );
  }

  renderModalDeny = () => {
    const {classes} = this.props;
    const {modalDenyShow, notes, errorNotes} = this.state;

    return (
      <Dialog
        open={modalDenyShow}
        onClose={() => this.setState({
          modalDenyShow: !this.state.modalDenyShow,
        })}
      >
        <form autoComplete="off" style={{width: 460}}>
          <DialogContent style={{paddingTop: '0 !important'}}>
            <input type="hidden" value="no-autocomplete" />
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <div className="logo" style={{display: 'flex', justifyContent: 'center', marginTop: 8, marginBottom: 24}}>
                  <img src={confirmationIcon} alt="img" style={{width: '56px'}} />
                </div>
              </Grid>
              <Grid item xs={12}>
                <div className="logo" style={{display: 'flex', justifyContent: 'center', marginBottom: 8}}>
                  <Typography style={{
                    fontSize: '24px',
                    fontWeight: '700',
                    color: '#656464',
                    fontFamily: 'Poppins !important',
                    marginTop: -16,
                    marginBottom: -10,
                  }}>Confirmation</Typography>
                </div>
              </Grid>
              <Grid item xs={12}>
                <div className="logo" style={{display: 'flex', justifyContent: 'center', marginBottom: 8, paddingTop: '0px !important', paddingBottom: '0px !important'}}>
                  <Typography style={{
                    fontSize: '14px',
                    fontWeight: '400',
                    color: '#656464',
                    fontFamily: 'Poppins !important',
                    textAlign: 'center',
                  }}>{`Are you sure you want to reject this request?`}</Typography>
                </div>
              </Grid>
              <Grid item>
                <MInput
                  style={{width: 420}}
                  name="notes*"
                  label="Reason"
                  placeholder="Enter inventory condition"
                  // value={notes}
                  onChange={(event) => this.handleReasonChange(event)}
                  // onClick={() => this.setState({errorNotes: false})}
                  multiline={true}
                  rows={3}
                  // notched={true}
                  shrink={true}
                  error={
                    errorNotes ? 'Reason is required' : false
                  }
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions style={{
            padding: '0px 20px 20px 20px',
            placeContent: 'center',
          }}>
            <MButton
              id="btnYes"
              className={classes.approveButton }
              label="Yes"
              onClick={() => this.handleRejectOfficeInventory()}
            />
            <MButton
              id="btnNo"
              style={{marginRight: 8}}
              className={classes.rejectButton}
              label="No"
              onClick={() => this.setState({
                modalDenyShow: false,
              })}
            />
          </DialogActions>
        </form>
      </Dialog>
    );
  }

  renderModalComplete = () => {
    const {classes} = this.props;
    const {modalCompleteShow, approval_date, error_date, notes, errorNotes, errorUpload, error_date_message} = this.state;
    const {images, date} = this.state.formData;

    return (
      <Dialog
        open={modalCompleteShow}
        onClose={() => this.setState({
          modalCompleteShow: !this.state.modalCompleteShow,
          errorUpload: false,
        })}
      >
        <form autoComplete="off" style={{width: 460}}>
          <DialogContent style={{paddingTop: '0 !important'}}>
            <input type="hidden" value="no-autocomplete" />
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <div className="logo" style={{display: 'flex', justifyContent: 'center', marginBottom: 8}}>
                  <Typography style={{
                    fontSize: '20px',
                    fontWeight: '700',
                    color: '#656464',
                    fontFamily: 'Poppins !important',
                    marginTop: 0,
                    marginBottom: -10,
                  }}>Completed Date</Typography>
                </div>
              </Grid>
              <Grid item xs={12}>
                <div className="logo" style={{display: 'flex', justifyContent: 'center', marginBottom: 8}}>
                  <Typography style={{
                    fontSize: '12px',
                    fontWeight: '400',
                    color: '#656464',
                    fontFamily: 'Poppins !important',
                    marginTop: 0,
                    marginBottom: -10,
                    textAlign: 'center',
                  }}>Please fill in the date and upload a photo of the condition of the item</Typography>
                </div>
              </Grid>
              <Grid item xs={12}>
                <MKeyBoardDatepicker
                  fullWidth={true}
                  variant='outlined'
                  className={classes.approvalDate}
                  name="approval_date"
                  label="Completed Date*"
                  value={approval_date}
                  onDateChange={this.handleApprovalDateChange}
                  placeholder="dd-mm-yyyy"
                  maxDate={new Date()}
                  minDate={date}
                  error={
                    error_date ? error_date_message : null
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <MInput
                  style={{width: 420}}
                  classNameFC={classes.formControlCondition}
                  name="notes"
                  label="Condition"
                  placeholder="Enter inventory condition"
                  // value={notes}
                  onChange={(event) => this.handleReasonChange(event)}
                  // onClick={() => this.setState({errorNotes: false})}
                  multiline={true}
                  rows={3}
                  // notched={true}
                  shrink={true}
                  error={
                    errorNotes ? 'Reason is required' : false
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <div>
                  <Typography className={errorUpload === false ? classes.photoTitle : classes.photoTitleError}>
                    Photo of Condition
                  </Typography>
                  <Button
                    variant="contained"
                    component="label"
                    endIcon={<Add />}
                    className={classes.buttonUpload}
                    disabled={images?.length >= 10 ? true : false}
                  >
                    Upload Image
                    <input
                      type="file"
                      id='fileUpload'
                      multiple
                      hidden
                      accept="image/*"
                      onChange= {(e) => this.handleUploadMultiple(e)}
                    />
                  </Button>
                  <Typography style={{marginBottom: 4, marginTop: 2}} className={errorUpload === false ? classes.photoSubTitle : classes.photoSubTitleError}>
                    {errorUpload === false ? `Must be .jpg/.jpeg with max size ${process.env.REACT_APP_MAX_UPLOAD_SIZE_MB} MB & max 10 photo` : errorUpload}
                  </Typography>
                </div>
              </Grid>
              {images && images?.length > 0 ?
                images.map((value, index) => {
                  return (
                    <Grid item xs={3} key={index}>
                      <Stack direction="row" sx={{color: 'action.active'}}>
                        <ThemeProvider theme={this.imageListTheme()}>
                          <Badge style={{cursor: 'pointer'}} onClick={() => this.handleRemoveImage(index)} color='primary' badgeContent={'x'}>
                            <img
                              src={URL.createObjectURL(value)}
                              srcSet={URL.createObjectURL(value)}
                              alt={`img-${index + 1}`}
                              loading="lazy"
                              style={{width: 72, height: 72, borderRadius: 8}}
                            />
                          </Badge>
                        </ThemeProvider>
                      </Stack>
                    </Grid>
                  );
                }) : null}
            </Grid>
          </DialogContent>
          <DialogActions style={{
            padding: '20px 20px 20px 20px',
            placeContent: 'center',
          }}>
            <MButton
              id="btnYes"
              className={classes.approveButton }
              label="Submit"
              onClick={() => this.handleCompleteOfficeInventory()}
            />
            <MButton
              id="btnNo"
              style={{marginRight: 8}}
              className={classes.rejectButton}
              label="Cancel"
              onClick={() => this.setState({
                modalCompleteShow: false,
                errorUpload: false,
              })}
            />
          </DialogActions>
        </form>
      </Dialog>
    );
  }

  renderActions = (params) => {
    const svgIcon = (
      <Icon>
        <img style={{display: 'flex', padding: 4, width: 19}} alt="completed" src={completedIcon} />
      </Icon>
    );
    return (
      <div>
        <>
          {params[5] === 'on_going' ? (
            <>

              <div style={{display: 'inline', marginRight: '5px'}}>
                <Tooltip title="Complete">
                  <Button
                    id='btn_complete'
                    variant="contained"
                    // color="primary"
                    style={{
                      'backgroundColor': '#008000',
                      'boxShadow': 'none',
                      'color': '#fff',
                      '&:hover': {
                        boxShadow: 'none',
                        backgroundColor: '#005900',
                      },
                      'minWidth': '10px',
                      'paddingRight': '0px',
                    }}
                    size="small"
                    onClick={() => this.handleCompleteRequest(params)}
                    startIcon={svgIcon}
                  >
                  </Button>
                </Tooltip>
              </div>
            </>
          ) : (null)}
          {params[5] === 'request' ? (
            <>

              <div style={{display: 'inline', marginRight: '5px'}}>
                <Tooltip title="Approve">
                  <Button
                    id='btn_approve'
                    variant="contained"
                    // color="primary"
                    style={{
                      'backgroundColor': '#008000',
                      'boxShadow': 'none',
                      'color': '#fff',
                      '&:hover': {
                        boxShadow: 'none',
                        backgroundColor: '#005900',
                      },
                      'minWidth': '10px',
                      'paddingRight': '0px',
                    }}
                    size="small"
                    onClick={() => this.toggleAcceptRequest(params)}
                    startIcon={<CheckCircle />}
                  >
                  </Button>
                </Tooltip>
              </div>
              <div style={{display: 'inline', marginRight: '5px'}}>
                <Tooltip title="Reject">
                  <Button
                    id='btn_reject'
                    variant="contained"
                    // color="primary"
                    style={{
                      'backgroundColor': '#E5AF5A',
                      'boxShadow': 'none',
                      'color': '#fff',
                      '&:hover': {
                        boxShadow: 'none',
                        backgroundColor: '#b78c48',
                      },
                      'minWidth': '10px',
                      'paddingRight': '0px',
                    }}
                    size="small"
                    onClick={() => this.handleDenyRequest(params)}
                    startIcon={<Cancel />}
                  >
                  </Button>
                </Tooltip>
              </div>
            </>
          ) : (null)}
          <div style={{display: 'inline', marginRight: '5px'}}>
            <Tooltip title="Detail">
              <Button
                id='btn_detail'
                variant="contained"
                // color="primary"
                style={{
                  'backgroundColor': '#00AFF0',
                  'boxShadow': 'none',
                  'color': '#fff',
                  '&:hover': {
                    boxShadow: 'none',
                    backgroundColor: '#00A2DE',
                  },
                  'minWidth': '10px',
                  'paddingRight': '0px',
                }}
                size="small"
                onClick={() => this.toggleModalDetail('open', params)}
                startIcon={<Search />}
              >
              </Button>
            </Tooltip>
          </div>
        </>
      </div>
    );
  };

  toggleModalDetail = (type, data) => {
    if (type === 'open') {
      this.setState({
        modalDetailShow: true,
        timelineAccordion: false,
        showMoreDesc: false,
        formData: {
          id: data[0],
          name: data[2],
          date: data[3],
          goods_name: data[4],
          status: data[5],
          loan_reason: data[6],
          approval_history: data[7],
          image: data[8],
          inventory_category: data[9],
          inventory_no: data[10],
          inventory_description: data[11],
          condition_history: data[12],
          inventory_id: data[13],
        },
      });
    } else {
      this.setState({
        modalDetailShow: false,
        timelineAccordion: false,
        showMoreDesc: false,
        formData: {
          id: null,
          status: null,
          name: null,
          date: null,
          goods_name: null,
          approval_history: [],
          image: null,
          inventory_category: null,
          inventory_no: null,
          inventory_description: null,
          condition_history: [],
          inventory_id: null,
        },
      });
    }
  };

  renderSliderimage = () => {
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 2,
      arrows: false,
    };

    const {image} = this.state.formData;
    return (
      <div>
        {image ? (
          <Link href={image} target='_blank'>
            <img style={{
              width: '40%',
              padding: '8px 0px 8px 0px',
            }} src={image} alt="detailImg" />
          </Link>
        ) : '-'
        }
      </div>
    );
  }

  toggleTimelineAccordion = () => {
    this.setState({
      timelineAccordion: !this.state.timelineAccordion,
    });
  }

  renderModalDetail = () => {
    const {classes} = this.props;
    const {modalDetailShow, timelineAccordion, showMoreDesc} = this.state;
    const {
      id,
      status,
      name,
      date,
      goods_name,
      loan_reason,
      approval_history,
      image,
      inventory_category,
      inventory_no,
      inventory_description,
      condition_history,
      inventory_id,
    } = this.state.formData;

    const reversed = approval_history ? approval_history.reverse() : [];


    return (
      <Dialog
        open={modalDetailShow}
        onClose={() => this.toggleModalDetail('close')}
        maxWidth="sm"
        fullWidth={true}
        scroll="paper"
      >
        <ModalTitle
          className={classes.modalTitle}
          title="Office Inventory Details"
          onClick={() => this.toggleModalDetail('close')}
        />
        <DialogContent className={classes.dialogContent}>
          <div style={{marginBottom: 24}}>
            <ThemeProvider theme={this.accordionTheme()}>
              <Accordion expanded={timelineAccordion} onChange={this.toggleTimelineAccordion} variant='outlined'>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                >
                  <Typography className={classes.accordionTitle}>
                  Status
                  </Typography>
                  <Typography
                    className={status === 'request' ? classes.statusRequest :
                      status === 'on_going' ? classes.statusOngoing :
                        status === 'rejected' ? classes.statusRejected :
                          status === 'completed' ? classes.statusCompleted : null}
                  >
                    {status === 'request' ? 'Request' :
                      status === 'on_going' ? 'On Going' :
                        status === 'rejected' ? 'Rejected' :
                          status === 'completed' ? 'Completed' : null}
                  </Typography>                </AccordionSummary>
                <AccordionDetails>
                  <Divider style={{marginBottom: 8}} />
                  <ThemeProvider theme={this.timelineTheme()}>
                    <Timeline
                      sx={{
                        [`& .${timelineItemClasses.root}:before`]: {
                          flex: 0,
                          padding: 0,
                        },
                      }}
                    >
                      {reversed?.map((data, index) => {
                        return (
                          <TimelineItem key={index.toString()}>
                            <TimelineSeparator>
                              <TimelineDot color={index === reversed?.length - 1 && data.status === 'request' ? 'primary' :
                                index === reversed?.length - 1 && data.status === 'on_going' ? 'secondary' :
                                  index === reversed?.length - 1 && data.status === 'rejected' ? 'warning' :
                                    index === reversed?.length - 1 && data.status === 'completed' ? 'success' : 'grey'} />
                              {index === reversed?.length - 1 ? (null) : (<TimelineConnector />)}
                            </TimelineSeparator>
                            <TimelineContent>
                              <div style={{display: 'flex', justifyContent: 'space-between'}}>
                                <div>
                                  <Typography
                                    className={data.status === 'request' ? classes.statusRequest :
                                      data.status === 'on_going' ? classes.statusOngoing :
                                        data.status === 'rejected' ? classes.statusRejected :
                                          data.status === 'completed' ? classes.statusCompleted : null}
                                  >
                                    {data.status === 'request' ? 'Request' :
                                      data.status === 'on_going' ? 'On Going' :
                                        data.status === 'rejected' ? 'Rejected' :
                                          data.status === 'completed' ? 'Completed' : null}
                                  </Typography>
                                  <Typography
                                    className={classes.timelineDate}
                                  >
                                    {data.status === 'rejected' ? data.notes : null}
                                  </Typography>
                                </div>
                                <Typography className={classes.timelineDate}>{convDate(data.date, 'DD-MM-YYYY')}</Typography>
                              </div>
                            </TimelineContent>
                          </TimelineItem>
                        );
                      })}
                    </Timeline>
                  </ThemeProvider>
                </AccordionDetails>
              </Accordion>
            </ThemeProvider>
          </div>

          <Typography className={classes.informationTitle}>Information</Typography>
          <Grid container wrap="nowrap" spacing={2}>
            <Grid item xs={4}>
              <Typography className={classes.informationDetail}>Name </Typography>
            </Grid>
            <Grid item>
              <Typography className={classes.informationDetail}> : </Typography>
            </Grid>
            <Grid item xs zeroMinWidth>
              <Typography className={classes.informationDetail}>{name ? name : '-'}</Typography>
            </Grid>
          </Grid>

          <Grid container wrap="nowrap" spacing={2}>
            <Grid item xs={4}>
              <Typography className={classes.informationDetail}>Inventory Category</Typography>
            </Grid>
            <Grid item>
              <Typography className={classes.informationDetail}> : </Typography>
            </Grid>
            <Grid item xs zeroMinWidth>
              {/* Separator */}
              <Typography className={classes.informationDetail}>{goods_name ? goods_name : '-'}</Typography>
            </Grid>
          </Grid>
          <Grid container wrap="nowrap" spacing={2}>
            <Grid item xs={4}>
              <Typography className={classes.informationDetail}>Inventory Name</Typography>
            </Grid>
            <Grid item>
              <Typography className={classes.informationDetail}> : </Typography>
            </Grid>
            <Grid item xs zeroMinWidth>
              {/* Separator */}
              <Typography className={classes.informationDetail}>{goods_name ? goods_name : '-'}</Typography>
            </Grid>
          </Grid>

          <Grid container wrap="nowrap" spacing={2}>
            <Grid item xs={4}>
              <Typography className={classes.informationDetail}>Notes </Typography>
            </Grid>
            <Grid item>
              <Typography className={classes.informationDetail}> : </Typography>
            </Grid>
            <Grid item xs zeroMinWidth>
              <Typography className={classes.informationDetail}>{loan_reason ? loan_reason : '-'}</Typography>
            </Grid>
          </Grid>

          <Typography className={classes.informationTitleOffice}>Office Inventory Information</Typography>
          <Grid container wrap="nowrap" spacing={2}>
            <Grid item xs={4}>
              <Typography className={classes.informationDetail}>No. Inventory </Typography>
            </Grid>
            <Grid item>
              <Typography className={classes.informationDetail}> : </Typography>
            </Grid>
            <Grid item xs zeroMinWidth>
              <Typography className={classes.informationDetail}>{inventory_no ? inventory_no : '-'}</Typography>
            </Grid>
          </Grid>

          <Grid container wrap="nowrap" spacing={2}>
            <Grid item xs={4}>
              <Typography className={classes.informationDetail}>Inventory Category</Typography>
            </Grid>
            <Grid item>
              <Typography className={classes.informationDetail}> : </Typography>
            </Grid>
            <Grid item xs zeroMinWidth>
              {/* Separator */}
              <Typography className={classes.informationDetail}>{inventory_category ? inventory_category : '-'}</Typography>
            </Grid>
          </Grid>
          <Grid container wrap="nowrap" spacing={2}>
            <Grid item xs={4}>
              <Typography className={classes.informationDetail}>Inventory Name</Typography>
            </Grid>
            <Grid item>
              <Typography className={classes.informationDetail}> : </Typography>
            </Grid>
            <Grid item xs zeroMinWidth>
              {/* Separator */}
              <Typography className={classes.informationDetail}>{goods_name ? goods_name : '-'}</Typography>
            </Grid>
          </Grid>

          <Grid container wrap="nowrap" spacing={2}>
            <Grid item xs={4}>
              <Typography className={classes.informationDetail}>Description </Typography>
            </Grid>
            <Grid item>
              <Typography className={classes.informationDetail}> : </Typography>
            </Grid>
            <Grid item xs zeroMinWidth>
              <Typography className={classes.informationDetail}>
                {
                  inventory_description && showMoreDesc ? inventory_description :
                      inventory_description && inventory_description?.length <= 70 && !showMoreDesc ? `${inventory_description.substring(0, 70)}` :
                        inventory_description && inventory_description?.length > 70 && !showMoreDesc ? `${inventory_description.substring(0, 70)}...` : '-'
                }
              </Typography>
              {inventory_description && inventory_description?.length > 50? (
                <Link underline='hover' onClick={() => this.setState({showMoreDesc: !this.state.showMoreDesc})}>
                  <Typography className={classes.showMore} >
                    {!showMoreDesc ? 'Show More' : 'Show Less'}
                  </Typography>
                </Link>
              ) : (<></>)}
            </Grid>
          </Grid>

          <Grid container wrap="nowrap" spacing={2}>
            <Grid item xs={4}>
              <Typography className={classes.informationDetail}>Condition History </Typography>
            </Grid>
            <Grid item>
              <Typography className={classes.informationDetail}> : </Typography>
            </Grid>
            <Grid item xs zeroMinWidth>
              {condition_history?.length > 0 ? (
            <>
              <Link to ={'/master-inventory-detail?id=' + inventory_id + '&ref=2'}>
                <Tooltip title="Condition History Detail">
                  <Button
                    id='btn-detail'
                    variant="contained"
                    // color="primary"
                    style={{
                      'backgroundColor': '#00AFF0',
                      'boxShadow': 'none',
                      'color': '#fff',
                      '&:hover': {
                        boxShadow: 'none',
                        backgroundColor: '#00A2DE',
                      },
                      'minWidth': '8px',
                      'paddingRight': '0px',
                    }}
                    size="small"
                    onClick={() => this.props.history.push('/master-inventory-detail?id=' + inventory_id + '&ref=2')}
                    startIcon={<Search />}
                  >
                  </Button>
                </Tooltip>
              </Link>
            </>
              ) : (
                <Typography className={classes.informationDetail}>-</Typography>
              )}
            </Grid>
          </Grid>

          {/* <Grid container wrap="nowrap" spacing={2}>
            <Grid item xs={3}>
              <Typography>Attachment </Typography>
            </Grid>
            <Grid item>
              <Typography> : </Typography>
            </Grid>
            <Grid item xs zeroMinWidth>
              {this.renderSliderimage()}
            </Grid>
          </Grid> */}

        </DialogContent>
        <DialogActions>
          {status === 'on_going' ? (
            <MButton
              id="btnReview"
              className={classes.approveButton}
              label="COMPLETE"
              onClick={() => this.handleCompleteRequestDetail(id, 'modal')
              }
              // loading={isLoadingPut}
            />
          ) : (null)}
          {status === 'request' ? (
            <MButton
              id="btnApprove"
              className={classes.approveButton}
              label="ACCEPT"
              onClick={() => this.toggleAcceptRequest(id, 'modal')}
              // loading={isLoadingPut}
            />
          ) : (null)}
          {status === 'request' ? (
            <MButton
              id="btnDeny"
              className={classes.rejectButton}
              label="REJECT"
              onClick={() => this.handleDenyRequests(id, name, date, goods_name, loan_reason)}
              // loading={isLoadingPut}
            />
          ) : (null)}
          <MButton id="btnClose" className={classes.cancelBtn} label="CLOSE" onClick={() => this.toggleModalDetail('close')} />
        </DialogActions>
      </Dialog>
    );
  }

  render() {
    const {classes, officeInventoryData, officeInventoryMeta, isLoading} = this.props;
    const {resetDatatable, resetPage} = this.state;

    const dummyData = [
      {
        id: 1,
        no: '1',
        name: 'Dummy Data',
        date: '2023-04-05',
        goods_name: '1000000',
        loan_reason: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
        status: 'request',
      },
      {
        id: 2,
        no: '2',
        name: 'Ade Billar',
        date: '2023-04-05',
        goods_name: '2000000',
        loan_reason: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
        status: 'request',
      },
      {
        id: 3,
        no: '3',
        name: 'Agris Gregory',
        date: '2023-04-05',
        goods_name: '150000',
        loan_reason: 'For my business',
        status: 'on_going',
      },
      {
        id: 4,
        no: '4',
        name: 'Agung Garry',
        date: '2023-04-04',
        goods_name: '480000',
        loan_reason: 'For daily work needs',
        status: 'completed',
      },
      {
        id: 5,
        no: '5',
        name: 'Asep Taufan',
        date: '2023-04-04',
        goods_name: '380000',
        loan_reason: 'For my project',
        status: 'rejected',
      },
    ];
    const columns = [
      {name: 'id', label: 'Id', display: false},
      {name: 'no', label: 'No', display: true},
      {
        name: 'name',
        label: 'Name',
        display: true,
        customBodyRender: (value) => (value ? value : '-'),
      },
      {
        name: 'loan_date',
        label: 'Request Date',
        display: true,
        customBodyRender: (value) => {
          return <div style={{whiteSpace: 'nowrap'}}>{value ? convDate(value, 'DD-MM-YYYY') : '-'}</div>;
        },
      },
      {
        name: 'goods_name',
        label: 'Inventory Name',
        display: true,
        // Separator
        customBodyRender: (value) => (value ? value : '-'),
      },
      {
        name: 'status',
        label: 'Status',
        display: true,
        customBodyRender: (value) => {
          if (value === 'request') {
            return <strong style={{color: '#00A2DE'}}>{'Request'}</strong>;
          } else if (value === 'on_going') {
            return <strong style={{color: '#FFC226'}}>{'On Going'}</strong>;
          } else if (value === 'completed') {
            return <strong style={{color: '#008000'}}>{'Completed'}</strong>;
          } else if (value === 'rejected') {
            return <strong style={{color: '#F00B38'}}>{'Rejected'}</strong>;
          } else {
            return '-';
          }
        },
      },
      {
        name: 'loan_reason',
        label: 'Loan Reason',
        display: false,
        customBodyRender: (value) => (value ? value : '-'),
      },
      {
        name: 'approval_history',
        label: 'Approval History',
        display: false,
        customBodyRender: (value) => (value ? value : '-'),
      },
      {
        name: 'loan_attachment',
        label: 'Loan Attachment',
        display: false,
        customBodyRender: (value) => (value ? value : '-'),
      },
      {
        name: 'inventory_category',
        label: 'Inventory Category',
        display: false,
        customBodyRender: (value) => (value ? value : '-'),
      },
      {
        name: 'inventory_no',
        label: 'Inventory No',
        display: false,
        customBodyRender: (value) => (value ? value : '-'),
      },
      {
        name: 'inventory_description',
        label: 'Inventory Description',
        display: false,
        customBodyRender: (value) => (value ? value : '-'),
      },
      {
        name: 'condition_history',
        label: 'Condition History',
        display: false,
        customBodyRender: (value) => (value ? value : '-'),
      },
      {
        name: 'inventory_id',
        label: 'Inventory Id',
        display: false,
        customBodyRender: (value) => (value ? value : '-'),
      },
    ];
    return (
      <div className={classes.root}>
        {this.renderFilter()}
        <Datatable
          creatable={false}
          title={'Request List'}
          loading={isLoading}

          // Comment this if using API Data
          // dataSource={dummyData}
          // total={dummyData?.length}
          // page={1}
          //

          // Comment this if using Dummy Data
          dataSource={officeInventoryData}
          total={officeInventoryMeta ? officeInventoryMeta.total : 0}
          page={officeInventoryMeta ? officeInventoryMeta.page : 1}
          //

          columns={columns}
          handleReload={(params) => this.handleReload(params)}
          // handleDetail={(params) => this.toggleModalDetail('open', params)}
          customActions={(params) => this.renderActions(params)}
          toggleResetAll={resetDatatable}
          toggleResetPage={resetPage}
          manualNumbering={true}
        />
        {this.renderModalDetail()}
        {this.renderModalDeny()}
        {this.renderModalAccept()}
        {this.renderModalComplete()}
        {this.renderSnackbar()}
      </div>
    );
  }
}

const mapStateToProps = ({officeInventory}) => {
  return {

    isLoading: officeInventory.isLoading,
    isError: officeInventory.isError,
    isSuccess: officeInventory.isSuccess,
    message: officeInventory.message,
    trigger: officeInventory.trigger,
    officeInventoryData: officeInventory.officeInventoryData,
    officeInventoryMeta: officeInventory.officeInventoryMeta,

    isLoadingPut: officeInventory.isLoadingPut,
    isSuccessPut: officeInventory.isSuccessPut,
    isFailurePut: officeInventory.isFailurePut,
    isErrorPut: officeInventory.isErrorPut,
    messagePut: officeInventory.messagePut,
    status: officeInventory.status,
    exportLoading: officeInventory.exportLoading,
    exportData: officeInventory.exportData,
  };
};
const mapDispatchToProps = (dispatch) => ({
  checkStatus: () => dispatch(checkStatus()),
  checkStatusUser: () => dispatch(checkStatusUser()),

  getOfficeInventory: (params) => dispatch(getOfficeInventory(params)),
  // getOfficeInventoryDetail: (id, params) => dispatch(getOfficeInventoryDetail(id, params)),
  completeOfficeInventory: (id, params) => dispatch(completeOfficeInventory(id, params)),
  acceptOfficeInventory: (id, params) => dispatch(acceptOfficeInventory(id, params)),
  denyOfficeInventory: (id, params) => dispatch(denyOfficeInventory(id, params)),
  exportExcel: (params) => dispatch(exportExcel(params)),
  clearState: () => dispatch(clearState()),
  updateExportNotification: (params) => dispatch(updateExportNotification(params)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withStyles(styles)(OfficeInventory));
