/* eslint-disable max-len */
import {initialState, BLASTING_NOTIF_STATE as STATE} from './blastingNotif.types';

export default (state = initialState, action) => {
  switch (action.type) {
    case STATE.REQUEST:
      return {
        ...state,
        isLoading: true,
        isError: null,
        isSuccess: null,
      };

    case STATE.REQUEST_FAILED:
      return {
        ...state,
        isLoading: false,
        isError: true,
        message: action.payload,
      };
    case STATE.FETCH:
      return {
        ...state,
        isLoading: false,
        isError: true,
        message: action.payload,
      }
    case STATE.FETCH_SUCCCESS:
      return {
        ...state,
        isLoading: false,
        isError: true,
        blastingData: action.payload.data,
        blastingMeta: action.payload.meta,
        message: action.payload,
      }
    case STATE.FETCH_FAILED:
      return {
        ...state,
        isLoading: false,
        isError: true,
        message: action.payload,
      }
    case STATE.FETCH_BY_ID:
      return {
        ...state,
        isLoading: false,
        isError: true,
        message: action.payload,
      }
    case STATE.FETCH_BY_ID_SUCCCESS:
      return {
        ...state,
        isLoading: false,
        isError: true,
        blastingDetail: action.payload.data,
        message: action.payload,
      }
    case STATE.FETCH_BY_ID_FAILED:
      return {
        ...state,
        isLoading: false,
        isError: true,
        message: action.payload,
      }
    case STATE.CREATE:
      return {
        ...state,
        isLoading: false,
        isError: true,
        message: action.payload,
        isSuccess: false
      }
    case STATE.CREATE_SUCCCESS:
      return {
        ...state,
        isLoading: false,
        isError: true,
        message: action.payload,
        isSuccess: true
      }
    case STATE.CREATE_FAILED:
      return {
        ...state,
        isLoading: false,
        isError: true,
        message: action.payload,
        isSuccess: false
      }
    case STATE.UPDATE:
      return {
        ...state,
        isLoading: false,
        isError: true,
        message: action.payload,
        isSuccess: false
      }
    case STATE.UPDATE_SUCCCESS:
      return {
        ...state,
        isLoading: false,
        isError: true,
        message: action.payload,
        isSuccess: true
      }
    case STATE.UPDATE_FAILED:
      return {
        ...state,
        isLoading: false,
        isError: true,
        message: action.payload,
        isSuccess: false
      }
    case STATE.FETCH_USERS:
      return {
        ...state,
        isLoading: false,
        isError: true,
        message: action.payload,
      }
    case STATE.FETCH_USERS_SUCCCESS:
      return {
        ...state,
        isLoading: false,
        isError: true,
        blastingNotifDataUsers: action.payload,
      }
    case STATE.FETCH_USERS_FAILED:
      return {
        ...state,
        isLoading: false,
        isError: true,
        message: action.payload,
      }
    case STATE.FETCH_ORGANIZATIONS:
      return {
        ...state,
        isLoading: false,
        isError: true,
        message: action.payload,
      }
    case STATE.FETCH_ORGANIZATIONS_SUCCCESS:
      return {
        ...state,
        isLoading: false,
        isError: true,
        blastingNotifDataOrganizations: action.payload,
      }
    case STATE.FETCH_ORGANIZATIONS_FAILED:
      return {
        ...state,
        isLoading: false,
        isError: true,
        message: action.payload,
      }
    case STATE.CLEAR_STATE:
      return {
        ...state,
        ...initialState,
      };
    default:
      return state;
  }
};
